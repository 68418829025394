import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./accordion.scss";
import { useState } from "react";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function Accordion(props) {
  const [expanded, setExpanded] = useState(
    props.expanded ? props.expanded : false
  );

  return (
    <>
      <button className={`accordion bg-[#fff] text-[#000] p-[10px_15px] cursor-pointer border-none w-full text-[12px] flex items-center ${expanded ? "active" : ""}`} onClick={()=>{
        setExpanded(!expanded)
      }}><div className="w-[calc(100%-35px)] block">{props.title}</div><div className="w-[35px] h-[35px] flex items-center justify-end">{expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}</div></button>
      <div className="panel" style={{
        display: expanded ? "block" : "none"
      }}>{props.children}</div>
    </>
  );
}
