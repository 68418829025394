import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "../../context/context";
import Switcher from "../switcher/switcher";
import { useTranslation } from "react-i18next";

export default function ProductTasteFilter() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const currentLanguageRef = useRef("");
  const [filterItems, setFilterItems] = useState();
  const { t } = useTranslation();

  const getFlters = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/get/tastefilter?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.filters) {
            setFilterItems(data.filters);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
    getFlters();
  }, [getFlters, currentLanguage]);
  return (
    filters.tasteFilter &&
    filterItems && (
      <>
        {filterItems.filter && (
          <>
            <div className="">{t("filter")}</div>
            <div className="bg-[#9c9c9c] h-[1px] m-[10px_0]"></div>
            {filterItems.filter.map((item, index) => {
              return (
                <div className="flex items-center mt-[15px]" key={index}>
                  <div className="w-[80%] text-[13px]">{item.name}</div>
                  <div className="w-[20%] flex justify-end">
                    <Switcher
                      checked={
                        filters.tasteFilter.find((element) => {
                          return element === item.id;
                        })
                          ? true
                          : false
                      }
                      changeHandler={(value) => {
                        if (value === true) {
                          setFilters({
                            ...filters,
                            tasteFilter: [...filters.tasteFilter, item.id],
                          });
                        } else {
                          setFilters({
                            ...filters,
                            tasteFilter: filters.tasteFilter.filter(
                              (element) => element !== item.id
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
        {filterItems.filter_taste && (
          <div className="mt-[15px]">
            <div className="">{t("taste_filter")}</div>
            <div className="bg-[#9c9c9c] h-[1px] m-[10px_0]"></div>
            {filterItems.filter_taste.map((item, index) => {
              return (
                <div className="flex items-center mt-[15px]" key={index}>
                  <div className="w-[80%] text-[13px]">{item.name}</div>
                  <div className="w-[20%] flex justify-end">
                    <Switcher
                      checked={filters.tasteFilter.find((element) => {
                        return element === item.id;
                      })}
                      changeHandler={(value) => {
                        if (value === true) {
                          setFilters({
                            ...filters,
                            tasteFilter: [...filters.tasteFilter, item.id],
                          });
                        } else {
                          setFilters({
                            ...filters,
                            tasteFilter: filters.tasteFilter.filter(
                              (element) => element !== item.id
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    )
  );
}
