import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Footer from "./layout/footer";
import FormButton from "./components/form-button/form-button";
import Textarea from "./components/textarea/textarea";
import Input from "./components/input/input";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import BannerContact from "./components/banner-contact/banner";

export default function ContactUs() {
  const { WEBSITE_URL, PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
    default: "",
  });
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  async function addMessage() {
    if (!name) {
      setError({ ...error, name: t('message_enter_name') });
      return;
    }

    if (!lastName) {
      setError({ ...error, lastName: t('message_enter_last_name') });
      return;
    }

    if (!email) {
      setError({ ...error, email: t('message_enter_email') });
      return;
    }

    if (!message) {
      setError({ ...error, message: t('message_enter_message') });
      return;
    }

    setLoader(1);
    try {
      await fetch(`${PUBLIC_URL}/api/get/addmessage`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          lastName: lastName,
          email: email,
          message: message,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.status === 1) {
            enqueueSnackbar(t('message_request_sent'), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            setName("");
            setLastName("");
            setEmail("");
            setMessage("");
          } else {
            enqueueSnackbar(t('message_request_not_sent'), {
              variant: "error",
              vertical: "bottom",
              horizontal: "left",
            });
          }
        });
    } catch (error) {
      setError({ ...error, default: t('message_proccess_error') })
      setLoader(0);
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("website_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <BannerContact />
        <div className="text-center mx-[15px] lg:mx-[10%]">
          <div className="mt-[60px]">
            <div className="text-[70px] font-[900] uppercase">{t('contact_us')}</div>
            <div>{t('company_name')}: SHAWN Trading Co L.L.C</div>
          </div>
          <div className="flex items-center gap-[10px] mt-[30px] px-0 md:px-[15%]">
            <div className="w-full">
              <a href="tel:+971506372879">{t('tel')}: (+971) 50 637 28 79</a>
            </div>
            <div className="w-full">{t('web')}: www.shawn.ae</div>
            <div className="w-full">
              <a href="mailto:info@shawn.ae">{t('email')} : <span className="underline">info@shawn.ae</span></a>
            </div>
          </div>
          <div className="mt-[30px] flex gap-[20px] justify-center">
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/facebook-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/instagram-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/twitter-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/linkedin-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/youtube-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
            <a href="#" rel="nofollow">
              <img
                src={`${`${WEBSITE_URL}/media/whatsapp-contact-icon.svg`}`}
                alt=""
                className=""
              />
            </a>
          </div>
          <img
            src={`${`${WEBSITE_URL}/media/contact-us-map.jpg`}`}
            alt=""
            className="rounded-[20px] my-[30px] mx-auto w-full md:w-[445px]"
          />
          <form className="w-full md:w-[80%] lg:w-[50%] mx-auto pb-[127px]">
            <div className="mb-[10px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[12px] 2xl:text-[14px] ltr:text-left rtl:text-right">
                  {t('name')} *
                </div>
                <Input
                  type="text"
                  value={name}
                  onchange={(value) => {
                    setName(value);
                    setError({ ...error, name: '' })
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.name && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.name}
                </div>
              )}
            </div>
            <div className="mb-[10px]">
              <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
                <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[12px] 2xl:text-[14px] ltr:text-left rtl:text-right">
                  {t('last_name')} *
                </div>
                <Input
                  type="text"
                  value={lastName}
                  onchange={(value) => {
                    setLastName(value);
                    setError({ ...error, lastName: '' })
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.lastName && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.lastName}
                </div>
              )}
            </div>
            <div className="mb-[10px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[12px] 2xl:text-[14px] ltr:text-left rtl:text-right">
                  {t('email_mobile')}
                </div>
                <Input
                  type="text"
                  value={email}
                  onchange={(value) => {
                    setEmail(value);
                    setError({ ...error, email: '' })
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.email && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.email}
                </div>
              )}
            </div>
            <div className="mb-[10px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[12px] 2xl:text-[14px] ltr:text-left rtl:text-right">
                  {t('your_message')}
                </div>
                <Textarea
                  value={message}
                  onchange={(value) => {
                    setMessage(value);
                    setError({ ...error, message: '' })
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.message && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.message}
                </div>
              )}
            </div>
            <FormButton
              onclick={() => {
                addMessage();
              }}
              classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full md:w-[70%] rounded-[20px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              {t('send')}
            </FormButton>
          </form>
          {error.default && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.default}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}
