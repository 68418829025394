import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Footer from "./layout/footer";
import { useNavigate, useParams } from "react-router-dom";
import Input from "./components/input/input";
import FormButton from "./components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";

export default function ResetPassword() {
  const { PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");
  let { slug } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(0);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    default: "",
  });

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const changePassword = async () => {
    try {
      if (!password) {
        setError({
          ...error,
          password: t("message_enter_password"),
        });
        return;
      }
      if (!confirmPassword) {
        setError({
          ...error,
          confirmPassword: t("message_enter_confirm_password"),
        });
        return;
      }
      if (password !== confirmPassword) {
        setError({
          ...error,
          confirmPassword: t("message_passwords_not_match"),
        });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/resetpassword`, {
        method: "POST",
        body: JSON.stringify({
          hash: slug,
          password: password,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setLoader(0);
          if (data.token) {
            localStorage.setItem("token", data.token);
            navigate(`/`);
          } else {
            enqueueSnackbar("Error", {
              variant: "error",
              vertical: "bottom",
              horizontal: "left",
            });
          }
        });
    } catch (error) {
      setLoader(0);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9] py-[60px]">
        <form className="mx-auto w-[50%]">
          <div className="mb-[10px]">
            <div className="flex rounded-[20px] overflow-hidden">
              <div
                className={`w-[280px] bg-[#00000033] ${
                  error.password ? "text-[#f00]" : "text-[#000]"
                } p-[15px] uppercase text-[14px] ltr:text-left rtl:text-right`}
              >
                password *
              </div>
              <Input
                type="password"
                value={password}
                onchange={(value) => {
                  setPassword(value);
                  setError({ ...error, password: "" });
                }}
                classes="w-full p-[15px] bg-[#0000000D]"
              />
            </div>
            {error.password && (
              <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                {error.password}
              </div>
            )}
          </div>
          <div className="mb-[10px]">
            <div className="flex rounded-[20px] overflow-hidden">
              <div
                className={`w-[280px] bg-[#00000033] ${
                  error.confirmPassword ? "text-[#f00]" : "text-[#000]"
                } p-[15px] uppercase text-[14px] ltr:text-left rtl:text-right`}
              >
                confirm password *
              </div>
              <Input
                type="password"
                value={confirmPassword}
                onchange={(value) => {
                  setConfirmPassword(value);
                  setError({ ...error, confirmPassword: "" });
                }}
                classes="w-full p-[15px] bg-[#0000000D]"
              />
            </div>
            {error.confirmPassword && (
              <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                {error.confirmPassword}
              </div>
            )}
          </div>
          <FormButton
            onclick={() => {
              changePassword();
            }}
            classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
          >
            {loader === 1 && (
              <ClipLoader
                color="#ffffff"
                size="15px"
                className="rtl:ml-[10px] ltr:mr-[10px]"
              />
            )}
            Change
          </FormButton>
          {error.default && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.default}
            </div>
          )}
        </form>
      </main>
      <Footer />
    </>
  );
}
