import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Footer from "./layout/footer";
import Banner from "./components/banner/banner";
import CartProductItemCard from "./components/cart-product-item-card/cart-product-item-card";
import { Link } from "react-router-dom";
import Price from "./components/price/price";
import CartAddressCard from "./components/cart-address-card/cart-address-card";
import Breadcrumb from "./components/breadcrumb/breadcrumb";

export default function Cart() {
  const { cart } = useContext(AppContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const { t } = useTranslation();
  // const [loader, setLoader] = useState(0);

  const calcTotal = useCallback(() => {
    let _totalPrice = cart
      ? cart?.reduce(
          (total, product) =>
            (total =
              total +
              product.count *
                (product.sale_price ? product.sale_price : product.price)),
          0
        )
      : 0;

    setTotalPrice(_totalPrice);
  });

  useEffect(() => {
    calcTotal();
  }, [cart]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <div className="mt-[51px] text-center text-[25px] md:text-[35px] 2xl:text-[54px] leading-normal font-[700]">
          {t("shawn_online_shop")}
        </div>
        <div className="mt-[28px] flex mx-auto gap-[0] lg:gap-[15px]">
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
          <div className="w-[100%] mx-[15px] md:mx-0 lg:w-[66%] 2xl:w-[76.0418%] flex flex-wrap items-center text-[#4D4D4D]">
            <Breadcrumb
              text={t("all_goods")}
              count={cart.length > 0 ? cart.length : 0}
            />

            <div className="bg-[#4D4D4D] h-[1px] w-full m-[6px_0_8px_0]"></div>
          </div>
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
        </div>

        <div className="flex mx-auto gap-[0] lg:gap-[15px]">
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
          <div className="w-[100%] mx-[15px] md:mx-0 lg:w-[66%] 2xl:w-[76.0418%]">
            <div className="w-full bg-black text-white rounded-[20px_20px_0_0] text-center py-[10px] ">
              {t("your_shipping_cart")}
            </div>

            <div className="flex gap-[10px] w-full py-[10px]">
              <div className="w-full">
                <div className="p-[10px] bg-white rounded-[20px]">
                  <CartAddressCard />
                </div>
              </div>
              <div className="w-full">
                {cart &&
                  cart.length > 0 &&
                  cart.map((item, index) => {
                    return <CartProductItemCard item={item} key={index} />;
                  })}

                <div className="p-[15px]">
                  <div className="flex items-center">
                    <div className="w-[80%]">
                      {t("prices_goods")} ({cart.length})
                    </div>
                    <div className="w-[20%] flex justify-end text-[#0BC154] text-[22px]">
                      <Price price={totalPrice} />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-[80%]">{t("total_shopping_cart")}</div>
                    <div className="w-[20%] flex justify-end text-[#0BC154] text-[22px]">
                      <Price price={totalPrice} />
                    </div>
                  </div>
                  {cart && (
                    <div className="flex items-center">
                      <div className="w-[80%]">{t("your_profit_purchase")}</div>
                      <div className="w-[20%] flex justify-end text-[#0BC154] text-[22px]">
                        <Price
                          price={cart?.reduce(
                            (total, product) =>
                              (total =
                                total +
                                (product.parent_price > 0
                                  ? (product.parent_price - product.price) *
                                    product.count
                                  : 0)),
                            0
                          )}
                        />
                      </div>
                    </div>
                  )}

                  <Link
                    to="/checkout"
                    className="p-[5px] mt-[10px] inline-block shadow text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
                  >
                    {t("order")}
                  </Link>
                  <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] my-[15px]"></div>
                  <div className="text-[11px]">{t("order_description")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
        </div>
      </main>
      <Footer />
    </>
  );
}
