import search_icon from "../../../assets/img/search-dark-icon.svg";
import { SearchBlackIcon, SearchIcon } from "../icons";

export default function SearchBox(props) {
  return (
    <form className="flex items-center p-[5px] border-[1.5px] border-solid border-[#D9D9D9] rounded-[20px]">
      <input
        value={props.value}
        onChange={(event) => {
          props.changeHandler(event.target.value);
        }}
        placeholder="search"
        className="w-[calc(100%-10px)] bg-transparent text-[#4D4D4D] text-[12px] font-[500] leading-normal"
      />
      <span className="w-[10px] ltr:mr-[7px] rtl:ml-[7px]">
        <SearchBlackIcon />
      </span>
      {/* <img src={search_icon} alt="" className="w-[15px] 2xl:w-[18px] cursor-pointer ltr:mr-[5px] rtl:ml-[5px]"/> */}
    </form>
  );
}
