import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from "react";
import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import WishlistIcon from "../wishlist/wishlisticon";
import ShareButtonIcon from "../sharebutton/sharebutton";
import ProductQuickView from "../product-quick-view/product-quick-view";
import AppContext from "../../context/context";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../assets/img/close_icon.svg";
import ProductDefaultImage from "../../../assets/img/product-placeholder.png";
import { ProductVariantIcon } from "../icons";

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export default function ProductCard(props) {
  const { cart } = useContext(AppContext);
  const { t } = useTranslation();
  const product = props.product;

  const attributes = product.attributes
    ? JSON.parse(product.attributes).filter((item) => item.attribute_type !== 3)
    : [];
  const packages = JSON.parse(product.attributes).filter(
    (item) => item.attribute_type === 3
  );
  const metas = product.metas ? JSON.parse(product.metas) : [];
  const [selectedAttribute, setSelectedAttribute] = useState(
    attributes.length > 0 ? attributes[0] : packages[0]
  );
  let images = product.images ? product.images.split(",") : [];

  let att_images = attributes.filter((attribute) => {
    return attribute.thumbnail !== null;
  });
  if (att_images.length > 0) {
    images = [
      ...images,
      att_images.map((item) => {
        return item.thumbnail;
      }),
    ];
  }
  const [activeImage, setActiveImage] = useState(
    images.length > 0 && images[0]
  );
  const [toggle, setToggle] = useState(false);
  const firstVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });
  const secondVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });

  const [selectedVariant, setSelectedVariant] = useState({
    first: null,
    second: null,
  });

  if (product.product_attributes && product.product_attributes.length > 0) {
    firstVariant.current.items = [];
    secondVariant.current.items = [];
    product.product_attributes.forEach((element) => {
      if (firstVariant.current.type === 0) {
        firstVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [element],
        };
      } else if (firstVariant.current.type === element.attribute_type) {
        firstVariant.current = {
          ...firstVariant.current,
          items: [...firstVariant.current.items, element],
        };
      } else {
        secondVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [...secondVariant.current.items, element],
        };
      }
    });
  }

  useEffect(() => {
    if (attributes) {
      setSelectedAttribute(attributes[0]);

      if (attributes[0].attribute_id) {
        const ids = attributes[0].attribute_id.split(",");

        if (ids[0] && ids[1]) {
          setSelectedVariant({ first: +ids[0], second: +ids[1] });
        } else if (ids[0] && !ids[1]) {
          setSelectedVariant({ first: +ids[0], second: null });
        }
      }
    }
  }, [selectedAttribute,setSelectedAttribute]);

  return (
    <div
      className={`flex relative flex-col min-w-auto 2xl:min-w-[20rem] flex-wrap md:flex-nowrap rounded-[20px] overflow-hidden bg-white  shadow-[0_4px_10px_0_rgba(0,0,0,0.10)] ${
        props.classes ? props.classes : ""
      }`}
      onMouseEnter={() => {
        if (images.length > 1) {
          setActiveImage(images[1]);
        }
      }}
      onMouseLeave={() => {
        if (images.length > 0) {
          setActiveImage(images[0]);
        }
      }}
    >
      {!props.hideThumbnail && (
        <>
          <div className="relative w-full">
            <div className="shadow-[0_4px_20px_0_rgba(0,0,0,0.20)]">
              <div className="flex">
                <div className="flex items-center w-[40%] gap-[10px] p-[10px_15px]">
                  {attributes.length > 1 && (
                    <span
                      className="cursor-pointer"
                      onClick={(event) => {
                        setToggle(true);
                      }}
                    >
                      <ProductVariantIcon className="w-[18px] 2xl:w-auto" />
                    </span>
                  )}

                  <ProductQuickView product={product} />
                  <ShareButtonIcon product={product} />
                  <WishlistIcon productId={product.id} />
                </div>
                <div className="w-[60%] flex justify-end">
                  {metas &&
                    metas.map((meta, index) => {
                      if (meta.display !== 0) {
                        return (
                          <span
                            key={index}
                            className={`bg-[#FFD954] text-[#61562E] ltr:rounded-[0_0_0_15px] rtl:rounded-[0_15px_0_0] text-[14px] 2xl:text-[18px] w-[60px] h-[45px] ltr:pl-[15px] ltr:pr-[30px] rtl:pr-[15px] rtl:pl-[30px] ltr:mr-[-15px] rtl:ml-[-15px] flex items-center justify-center shadow`}
                          >
                            {meta.name}
                          </span>
                        );
                      } else {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                    })}
                  {!props.hideDiscount &&
                  selectedAttribute &&
                  selectedAttribute.sale_price ? (
                    <span className="bg-[#FF547D] text-[#F9F9F9] rounded-[0_15px] text-[14px] 2xl:text-[18px] w-[45px] h-[45px] flex items-center justify-center shadow">
                      {(
                        100 -
                        (+selectedAttribute.sale_price * 100) /
                          +selectedAttribute.price
                      ).toFixed(0)}
                      %
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Link
                to={`/product/${product.slug}`}
                className="block"
              >
                <LazyLoadImage
                  src={activeImage ? activeImage : ProductDefaultImage}
                  alt={product.name}
                  className="block w-auto max-w-auto lg:max-w-[15.36458333vw] h-auto max-h-auto lg:max-h-[15.36458333vw] m-auto"
                />
                {cart &&
                  product.product_attributes.length > 1 &&
                  cart.filter((item) => item.product_id === product.id).length >
                    0 && (
                    <div className="absolute bottom-0 ltr:left-[15px] rtl:right-[15px]">
                      {cart
                        .filter((item) => item.product_id === product.id)
                        .map((item_product, index) => {
                          return (
                            <div
                              className="flex flex-wrap mt-[3px]"
                              key={index}
                            >
                              {item_product.attributes.map(
                                (item_att, index) => {
                                  return (
                                    <span
                                      className="bg-[#603085] text-white text-[12px] rounded-[20px] flex items-center justify-start p-[3px_20px] ltr:mr-[3px] rtl:ml-[3px]"
                                      key={index}
                                    >
                                      {item_att.type === 1 && (
                                        <span
                                          className={`w-[15px] h-[15px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                          style={{
                                            background: item_att.value,
                                          }}
                                        ></span>
                                      )}
                                      {item_att.name}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
              </Link>
            </div>
            <div className="px-[16px] mt-[15px] 2xl:mt-0">
              <div className="text-[18px] 2xl:text-[32px] font-[600] leading-normal text-[#4B4B4B]">
                <Link to={`/product/${product.slug}`}>{product.name}</Link>
              </div>
              <div className="text-[11px] 2xl:text-[18px] text-[#4B4B4B] leading-normal">
                {selectedAttribute && getText(selectedAttribute.excerpt)}
              </div>
            </div>
          </div>
          <div className="px-[16px] pb-[12px] pt-0 mt-auto">
            <div className="flex items-end justify-end mt-[8px]">
              {!props.hidePrice && selectedAttribute && (
                <div className="w-[calc(100%-80px)]">
                  {!selectedAttribute.sale_price ? (
                    <span className="text-[#0BC154] text-[18px] 2xl:text-[32px] leading-normal">
                      <Price price={selectedAttribute.price} />
                    </span>
                  ) : (
                    <>
                      <div className="text-[#0BC154] text-[18px] 2xl:text-[32px] leading-normal">
                        <Price price={selectedAttribute.sale_price} />
                      </div>
                      <div className="text-[14px] 2xl:text-[18px] leading-normal text-[#FF547D] ltr:ml-[7px] rtl:mr-[7px]">
                        <del>
                          <Price price={selectedAttribute.price} />
                        </del>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="w-[80px] flex justify-end">
                {attributes.length === 1 ? (
                  <AddToCartButton
                    attribute={selectedAttribute}
                    text={t("add")}
                    buttonText=" "
                  />
                ) : (
                  <span
                    className={`cursor-pointer p-[4px_17px] text-[14px] 2xl:text-[24px] inline-block text-[#E6E6E6] bg-[#000] rounded-[100px]`}
                    onClick={() => {
                      setToggle(true);
                    }}
                  >
                    {t("add")}
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {attributes.length > 1 && (
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } flex-col absolute top-0 left-0 w-full h-full bg-white px-[10px] pb-[10px]`}
        >
          <span className="flex items-center justify-center w-[25px] h-[25px] rounded-full bg-[#00000033] absolute top-[10px] ltr:right-[10px] rtl:left-[10px]">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] rounded-full cursor-pointer"
              onClick={() => {
                setToggle(false);
              }}
            />
          </span>
          <div className="p-[10px_0]">
            <div className="text-[18px] md:text-[25px] 2xl:text-[35px] text-[#1E1E1EBB]">
              {product.name}
            </div>
          </div>
          <div className="h-[1px] w-full bg-[#00000033]"></div>

          {product.product_attributes ? (
            <>
              {firstVariant.current.items.length > 0 && (
                <>
                  <div className="mt-[15px]">
                    {firstVariant.current.type_name}
                  </div>

                  <div className=" flex flex-wrap gap-[5px]">
                    {firstVariant.current.items.map((variant, index) => {
                      return (
                        <div
                          className={`w-[calc(50%-4px)] p-[3px] ${
                            selectedVariant.first === variant.attribute_id
                              ? "bg-[#603085] text-white"
                              : "bg-[#D9D9D9] text-[#626262]"
                          }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                          key={index}
                          onClick={() => {
                            setSelectedVariant({
                              ...selectedVariant,
                              first: variant.attribute_id,
                            });
                            if (secondVariant.current.items.length === 0) {
                              setSelectedAttribute(
                                attributes.filter((item) => {
                                  return (
                                    +item.attribute_id === +variant.attribute_id
                                  );
                                })[0]
                              );
                            } else if (selectedVariant.second) {
                              setSelectedAttribute(
                                attributes.filter((item) => {
                                  return (
                                    item.attribute_id ==
                                      variant.attribute_id +
                                        "," +
                                        selectedVariant.second ||
                                    item.attribute_id ==
                                      selectedVariant.second +
                                        "," +
                                        variant.attribute_id
                                  );
                                })[0]
                              );
                            }
                          }}
                        >
                          {variant.attribute_type === 1 && (
                            <span
                              className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                              style={{
                                background: variant.attribute_value,
                              }}
                            ></span>
                          )}
                          {variant.attribute_name}
                        </div>
                      );
                    })}
                  </div>
                  {selectedVariant.first &&
                    firstVariant.current.items &&
                    firstVariant.current.items.length > 0 &&
                    firstVariant.current.items.filter((item) => {
                      return item.attribute_id === selectedVariant.first;
                    })[0] &&
                    firstVariant.current.items.filter((item) => {
                      return item.attribute_id === selectedVariant.first;
                    })[0].attribute_description && (
                      <>
                        <div className="text-[12px] 2xl:text-[14px]">
                          {firstVariant.current.type_name +
                            " " +
                            t("description")}
                        </div>

                        <div className="text-[10px] 2xl:text-[12px]">
                          {
                            firstVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.first
                              );
                            })[0].attribute_description
                          }
                        </div>
                      </>
                    )}
                </>
              )}

              {secondVariant.current.items &&
                secondVariant.current.items.length > 0 && (
                  <>
                    <div className="h-[1px] w-full bg-[#00000033] my-[10px]"></div>
                    <div className="">{secondVariant.current.type_name}</div>
                    <div className=" flex flex-wrap gap-[5px]">
                      {secondVariant.current.items.map((variant, index) => {
                        return (
                          <div
                            className={`w-[calc(50%-4px)] p-[3px] ${
                              selectedVariant.second === variant.attribute_id
                                ? "bg-[#603085] text-white"
                                : "bg-[#D9D9D9] text-[#626262]"
                            }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-start cursor-pointer`}
                            key={index}
                            onClick={() => {
                              setSelectedVariant({
                                ...selectedVariant,
                                second: variant.attribute_id,
                              });

                              setSelectedAttribute(
                                attributes.filter((item) => {
                                  return (
                                    item.attribute_id ==
                                      selectedVariant.first +
                                        "," +
                                        variant.attribute_id ||
                                    item.attribute_id ==
                                      variant.attribute_id +
                                        "," +
                                        selectedVariant.first
                                  );
                                })[0]
                              );
                            }}
                          >
                            {variant.attribute_type === 1 && (
                              <span
                                className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                style={{
                                  background: variant.attribute_value,
                                }}
                              ></span>
                            )}
                            {variant.attribute_name}
                          </div>
                        );
                      })}
                    </div>
                    {selectedVariant.second &&
                      secondVariant.current.items &&
                      secondVariant.current.items.length > 0 &&
                      secondVariant.current.items.filter((item) => {
                        return item.attribute_id === selectedVariant.second;
                      })[0] &&
                      secondVariant.current.items.filter((item) => {
                        return item.attribute_id === selectedVariant.second;
                      })[0].attribute_description && (
                        <>
                          <div className="text-[12px] 2xl:text-[14px]">
                            {secondVariant.current.type_name +
                              " " +
                              t("description")}
                          </div>
                          <div className="text-[10px] 2xl:text-[12px]">
                            {
                              secondVariant.current.items.filter((item) => {
                                return (
                                  item.attribute_id === selectedVariant.second
                                );
                              })[0].attribute_description
                            }
                          </div>
                        </>
                      )}
                  </>
                )}
            </>
          ) : (
            <div className=" flex flex-wrap gap-[5px] mt-[15px]">
              {attributes.map((attribute, index) => {
                return (
                  <div
                    className={`w-[calc(50%-4px)] p-[3px] ${
                      attribute.id === selectedAttribute.id
                        ? "bg-[#603085] text-white"
                        : "bg-[#D9D9D9] text-[#626262]"
                    }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                    key={index}
                    onClick={() => {
                      setSelectedAttribute(attribute);
                    }}
                  >
                    {attribute.attribute_type === 1 && (
                      <span
                        className={`w-[20px] h-[20px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                        style={{
                          background: attribute.attribute_value,
                        }}
                      ></span>
                    )}
                    {attribute.attribute_name}
                  </div>
                );
              })}
            </div>
          )}

          <div className="flex items-end flex-grow-[1] justify-end">
            {selectedAttribute && (
              <AddToCartButton
                attribute={selectedAttribute}
                text={t("submit")}
                classes="bg-[#000] hover:bg-[#603085] w-full text-center"
                clickHandler={() => {
                  // setToggle(false);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
