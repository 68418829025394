import { useTranslation } from "react-i18next";
import person_icon from "../../../assets/img/person_icon.svg";
import InfoIcon from "../../../assets/img/info_profile_icon.svg";
import CloseIcon from "../../../assets/img/close_icon.svg";
import UserIcon from "../../../assets/img/user_icon.svg";
import PetIcon from "../../../assets/img/pet_icon.svg";
import PetBigIcon from "../../../assets/img/pet_big_icon.svg";
import CartIcon from "../../../assets/img/cart_profile_icon.svg";
import NotificationIcon from "../../../assets/img/notification_icon.svg";
import NotificationBigIcon from "../../../assets/img/notification_big_icon.svg";
import CartBigIcon from "../../../assets/img/cart_big_icon.svg";
import { useState } from "react";
import { Box, Dialog, Hidden, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppContext from "../../context/context";
import { useContext } from "react";
import LoginCard from "../login-card/login-card";
import RegisterCard from "../register-card/register-card";
import ProfileCard from "../profile-card/profile-card";
import PetProfileCard from "../pet-profile-card/pet-profile-card";
import ResetPasswordCard from "../reset-password-card/reset-password-card";
import PurchaseCard from "../purchase-card/purchase-card";
import NotificationCard from "../notification-card/notification-card";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    // maxWidth: 40,
    width: "100%",
    backgroundColor: "#000",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flexDirection: { xs: "column", md: "initial" },
    gap: "5px",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#000",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#3333",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ overflowY: "auto" }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MenuAccount() {
  const { getToken } = useContext(AppContext);
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <img
        src={person_icon}
        alt={t("website_name")}
        className="w-[20px] cursor-pointer"
        onClick={() => {
          setToggle(true);
        }}
      />
      <Dialog
        open={toggle}
        onClose={() => {
          setToggle(false);
        }}
        scroll="body"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="sh-blur"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { xs: "100%", md: "60%" },
              margin: { xs: "0", md: "32px" },
            },
          },
        }}
      >
        <div className="bg-[#fff] rounded-[20px] p-[15px] md:p-[30px] relative">
          <div className="absolute top-[10px] ltr:right-[10px] rtl:left-[10px]">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] bg-[#00000033] rounded-full cursor-pointer"
              onClick={() => {
                setToggle(false);
              }}
            />
          </div>
          <div className="flex justify-center">
            {!getToken() ? (
              <>
                {page === 0 ? (
                  <img src={InfoIcon} alt="" />
                ) : page === 1 ? (
                  <img src={InfoIcon} alt="" />
                ) : (
                  page === 2 && <img src={InfoIcon} alt="" />
                )}
              </>
            ) : (
              <>
                {page === 0 ? (
                  <img src={InfoIcon} alt="" />
                ) : page === 1 ? (
                  <img src={PetBigIcon} alt="" />
                ) : page === 2 ? (
                  <img src={CartBigIcon} alt="" />
                ) : (
                  <img src={NotificationBigIcon} alt="" />
                )}
              </>
            )}
          </div>
          <div className="mt-[15px] text-center">
            {!getToken() ? (
              <>
                {page === 0 ? (
                  <>
                    <div className="font-[700] text-[25px]">{t('login')}</div>
                    <div>{t('login_description')}</div>
                  </>
                ) : page === 1 ? (
                  <>
                    <div className="font-[700] text-[25px]">{t('register')}</div>
                    <div>{t('register_description')}</div>
                  </>
                ) : (
                  page === 2 && (
                    <>
                      <div className="font-[700] text-[25px]">
                        {t('reset_password')}
                      </div>
                      <div>{t('reset_password_description')}</div>
                    </>
                  )
                )}
              </>
            ) : (
              <>
                {page === 0 ? (
                  <>
                    <div className="font-[700] text-[25px]">
                      {t('your_information')}
                    </div>
                    <div>
                      {t('your_information_description')}
                    </div>
                  </>
                ) : page === 1 ? (
                  <>
                    <div className="font-[700] text-[25px]">
                      {t('your_pet_information')}
                    </div>
                    <div>
                      {t('your_pet_information_description')}
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className="font-[700] text-[25px]">
                      {t('your_purchase_history')}
                    </div>
                    <div>
                      {t('your_purchase_history_description')}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="font-[700] text-[25px]">
                      {t('your_notifications')}
                    </div>
                    <div>
                      {t('your_notifications_description')}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="">
              <Box sx={{ width: "100%" }}>
                <Box>
                  {!getToken() ? (
                    <StyledTabs
                      value={activeTab}
                      onChange={(event, newValue) => {
                        setActiveTab(newValue);
                      }}
                      variant="fullWidth"
                    >
                      <StyledTab
                        icon={<img src={UserIcon} alt="" />}
                        label={t('login')}
                        onClick={() => {
                          setPage(0);
                        }}
                      />
                      <StyledTab
                        icon={<img src={UserIcon} alt="" />}
                        label={t('register')}
                        onClick={() => {
                          setPage(1);
                        }}
                      />
                      <StyledTab
                        icon={<img src={UserIcon} alt="" />}
                        label={t('reset_password')}
                        onClick={() => {
                          setPage(2);
                        }}
                      />
                    </StyledTabs>
                  ) : (
                    <StyledTabs
                      value={activeTab}
                      onChange={(event, newValue) => {
                        setActiveTab(newValue);
                      }}
                      variant="fullWidth"
                    >
                      <StyledTab
                        icon={<img src={UserIcon} alt="" />}
                        label={t('your_profile')}
                        onClick={() => {
                          setPage(0);
                        }}
                      />
                      <StyledTab
                        icon={<img src={PetIcon} alt="" />}
                        label={t('pet_profile')}
                        onClick={() => {
                          setPage(1);
                        }}
                      />
                      <StyledTab
                        icon={<img src={CartIcon} alt="" />}
                        label={t('purchase_history')}
                        onClick={() => {
                          setPage(2);
                        }}
                      />
                      <StyledTab
                        icon={<img src={NotificationIcon} alt="" />}
                        label={t('notifications')}
                        onClick={() => {
                          setPage(3);
                        }}
                      />
                    </StyledTabs>
                  )}
                  <Box sx={{ p: 3 }} />
                </Box>
                {!getToken() ? (
                  <>
                    <TabPanel value={activeTab} index={0}>
                      <LoginCard settoggle={setToggle} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <RegisterCard settoggle={setToggle} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                      <ResetPasswordCard settoggle={setToggle} />
                    </TabPanel>
                  </>
                ) : (
                  <>
                    <TabPanel value={activeTab} index={0}>
                      <ProfileCard settoggle={setToggle} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <PetProfileCard />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                      <PurchaseCard />
                    </TabPanel>
                    <TabPanel value={activeTab} index={3}>
                      <NotificationCard />
                    </TabPanel>
                  </>
                )}
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
