import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["en"];
const availableLanguages = ["en",'ar'];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    resources: {
      en: {
        translation: {
          website_name: "Shawn Pet Shop",
          website_description: "Pet supplies store",
          shawn:'Shawn',
          shop:'Shop',
          blog:'Blog',
          products: "Products",
          articles: "Articles",
          all_articles:'All articles',
          all_goods:'All goods',
          items:'Items',
          trust_the_tilt: "Trust The Tilt",
          shawn_online_shop: "SHAWN ONLINE SHOP",
          shawn_online_blog: "SHAWN ONLINE BLOG",
          home_description_1:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...",
          no_category: "No category to display.",
          for_your_pet: "For Your Pet",
          hot_discount: "Hot Discount",
          hot_discount_description:
            "Take advantage of these unique discounts before it's too late",
          goto_discounts: "Go to the discounts page",
          dolar: "$",
          dirham: 'AED ',
          shawn_services: "shawn services",
          shawn_services_description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...",
          more: "More  >",
          new_products: "New Products",
          magazine: "MAGAZINE",
          home_magazine_description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...",
          recive_articles_email: "Receive articles by email",
          in_store: "In-store",
          discount:'Discount',
          discounts: "Discounts",
          off_15: "15% OFF",
          home_in_store_description:
            "Special discounts on the occasion of the opening of the new Shawn branch",
          more_news: "More News",
          address: "Address",
          address_name: "Address Name",
          address_placeholder: "Write your address",
          add_address: "+ New Address",
          your_shipping_cart: "Your shopping cart",
          payment: "Payment",
          sku: "SKU",
          add: "Add",
          send: "Send >",
          code_resent: "code resent",
          confirm_Code: "Confirm Code",
          activation: "Activation",
          resend_activation_code: "Resend activation code",
          product_test_guarantee: "Product test guarantee",
          letme_know_if_available: "Let me know if product is available",
          email: "Email",
          register: "Register",
          back: "Back",
          username_email: "username or email",
          password: "password",
          login: "Login",
          login_description: "Login to your account",
          register_description: "Register an account to start buying",
          reset_password: "Reset Password",
          reset_password_description: "Reset your password if you forgot it.",
          your_information: "Your Information",
          your_information_description:
            "Get a 10% discount code by completing your profile information",
          your_pet_information: "Your Pet information",
          your_pet_information_description:
            "Enter your pet's profile so that we can update articles and products according to its needs",
          your_purchase_history: "Your purchase history",
          your_purchase_history_description:
            "All your purchases can be seen both online and in person if you are a member of the customer club",
          your_notifications: "Your notifications",
          your_notifications_description:
            "Read news about new products and discounts or events well to benefit from them",
          your_profile: "Your profile",
          pet_profile: "Pet profile",
          purchase_history: "Purchase history",
          notifications: "Notifications",
          total: "Total",
          cart: "Cart",
          order: "Order",
          order_description: 'The cost of this order has not been paid yet, and if the stock runs out, the goods will be removed from the basket',
          profile: "Profile",
          species: "Species",
          gender: "Gender",
          type: "Type",
          age: "Age",
          description: "Description",
          male: "Male",
          female: "Female",
          pet_name: "pet name",
          pet_species: "pet species",
          breed_type: "breed type",
          birth: "Birth",
          pet_interests: "pet interests",
          more_articles: "More Articles",
          from: "From",
          untile_the: "Untile The",
          about_the_product: "About the product",
          original_price: "Original price",
          transaction_price: "Transaction price",
          your_profit: "Your profit",
          no: "No",
          product_rating: "Product rating",
          one_day_delivery: "One day delivery",
          price: "Price",
          unavailable: "Unavailable",
          add_to_cart: "Add To Cart",
          filter: "Filter",
          taste_filter: "Taste Filter",
          product_type: "Product Type",
          current_password: "current password",
          confirm_password: "confirm password",
          change: "Change",
          name: "Name",
          last_name: "last name",
          mobile: "mobile",
          username: "username",
          edit: "Edit",
          change_password: "Change password",
          logout: "Logout",
          order_number: "Order Number",
          date: "Date",
          commodity: "Commodity",
          count: "Count",
          total_purchase: "Total purchase",
          reset: "Reset",
          you: "You",
          shipping_all_parts_country: "Shipping to all parts of the country",
          product_quality_assurance: "Product quality assurance",
          money_back_guarantee: "Money back guarantee",
          excellent_response: "Excellent response",
          tel: "Tel",
          web: "Website",
          copyright: "Allrights reserved.",
          license_no: "License No",
          register_no: "Register No",
          filters: "Filters",
          direct_faster_shipping: "Direct and faster shipping",
          goods:'goods',
          prices_goods:'Prices of goods',
          total_shopping_cart:'Total shopping cart',
          your_profit_purchase:'Your profit from the purchase',
          checkout:'Checkout',
          province:'Province',
          postal_code:'Postal Code',
          purchase_note:'Purchase Note',
          discount_code:'Discount Code',
          sub_total:'Sub Total',
          purchase:'Purchase',
          back_to_store:'Back To Store',
          contact_us:'contact us',
          email_mobile:'email or mobile',
          your_message:'your message',
          similar_posts:'Similar Posts',
          about_post:'About Post',
          close:'Close',
          open:'Open',
          delete_transaction:'Delete transaction',
          similar_products:'Similar Products',
          about_product:'About Product',
          deals:'Deals',
          free_shipping:'Free shipping',
          charity: 'Charity',
          training_behaviour: 'Training and behaviour',
          outdoor_shop: 'Outdoor Shop',
          dog_walker:'Dog Walker',
          treatment: 'Treatment',
          learning_center:'Learning Center',
          submit: 'Submit',
          add_plus: '+ Add',
          type_your_comment: 'Type your comment',
          expert_check:'Expert check',
          specifications:'Specifications',
          opinions:'Opinions',
          FAQ:'FAQ',
          there_is_no_product:'There is no product.',
          days:'Days',
          hours:'Hours',
          minutes:'Minutes',
          seconds:'Seconds',
          transfer_to_bank: "Transfer to the bank portal",
          try_again: "Try Again",
          length: 'Length',
          centimeter: 'cm',
          width: 'Width',
          height: 'Height',
          weight: 'Weight',
          factor: 'Factor',
          choose_goods_with_shawn:"Choose goods with Shawn",
          choose_goods_with_shawn_message:"Shawn trading co L.L.C established in 2023 in Dubai, UAE as a hub for retail and chain stores and regional wholesale. We started the business in Dubai as it has the greatest potential within GCC region. The holding company is active in production and distribution of pet supplies to large number of wholesalers, chain stores and retails etc.The platforms are online and offline. The scope of the business is planned to expand in many aspects such as product ranges, countries, industries, etc.",
          order_date: 'Order Date',
          reference_number:'Transaction Reference',
          unit_price: 'Unit Price',
          tax: 'Tax',
          customer_name: 'Customer Name',
          company_name: 'Company Name',
          phone: 'Phone',
          online_shopping: 'Online Shopping',
          open_invoice: 'Open Invoice',
          buy_person: 'Buy Person',
          search: "Search",
          pet_supplies_store: "Pet supplies store",
          the_newest: "The Newest",
          bestselling:"Bestselling",
          cheapest: "Cheapest",
          the_most_expensive: "The Most Expensive",
          message_enter_activation_code:'Enter activation code.',
          message_product_removed: "Product removed from cart.",
          message_product_out_of_stock: "Product is out of stock.",
          message_product_added: "Product successfully added to your cart.",
          message_proccess_error: "Error in proccess.",
          message_enter_address_name: "Enter your address name.",
          message_enter_address: "Enter your address.",
          message_enter_email: "Enter your email.",
          message_cart_no_products: "No Products in your cart",
          message_no_notifications: "There is no notification.",
          message_no_orders: "There is no order history.",
          message_no_post:'There is no post.',
          message_product_review:
            "people) of buyers have suggested this product",
          message_account_activation: "Your account activated successfully.",
          message_you_added_exists_notification:
            "Your request added to list successfully.",
          message_you_already_exists_notification:
            "Your request already added to list.",
          message_you_loggedin: "You are logged in.",
          message_enter_current_password: "Enter your current password.",
          message_enter_new_password: "Enter your new password.",
          message_enter_confirm_password: "Enter your confirm password.",
          message_passwords_not_match:
            "Your password and confirm password are not match.",
          message_passwords_changed: "Your password changed.",
          message_enter_required_fields: "Enter required fileds (*).",
          message_enter_name: "Enter your name.",
          message_enter_last_name: "Enter your last name.",
          message_enter_username: "Enter your username.",
          message_enter_password: "Enter your password.",
          message_enter_province: "Enter your province.",
          message_enter_message: "Enter your message.",
          message_enter_tel_or_mobile: "Enter your telephone number or mobile.",
          message_account_created: "Your account created.",
          message_request_sent: "Your request has been send",
          message_request_not_sent: "Your request has not been send",
          message_enter_comment: "TYPE YOUR MASSAGE:",
          message_added_wishlist: "Added to wishlist.",
          message_removed_wishlist: "Removed from wishlist",
          message_wrong_discount_code: "Wrong discount code.",
          message_transfer_to_bank:
            "If there is no automatic transfer to the portal, click on the button.",
            message_bank_duplicate_request: 'Please wait for a minute and try again'
        },
      },
    },
  });

export default i18n;
