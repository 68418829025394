import { useTranslation } from "react-i18next";
import cart_icon from "../../../assets/img/cart_icon.png";
import { Badge, Drawer } from "@mui/material";
import { useContext, useState } from "react";
import CloseIcon from "../../../assets/img/close_icon.svg";
import { Link } from "react-router-dom";
import Price from "../price/price";
import CartItemCard from "../cart-item-card/cart-item-card";
import AppContext from "../../context/context";

export default function MenuCart() {
  const { cart } = useContext(AppContext);
  const [showCart, setShowCart] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {cart?.length > 0 ? (
        <Badge
          badgeContent={cart.length}
          color="purple"
          sx={{
            width: "20px",
            height: "20px",
          }}
        >
          <img
            src={cart_icon}
            alt={t("website_name")}
            className="w-[20px] inline-block cursor-pointer"
            onClick={(event) => {
              event.preventDefault();
              setShowCart(true);
            }}
          />
        </Badge>
      ) : (
        <img
          src={cart_icon}
          alt={t("website_name")}
          className="w-[20px] inline-block cursor-pointer"
          onClick={(event) => {
            event.preventDefault();
            setShowCart(true);
          }}
        />
      )}
      <Drawer
        anchor="right"
        open={showCart}
        onClose={() => {
          setShowCart(false);
        }}
        className="sh-blur"
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              md: "25%",
            },
            borderRadius: "15px",
          },
        }}
      >
        <div className="bg-[#fff] shadow rounded-[20px]">
          <div className="flex items-center bg-[#000] p-[5px] text-[#fff] ltr:rounded-[15px_15px_0_0] rtl:rounded-[0_0_15px_15px]">
            <div className="w-[75%] px-[10px]">{t("your_shipping_cart")}</div>
            <div className="w-[25%] flex justify-end">
              <img
                src={CloseIcon}
                alt=""
                className="p-[5px] bg-[#fff] rounded-full cursor-pointer"
                onClick={() => {
                  setShowCart(false);
                }}
              />
            </div>
          </div>

          {cart && cart.length > 0 ? (
            <>
              <div className="p-[15px]">
                {cart.map((item, index) => {
                  return <CartItemCard item={item} key={index} />;
                })}
              </div>
              <div className="p-[15px_5px] mx-[5px] flex items-center border-solid border-t-[1px] border-[#BEBEBE]">
                <div className="w-full text-[20px] text-[#666]">
                  <span className="font-[700] text-[#000]">{t("total")}: </span>
                  <span className="text-[#0BC154]">
                    <Price
                      price={cart?.reduce(
                        (total, product) =>
                          (total =
                            total +
                            product.count *
                              (product.sale_price
                                ? product.sale_price
                                : product.price)),
                        0
                      )}
                    />
                  </span>
                </div>
                <div className="w-full flex justify-end">
                  <Link
                    to="/cart"
                    className="p-[3px_20px] text-[#fff] bg-[#0BC154] rounded-[15px] ltr:mr-[5px] rtl:ml-[5px]"
                  >
                    {t("cart")}
                  </Link>
                  <Link
                    to="/checkout"
                    className="p-[3px_20px] text-[#fff] bg-[#0BC154] rounded-[15px]"
                  >
                    {t("order")}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center py-[30px]">
              {t("message_cart_no_products")}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
