import { useContext, useState } from "react";
import AppContext from "../../context/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTelegramPlane,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener } from "@mui/material";
import { ProductShareIcon } from "../icons";

export default function ShareButtonIcon(props) {
  const [openShare, setOpenShare] = useState(false);
  const { WEBSITE_URL } = useContext(AppContext);
  return (
    <span className="share-button cursor-pointer">
      <span
        onClick={() => {
          setOpenShare(!openShare);
        }}
      >
        <ProductShareIcon className="w-[16px] 2xl:w-auto" />
      </span>
      {openShare && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenShare(false);
          }}
        >
          <span
            className={`absolute items-center justify-center cursor-pointer text-[20px] !p-[10px_10px] md:w-auto text-[#000] ltr:left-0 rtl:right-0`}
          >
            <div className="flex gap-[10px]">
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${WEBSITE_URL}/product/${props.product.slug}`}
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: document.title,
                      text: props.product.name,
                      url: `${WEBSITE_URL}/product/${props.product.slug}`,
                    });
                  } else {
                    window.open(
                      `https://www.facebook.com/sharer/sharer.php?u=${WEBSITE_URL}/product/${props.product.slug}&display=popup`,
                      "facebook-share-dialog",
                      "width=626,height=436"
                    );
                    return false;
                  }
                }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                href={`https://twitter.com/share?url=${WEBSITE_URL}/product/${props.product.slug}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${WEBSITE_URL}/product/${props.product.slug}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                target="_blank"
                href={`whatsapp://send?text=${WEBSITE_URL}/product/${props.product.slug}`}
                data-action="share/whatsapp/share"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                target="_blank"
                href={`https://t.me/share/url?url=${WEBSITE_URL}/product/${props.product.slug}`}
              >
                <FontAwesomeIcon icon={faTelegramPlane} />
              </a>
              <a
                className="flex hover:text-[var(--theme-color)]"
                rel="nofollow noreferrer"
                target="_blank"
                href={`mailto:?body=${WEBSITE_URL}/product/${props.product.slug}`}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </span>
        </ClickAwayListener>
      )}
    </span>
  );
}
