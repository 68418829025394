import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function Quantity(props) {
  const [value, setValue] = useState(
    props.defaultValue ? props.defaultValue : 0
  );
  return (
    <div className="flex items-center">
      <div
        className="cursor-pointer shadow w-[30px] h-[30px] bg-[#000] text-white flex items-center justify-center rounded-[20px]"
        onClick={() => {
          if (value - 1 >= 0) {
            setValue(value - 1);
            props.valueChange(value - 1);
          }
        }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className="inline-block px-[10px]">{value}</div>
      <div
        className="cursor-pointer shadow w-[30px] h-[30px] bg-[#000] text-white flex items-center justify-center rounded-[20px]"
        onClick={() => {
          setValue(value + 1);
          props.valueChange(value + 1);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
}
