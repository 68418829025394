import FilterIcon from "../../assets/img/filter_icon.svg";
import TrashIcon from "../../assets/img/trash_icon.svg";
import Accordion from "../components/accordion/accordion";
import PriceSlider from "../components/price-slider/price-slider";
import Switcher from "../components/switcher/switcher";
import ProductTypeFilter from "../components/product-type-filter/product-type-filter";
import ProductBrandFilter from "../components/product-brand-filter/product-brand-filter";
import { useContext } from "react";
import AppContext from "../context/context";
import { useTranslation } from "react-i18next";

export default function LeftSidebar() {
  const { filters, removeFilters, setFilters, getToken } =
    useContext(AppContext);
    const { t } = useTranslation();
  return (
    <aside className="">
      <section className="shadow-[0_4px_20px_0_rgba(0,0,0,0.10)] rounded-0 ltr:lg:rounded-[0_20px_20px_0] rtl:lg:rounded-[20px_0_0_20px] pt-[14px] bg-[rgba(255,255,255,0.50)]">
        <div className="flex items-center px-[15px]">
          <div className="w-full flex">
            <img src={FilterIcon} alt="" className="ltr:mr-[5px] rtl:ml-[5px]" /> {t('filters')}
          </div>
          <div className="w-full flex justify-end text-[11px] text-[#808080]">
            <span
              onClick={() => {
                removeFilters();
              }}
              className="flex w-full justify-end cursor-pointer"
            >
              <img src={TrashIcon} alt="" className="mr-[5px]" /> Remove filters
            </span>
          </div>
        </div>
        <div className="bg-[#9c9c9c] h-[1px] m-[10px_15px]"></div>
        <ProductBrandFilter />
        {getToken() && (
          <div className="flex items-center mt-[15px] px-[15px]" key={1}>
            <div className="w-[80%] text-[13px]">{t('for_your_pet')}</div>
            <div className="w-[20%] flex justify-end">
              <Switcher
                checked={filters.yourPet ? true : false}
                changeHandler={(value) => {
                  setFilters({
                    ...filters,
                    yourPet: value,
                  });
                }}
              />
            </div>
          </div>
        )}

        <div className="flex items-center mt-[15px] px-[15px]">
          <div className="w-[80%] text-[13px]">{t('direct_faster_shipping')}</div>
          <div className="w-[20%] flex justify-end">
            <Switcher
              checked={filters.directFast ? true : false}
              changeHandler={(value) => {
                setFilters({
                  ...filters,
                  directFast: value,
                });
              }}
            />
          </div>
        </div>

        <Accordion title={t('price_range')} expanded="1" classes="mt-[20px]">
          <div className="mt-[15px]">
            <PriceSlider />
          </div>
        </Accordion>
        <ProductTypeFilter />
      </section>
    </aside>
  );
}
