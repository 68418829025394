import PencilIcon from "../../../assets/img/pencil_icon.svg";
import TrashIcon from "../../../assets/img/trash_icon.svg";
import PinIcon from "../../../assets/img/pin_icon.svg";
import AppContext from "../../context/context";
import { useContext, useState } from "react";
import Input from "../input/input";
import Textarea from "../textarea/textarea";
import { useTranslation } from "react-i18next";

export default function AddressItemCard(props) {
  const { PUBLIC_URL, getToken } = useContext(AppContext);
  const item = props.item ? props.item : null;
  const [addressName, setAddressName] = useState("");
  const [address, setAddress] = useState("");
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState({
    addressName: "",
    address: "",
    default: "",
  });
  const { t } = useTranslation();

  async function deleteAddress() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/deleteaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  async function editAddress() {
    try {
      if (!addressName) {
        setError({ ...error, addressName: t('message_enter_address_name') });
        return;
      }
      if (!address) {
        setError({ ...error, address: t('message_enter_address') });
        return;
      }
      await fetch(`${PUBLIC_URL}/api/customer/editaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
          addressName: addressName,
          address: address,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            setAddressName("");
            setAddress("");
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            setError({ ...error, default: t('message_proccess_error') });
          }
        });
    } catch (error) {
      setError({ ...error, default: t('message_proccess_error') });
    }
  }

  async function pinAddress() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/pinaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  return (
    <div className="rounded-[15px] shadow p-[5px] mt-[10px] bg-[#0000000D]">
      <div className="flex items-center">
        <div className="w-[50%]">
          <span className="text-[#000] inline-block p-[3px_15px] bg-[#0000000D] rounded-[15px] text-[11px] md:text-[14px]">
            {edit ? (
              <Input
                type="text"
                value={addressName}
                onchange={(value) => {
                  setAddressName(value);
                  setError({ ...error, addressName: "" });
                }}
                placeholder={t('message_enter_address_name')}
                classes="w-full p-[10px] bg-transparent text-[11px] md:text-[14px]"
              />
            ) : (
              item.name && item.name
            )}
          </span>
        </div>
        <div className="w-[50%] flex justify-end gap-[5px]">
          <span
            className="p-[10px] rounded-full bg-[#0000000D] cursor-pointer"
            onClick={() => {
              if (!error.name && !error.addressName) {
                if (edit) {
                  editAddress();
                } else {
                  setAddressName(item.name);
                  setAddress(item.address);
                }

                setEdit(!edit);
              }
            }}
          >
            <img src={PencilIcon} alt="" className="w-[15px]" />
          </span>
          <span
            className="p-[10px] rounded-full bg-[#0000000D] cursor-pointer"
            onClick={deleteAddress}
          >
            <img src={TrashIcon} alt="" className="w-[15px]" />
          </span>
          <span
            className={`p-[10px] rounded-full ${
              item.main_address ? "bg-[#FFD954]" : "bg-[#0000000D]"
            } cursor-pointer`}
            onClick={pinAddress}
          >
            <img src={PinIcon} alt="" className="w-[15px]" />
          </span>
        </div>
      </div>
      <div className="p-[3px_15px] mt-[5px] bg-[#0000000D] rounded-[15px] text-[11px] md:text-[14px]">
        {edit ? (
          <Textarea
            value={address}
            onchange={(value) => {
              setAddress(value);
              setError({ ...error, address: "" });
            }}
            placeholder={t('address_placeholder')}
            classes="w-full p-[10px] bg-transparent text-[11px] md:text-[14px]"
          />
        ) : (
          item.address
        )}
      </div>
      {error.addressName && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
          {error.addressName}
        </div>
      )}
      {error.address && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
          {error.address}
        </div>
      )}
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
          {error.default}
        </div>
      )}
    </div>
  );
}
