import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../context/context";
import FormButton from "../form-button/form-button";
import Input from "../input/input";
import Textarea from "../textarea/textarea";
import { ClipLoader } from "react-spinners";
import ShopPagination from "../pagination/pagination";
import CartAddressItemCard from "../cart-address-item-card/cart-address-item-card";
import { t } from "i18next";

export default function CartAddressCard(props) {
  const { PUBLIC_URL, getToken } = useContext(AppContext);
  const [addresses, setAddresses] = useState([]);
  const [addressName, setAddressName] = useState("");
  const [address, setAddress] = useState("");
  const [addressLoader, setAddressLoader] = useState(0);
  const [page, setPage] = useState(1);

  const getAddress = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/addresslist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }, [PUBLIC_URL, getToken]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  async function addAddress() {
    try {
      setAddressLoader(1);
      if (!address || !addressName) {
        // setError(t("ramz_ra_vared_konid"));
        setAddressLoader(0);
        return;
      }
      await fetch(`${PUBLIC_URL}/api/customer/addaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressName: addressName,
          address: address,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setAddressLoader(0);
          if (data.addresses) {
            setAddressName("");
            setAddress("");
            setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
      setAddressLoader(0);
    }
  }

  return (
    addresses && (
      <>
        <div className="rounded-[15px] shadow p-[5px] mt-[10px] bg-[#fff]">
          <div className="flex items-center">
            <div className="w-[70%]">
              <span className="text-[#000] inline-block p-[3px_15px] bg-[#0000001A] rounded-[15px]">
                <Input
                  type="text"
                  value={addressName}
                  onchange={(value) => {
                    setAddressName(value);
                  }}
                  placeholder={t('address_name')}
                  classes="w-full p-[10px] bg-transparent"
                />
              </span>
            </div>
          </div>
          <div className="p-[3px_15px] mt-[5px] bg-[#0000001A] rounded-[15px]">
            <Textarea
              value={address}
              onchange={(value) => {
                setAddress(value);
              }}
              placeholder={t('address_placeholder')}
              classes="w-full p-[10px] bg-transparent"
            />
          </div>
        </div>
        <FormButton
          onclick={() => {
            addAddress();
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {addressLoader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t('add_address')}
        </FormButton>
        {addresses && (
          <div className="ltr:text-left rtl:text-right">
            {addresses.map((item, index) => {
              if ((page - 1) * 2 <= index && index < page * 2) {
                return (
                  <CartAddressItemCard
                    item={item}
                    key={index}
                    setAddresses={setAddresses}
                    setPage={setPage}
                    displayAdd={props.displayAdd}
                    addAddress={(value) => {
                      props.addAddress(value);
                    }}
                  />
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
            {addresses.length > 2 && (
              <div className="w-full">
                <ShopPagination
                  pagesCount={
                    addresses.length % 2 === 0
                      ? addresses.length / 2
                      : Math.floor(addresses.length / 2) + 1
                  }
                  page={page}
                  handleChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  );
}
