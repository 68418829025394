import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Banner from "./components/banner/banner";
import ProductCategoryList from "./components/category-list/category-list";
import ShopIcon from "../assets/img/shop_icon_grey.png";
import Footer from "./layout/footer";
import LeftSidebar from "./layout/left-sidebar";
import RightSidebar from "./layout/right-sidebar";
import ProductCard from "./components/productcard/productcard";
import CloseIcon from "../assets/img/close_icon.svg";
import ProductSkeleton from "./components/skeleton/product/product-skeleton";
import { Link } from "react-router-dom";
import ShopPagination from "./components/pagination/pagination";
import SearchBox from "./components/search-box/search-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "@mui/material";
import { ShopStoreIcon } from "./components/icons";
import ShopFilterItem from "./components/shop-filter-item/shop-filter-item";
import Breadcrumb from "./components/breadcrumb/breadcrumb";

export default function Shop() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters, getToken } =
    useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");
  const page = useRef(1);
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [loader, setLoader] = useState(0);
  const [leftSidebarToggle, setLeftSidebarToggle] = useState(false);
  const [rightSidebarToggle, setRightSidebarToggle] = useState(false);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getProducts = useCallback(async () => {
    try {
      setLoader(1);

      let filter = "";

      if (page.current > 0) {
        filter = filter + "p=" + page.current;
      }

      if (currentLanguageRef.current) {
        if (!filter) {
          filter = filter + "lng=" + currentLanguageRef.current;
        } else {
          filter = filter + "&lng=" + currentLanguageRef.current;
        }
      }

      if (filters.filter > 0) {
        if (!filter) {
          filter = filter + "filter=" + filters.filter;
        } else {
          filter = filter + "&filter=" + filters.filter;
        }
      }

      if (filters.tasteFilter.length > 0) {
        if (!filter) {
          filter = filter + "taste=" + filters.tasteFilter.toString();
        } else {
          filter = filter + "&taste=" + filters.tasteFilter.toString();
        }
      }

      if (filters.topFilter > 0) {
        if (!filter) {
          filter = filter + "tf=" + filters.topFilter;
        } else {
          filter = filter + "&tf=" + filters.topFilter;
        }
      }

      if (filters.searchTerm) {
        if (!filter) {
          filter = filter + "term=" + filters.searchTerm;
        } else {
          filter = filter + "&term=" + filters.searchTerm;
        }
      }

      if (filters.brand.length > 0) {
        if (!filter) {
          filter = filter + "brand=" + filters.brand.toString();
        } else {
          filter = filter + "&brand=" + filters.brand.toString();
        }
      }

      if (filters.yourPet) {
        if (!filter) {
          filter = filter + "pet=1";
        } else {
          filter = filter + "&pet=1";
        }
      }

      if (filters.directFast) {
        if (!filter) {
          filter = filter + "fast=1";
        } else {
          filter = filter + "&fast=1";
        }
      }

      if (filters.maxPrice > 0) {
        if (!filter) {
          filter = filter + "price=" + filters.maxPrice;
        } else {
          filter = filter + "&price=" + filters.maxPrice;
        }
      }

      if (filters.productType.length > 0) {
        if (!filter) {
          filter = filter + "type=" + filters.productType.toString();
        } else {
          filter = filter + "&type=" + filters.productType.toString();
        }
      }

      if (filters.categoryId > 0) {
        filter = filter + "&cid=" + filters.categoryId;
      } else {
        filter = filter + "&cid=" + filters.categoryId;
      }

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setProductsCount(data.count);
          setProducts(data.products);
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
  }, [PUBLIC_URL, page, filters]);

  useEffect(() => {
    setProducts([]);
    getProducts();
  }, [page, currentLanguage, getProducts]);

  useEffect(() => {
    setProducts([]);
    getProducts();
  }, [filters, setFilters]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <div className="mt-[51px] text-center text-[25px] md:text-[35px] 2xl:text-[54px] leading-normal font-[700]">
          {t("shawn_online_shop")}
        </div>
        <div className="mt-[28px] flex mx-auto gap-[0] lg:gap-[15px]">
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
          <div className="w-[100%] mx-[15px] md:mx-0 lg:w-[66%] 2xl:w-[76.0418%] flex flex-wrap items-center text-[#4D4D4D]">
            <Breadcrumb text={t("all_goods")} count={products ? productsCount : 0} />
            
            <div className="bg-[#4D4D4D] h-[1px] w-full m-[6px_0_8px_0]"></div>
          </div>
          <div className="w-0 lg:w-[16%] 2xl:w-[11.9791%]"></div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap gap-[0] lg:gap-[15px]">
          {window.innerWidth < 992 ? (
            <>
              <div className="flex p-[15px] w-full">
                <div className="w-[50%]">
                  <span
                    onClick={() => {
                      setLeftSidebarToggle(true);
                    }}
                    className="cursor-pointer p-[15px] border-solid border-[1px] border-[#9c9c9c] bg-white rounded-[5px] w-[35px] h-[35px] flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </span>
                </div>
                <div className="w-[50%] flex justify-end">
                  <span
                    onClick={() => {
                      setRightSidebarToggle(true);
                    }}
                    className="cursor-pointer p-[15px] border-solid border-[1px] border-[#9c9c9c] bg-white rounded-[5px] w-[35px] h-[35px] flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </span>
                </div>
              </div>
              <div className={`block`}>
                <Drawer
                  anchor="left"
                  open={leftSidebarToggle}
                  onClose={() => setLeftSidebarToggle(false)}
                  className={`sh-blur fixed top-0 ltr:left-0 rtl:right-0 w-full h-full bg-[#0006] lg:bg-transparent`}
                  PaperProps={{
                    sx: {
                      width: {
                        xs: "100%",
                        sm: "40%",
                        md: "40%",
                      },
                    },
                  }}
                >
                  <div className={`w-full bg-white h-full`}>
                    <div className="flex justify-end p-[15px] lg:hidden">
                      <img
                        src={CloseIcon}
                        alt=""
                        className="p-[5px] bg-[#00000033] rounded-full cursor-pointer"
                        onClick={() => {
                          setLeftSidebarToggle(false);
                        }}
                      />
                    </div>
                    <LeftSidebar />
                  </div>
                </Drawer>
              </div>
            </>
          ) : (
            <div className="w-[16%] 2xl:w-[11.9791%] h-full">
              <LeftSidebar />
            </div>
          )}

          <div className="w-full lg:w-[66%] 2xl:w-[76.0418%] text-[#4D4D4D]">
            {window.innerWidth > 767 && (
              <div className="flex w-full items-center p-[7px] bg-white rounded-[20px] shadow-[0_4px_20px_0_rgba(0,0,0,0.10)]">
                <div className="flex items-center w-[calc(100%-278px)] gap-[8px]">
                  <ShopFilterItem filter_id={1} name={t("the_newest")} />

                  <ShopFilterItem filter_id={2} name={t("bestselling")} />

                  <ShopFilterItem filter_id={3} name={t("cheapest")} />

                  <ShopFilterItem
                    filter_id={4}
                    name={t("the_most_expensive")}
                  />
                </div>
                <div className="w-[278px]">
                  <SearchBox
                    value={filters.searchTerm}
                    changeHandler={(value) => {
                      setFilters({ ...filters, searchTerm: value });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="product-list mt-[10px] flex flex-wrap gap-[10px] w-full px-[15px] md:px-0">
              {loader === 0 ? (
                products ? (
                  products.map((item, index) => {
                    return (
                      <ProductCard
                        product={item}
                        key={index}
                        classes="w-full md:w-[calc(33.333333%-7px)] lg:w-[calc(25%-8px)]"
                      />
                    );
                  })
                ) : (
                  <div className="w-full p-[20px] bg-[#fff] rounded-[15px]">
                    {t("there_is_no_product")}
                  </div>
                )
              ) : (
                <div className="flex flex-wrap gap-[10px]">
                  <ProductSkeleton count={12} />
                </div>
              )}
            </div>
            <div className="w-full h-[1px] bg-[rgba(30,30,30,0.50)] mt-[36px] mb-[13px]"></div>
            {productsCount > 12 && (
              <div className="w-full mb-[49px]">
                <ShopPagination
                  pagesCount={
                    productsCount % 12 === 0
                      ? productsCount / 12
                      : Math.floor(productsCount / 12) + 1
                  }
                  page={page.current}
                  handleChange={(event, value) => {
                    page.current = value;
                    getProducts();
                  }}
                />
              </div>
            )}
          </div>

          {window.innerWidth < 992 ? (
            <div className={`block`}>
              <Drawer
                anchor="right"
                open={rightSidebarToggle}
                onClose={() => setRightSidebarToggle(false)}
                className={`sh-blur fixed top-0 ltr:left-0 rtl:right-0 w-full h-full bg-[#0006] lg:bg-transparent`}
                PaperProps={{
                  sx: {
                    width: {
                      xs: "100%",
                      sm: "40%",
                      md: "40%",
                    },
                  },
                }}
              >
                <div className={`w-full bg-white h-full`}>
                  <div className="flex justify-end p-[15px] lg:hidden">
                    <img
                      src={CloseIcon}
                      alt=""
                      className="p-[5px] bg-[#00000033] rounded-full cursor-pointer"
                      onClick={() => {
                        setRightSidebarToggle(false);
                      }}
                    />
                  </div>
                  <RightSidebar />
                </div>
              </Drawer>
            </div>
          ) : (
            <div className={`block w-[16%] 2xl:w-[11.9791%] h-full`}>
              <RightSidebar />
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}
