import Header from './layout/header'
import Footer from './layout/footer'
import { Link, useSearchParams } from 'react-router-dom'
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import AppContext from './context/context'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NumericFormat } from 'react-number-format'
import ProductImagePlaceholder from '../assets/img/product-placeholder.png'
import { t } from 'i18next'
import { Card } from '@mui/material'

export default function BankConfirm() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { getToken, PUBLIC_URL,currentCurrency } = useContext(AppContext)
  const [order, setOrder] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [discount, setDiscount] = useState({ type: 0, amount: 0 })
  const [taxes, setTaxes] = useState()

  const getOrder = useCallback(async () => {
    try {
      const token = searchParams.get('token')

      await fetch(`${PUBLIC_URL}/api/order/ordertoken?otoken=${token}`, {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          if (data.status != 0) {
            setOrder(data)

            setDiscount(JSON.parse(data.discount))
            setTaxes(JSON.parse(data.tax))
            let _totalPrice = data.details.reduce(
              (total, product) =>
                (total = total + product.count * product.unit_price),
              0,
            )

            setTotalPrice(_totalPrice)
          }
        })
    } catch (error) {}
  }, [])

  useEffect(() => {
    getOrder()
  }, [getOrder, searchParams, setSearchParams])

  return (
    <>
      <Header />
      <div className="mt-[60px] mb-[60px] max-w-[95%] m-auto">
        <div className="w-full">
          <strong>{t('factor')}:</strong>{' '}
          {searchParams.get('message')}
        </div>
        {searchParams.get('status') === '1' && (
          <Card className="shadow-[0_0_10px_-3px_var(--text-color)] p-[30px] rounded-[15px] mt-[15px]">
            {order && (
              <>
                <div className="mb-[15px] flex flex-wrap md:flex-nowrap">
                  <div className="w-full">
                    <strong>{t('order_number')}:</strong> {order.order_id}
                  </div>
                  <div className="w-full">
                    <strong>{t('order_date')}:</strong>{' '}
                    {new Date(order.date).toLocaleString("en-EN", {
                      date: 'short',
                    })}
                  </div>
                  <div className="w-full">
                    <strong>{t('reference_number')}:</strong>{' '}
                    {searchParams.get('tranRef')}
                  </div>
                </div>
              </>
            )}
            {order?.details && (
              <>
                {order?.details?.map((detail, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-wrap md:flex-nowrap items-center border-solid border-b-[1px] border-[var(--border-color)] p-[10px]"
                    >
                      <div className="w-[20%] md:w-[10%] p-[15px_5px]">
                        {detail.thumbnail ? (
                          <LazyLoadImage src={detail.thumbnail} />
                        ) : detail.images ? (
                          <LazyLoadImage src={detail.images.split(',')[0]} />
                        ) : (
                          <LazyLoadImage src={ProductImagePlaceholder} />
                        )}
                      </div>
                      <div className="w-[80%] md:w-[45%] p-[15px_5px]">
                        <div>
                          <strong>{detail.product_name}</strong>
                        </div>
                        <div>{detail.attribute}</div>
                      </div>
                      <div className="w-[33.333333%] md:w-[15%] p-[15px_5px]">
                        <strong>{t('count')}: </strong>
                        {detail.count}
                      </div>
                      <div className="w-[33.333333%] md:w-[15%] p-[15px_5px]">
                        <strong>{t('unit_price')}: </strong>
                        {currentCurrency.place === 'before' &&
                          currentCurrency.text}{' '}
                        <NumericFormat
                          value={detail.unit_price}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        {' '}{currentCurrency.place === 'after' &&
                          currentCurrency.text}
                      </div>
                      <div className="w-[33.333333%] md:w-[15%] p-[15px_5px]">
                        <strong>{t('total')}: </strong>
                        {currentCurrency.place === 'before' &&
                          currentCurrency.text}{' '}
                        <NumericFormat
                          value={detail.unit_price * detail.count}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        {' '}{currentCurrency.place === 'after' &&
                          currentCurrency.text}
                      </div>
                    </div>
                  )
                })}

                {discount.amount > 0 && (
                  <div className="flex pt-[10px] pb-[10px]">
                    <div className="w-0 md:w-[70%]"></div>
                    <div className="w-[66.666666%] md:w-[15%]">
                      <strong>{t('discount')}</strong>
                    </div>
                    <div className="w-[33.333333%] md:w-[15%]">
                      <strong>
                        {discount.type === 1
                          ? '-' + discount.amount + '%'
                          : '-' +
                            (currentCurrency.place === 'before'
                              ? currentCurrency.text + ' '
                              : '') +
                            discount.amount +
                            (' '+ currentCurrency.place === 'after'
                              ? currentCurrency.text
                              : '')}
                      </strong>
                    </div>
                  </div>
                )}
                {taxes &&
                  taxes?.map((tax, index) => {
                    if (tax.type === 0) {
                      return <React.Fragment key={index}></React.Fragment>
                    }
                    return (
                      <div key={index} className="flex pt-[10px] pb-[10px]">
                        <div className="w-0 md:w-[70%]"></div>
                        <div className="w-[66.666666%] md:w-[15%]">
                          <strong>
                            {t('tax')} {tax.name}
                          </strong>
                        </div>
                        <div className="w-[33.333333%] md:w-[15%]">
                          <strong>
                            {tax.type === 1
                              ? '+' + tax.amount + '%'
                              : '+' +
                                (currentCurrency.place === 'before' &&
                                  currentCurrency.text) + ' ' +
                                tax.amount +
                                (' ' + currentCurrency.place === 'after'
                                  ? currentCurrency.text
                                  : '')}
                          </strong>
                        </div>
                      </div>
                    )
                  })}
                <div className="flex pt-[10px] pb-[10px]">
                  <div className="w-0 md:w-[70%]"></div>
                  <div className="w-[66.666666%] md:w-[15%]">
                    <strong>{t('total')}: </strong>
                  </div>
                  <div className="w-[33.333333%] md:w-[15%]">
                    <strong>
                      {currentCurrency.place === 'before' &&
                        currentCurrency.text}{' '}
                      <NumericFormat
                        value={
                          totalPrice -
                          (discount.type === 1
                            ? (totalPrice * discount.amount) / 100
                            : discount.amount) +
                          (taxes
                            ? taxes?.reduce(
                                (total, tax) =>
                                  tax.type === 1
                                    ? (total =
                                        total +
                                        ((totalPrice -
                                          (discount.type === 1
                                            ? (totalPrice * discount.amount) /
                                              100
                                            : discount.amount)) *
                                          tax.amount) /
                                          100)
                                    : tax.amount,
                                0,
                              )
                            : 0)
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={0}
                      />
                      {' '}{currentCurrency.place === 'after' &&
                        currentCurrency.text}
                    </strong>
                  </div>
                </div>
              </>
            )}
            {order && (
              <div className="mt-[15px]">
                <div className="flex">
                  <div className="w-full">
                    <strong>{t('customer_name')}: </strong>
                    {order.customer_name}
                  </div>
                  {order.company_name && (
                    <div className="w-full">
                      <strong>{t('company_name')}: </strong>
                      {order.company_name}
                    </div>
                  )}
                </div>
                <div className="w-full mt-[15px]">
                  <strong>{t('address')}:</strong> {order.address}
                </div>
                <div className="flex flex-wrap md:flex-nowrap mt-[15px]">
                  <div className="w-full">
                    <strong>{t('postal_code')}:</strong> {order.postal_code}
                  </div>
                  <div className="w-full">
                    <strong>{t('phone')}:</strong>{' '}
                    {order.tel.replace('0097', '0')}
                  </div>
                  <div className="w-full">
                    <strong className='capitalize'>{t('mobile')}:</strong>{' '}
                    {order.mobile.replace('0097', '0')}
                  </div>
                </div>
              </div>
            )}
          </Card>
        )}

        <div className="mt-[30px]">
          <Link to="/shop" className="button inline-block">
            {t('back_to_store')}
          </Link>
        </div>
      </div>
      <Footer />
    </>
  )
}
