import PencilIcon from "../../../assets/img/pencil_white_icon.svg";
import TrashIcon from "../../../assets/img/trash_white_icon.svg";
import PinIcon from "../../../assets/img/pin_white_icon.svg";
import AppContext from "../../context/context";
import { useContext, useState } from "react";
import Input from "../input/input";
import Textarea from "../textarea/textarea";
import { useTranslation } from "react-i18next";

export default function PetItemCard(props) {
  const { PUBLIC_URL, getToken } = useContext(AppContext);
  const item = props.item ? props.item : null;
  const [name, setName] = useState("");
  // const [type, setType] = useState(1);
  // const [breed, setBreed] = useState(0);
  // const [birthdate, setBirthdate] = useState("");
  const [interests, setInterests] = useState("");
  // const [gender, setGender] = useState(0);
  const { t } = useTranslation();

  async function deletePet() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/deletepet`, {
        method: "POST",
        body: JSON.stringify({
          id: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.pets) {
            props.setPets(data.pets);
            if (data.pets.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  async function editPet() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/editpet`, {
        method: "POST",
        body: JSON.stringify({
          id: item.id,
          name: name,
          interests: interests,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.pets) {
            setName("");
            setInterests("");
            props.setPets(data.pets);
            if (data.pets.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  async function pinPet() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/pinpet`, {
        method: "POST",
        body: JSON.stringify({
          id: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.pets) {
            props.setPets(data.pets);
            if (data.pets.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  return (
    <div className="flex rounded-[15px] shadow mt-[10px] overflow-hidden ltr:text-left rtl:text-right text-[11px] md:text-[14px]">
      <div className="w-[22.5%] bg-[#603085] text-[#fff] font-[600]">
        <div>
          {name ? (
            <Input
              type="text"
              value={name}
              onchange={(value) => {
                setName(value);
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          ) : (
            item.name
          )}
        </div>
        <div>{t('profile')}</div>
      </div>
      <div className="w-[67.5%] bg-[#60308533]">
        <div className="flex border-solid border-b-[1px] border-[#603085]">
          <div className="w-[33.333333%] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#603085] p-[10px]">
            {t('species')}: {item.type_name}
          </div>
          <div className="w-[33.333333%] p-[10px]">
            {t('gender')}: {item.gender === 0 ? t('male') : t('female')}
          </div>
          <div className="w-[33.333333%] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#603085] p-[10px]">
            {t('type')}: {item.breed_name}
          </div>
        </div>
        <div className="flex">
          <div className="w-[33.333333%] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#603085] p-[10px]">
            {t('age')}: {(new Date()).getFullYear() - (new Date(item.birthdate)).getFullYear()}
          </div>
          <div className="w-[66.666666%] p-[10px] text-[10px]">
            <div>{t('description')}:</div>
            {interests ? (
              <div>
                <Textarea
                  value={interests}
                  onchange={(value) => {
                    setInterests(value);
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
            ) : (
              <div>{item.interests}</div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[10%] bg-[#603085] p-[5px] text-center">
        <span
          className="p-[10px] rounded-full bg-[#FFFFFF33] cursor-pointer inline-block"
          onClick={() => {
            if (name) {
              editPet();
            } else {
              setName(item.name);
              setInterests(item.interests)
            }
          }}
        >
          <img src={PencilIcon} alt="" className="w-[15px]" />
        </span>
        <span
          className="p-[10px] rounded-full bg-[#FFFFFF33] cursor-pointer inline-block"
          onClick={deletePet}
        >
          <img src={TrashIcon} alt="" className="w-[15px]" />
        </span>
        <span
          className={`p-[10px] rounded-full ${
            item.main_pet ? "bg-[#FFD954]" : "bg-[#FFFFFF33]"
          } cursor-pointer inline-block`}
          onClick={pinPet}
        >
          <img src={PinIcon} alt="" className="w-[15px]" />
        </span>
      </div>
    </div>
    // <div className=" bg-[#60308533] flex items-center  ">
    //   <div className="w-[90%] flex">
    //     <div className="w-[25%] p-[15px] bg-[#603085] h-full ">
    //
    //     </div>
    //     <div className="w-[75%]">
    //
    //     </div>
    //   </div>
    //   <div className="w-[10%] bg-[#603085] ">
    //
    //   </div>
    // </div>
  );
}
