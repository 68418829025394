import { useTranslation } from "react-i18next";

export default function PurchaseItemCard(props) {
  const item = props.item ? props.item : null;
  const details = item.details ? item.details : null;
  const { t } = useTranslation();

  return (
    <div
      className={`flex rounded-[15px] shadow mt-[10px] overflow-hidden ltr:text-left rtl:text-right text-[11px] md:text-[14px]  border-solid border-[1px] ${
        item.type === 1
          ? "border-[#0BC154]"
          : item.type === 2
          ? "border-[#1DC8C8]"
          : "border-[#FF547D]"
      }`}
    >
      <div
        className={`w-[25%] ${
          item.type === 1
            ? "bg-[#0BC154]"
            : item.type === 2
            ? "bg-[#1DC8C8]"
            : "bg-[#FF547D]"
        } text-[#fff]`}
      >
        <div className="bg-[#1E1E1E33] p-[5px] font-[600]">
        <>{item.type === 1
            ? t('online_shopping')
            : item.type === 2
            ? t('open_invoice')
            : t('buy_person')}</>
        </div>
        <div className="p-[5px]">
          {t("order_number")}: {item.order_id}
        </div>
        <div className="p-[5px]">
          <div>{t("date")}: </div>
          <div>
            {new Date(item.date).toLocaleString("en-EN", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </div>
        </div>
      </div>
      <div className="w-[75%] bg-[#60308533]">
        <table className="w-full">
          <thead>
            <tr
              className={`${
                item.type === 1
                  ? "text-[#087132]"
                  : item.type === 2
                  ? "text-[#189797]"
                  : "text-[#B12B4B]"
              }`}
            >
              <th
                className={`p-[5px] font-[400] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[0.5px] ${
                  item.type === 1
                    ? "border-[#0BC154]"
                    : item.type === 2
                    ? "border-[#1DC8C8]"
                    : "border-[#FF547D]"
                }`}
              >
                {t("commodity")}
              </th>
              <th
                className={`p-[5px] font-[400] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[0.5px] ${
                  item.type === 1
                    ? "border-[#0BC154]"
                    : item.type === 2
                    ? "border-[#1DC8C8]"
                    : "border-[#FF547D]"
                }`}
              >
                {t("count")}
              </th>
              <th
                className={`p-[5px] font-[400] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[0.5px] ${
                  item.type === 1
                    ? "border-[#0BC154]"
                    : item.type === 2
                    ? "border-[#1DC8C8]"
                    : "border-[#FF547D]"
                }`}
              >
                {t("price")}
              </th>
              <th
                className={`p-[5px] font-[400] border-solid border-b-[0.5px] ${
                  item.type === 1
                    ? "border-[#0BC154]"
                    : item.type === 2
                    ? "border-[#1DC8C8]"
                    : "border-[#FF547D]"
                }`}
              >
                {t("total_purchase")}
              </th>
            </tr>
          </thead>
          <tbody>
            {details &&
              details.map((detail, index) => {
                return (
                  <tr key={index} className="text-[#808080]">
                    <td
                      className={`p-[5px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-t-[0.5px] ${
                        item.type === 1
                          ? "border-[#0BC154]"
                          : item.type === 2
                          ? "border-[#1DC8C8]"
                          : "border-[#FF547D]"
                      }`}
                    >
                      <div>{detail.product_name}</div>
                      {detail.attributes && (
                        <div className="text-[11px]">
                          {detail.attributes.map((att, index) => {
                            return (
                              <span className="ltr:mr-[10px] rtl:ml-[10px]" key={index}>
                                <span className="ltr:mr-[3px] rtl:ml-[3px]">
                                  {att.type_name}:
                                </span>
                                {att.type === 1 && (
                                  <span
                                    className={`w-[10px] h-[10px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                    style={{
                                      background: att.value,
                                    }}
                                  ></span>
                                )}
                                {att.name}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td
                      className={`p-[5px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-t-[0.5px] ${
                        item.type === 1
                          ? "border-[#0BC154]"
                          : item.type === 2
                          ? "border-[#1DC8C8]"
                          : "border-[#FF547D]"
                      }`}
                    >
                      {detail.count}
                    </td>
                    <td
                      className={`p-[5px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-t-[0.5px] ${
                        item.type === 1
                          ? "border-[#0BC154]"
                          : item.type === 2
                          ? "border-[#1DC8C8]"
                          : "border-[#FF547D]"
                      }`}
                    >
                      {detail.unit_price}
                    </td>
                    <td
                      className={`p-[5px] border-solid border-t-[0.5px] ${
                        item.type === 1
                          ? "border-[#0BC154]"
                          : item.type === 2
                          ? "border-[#1DC8C8]"
                          : "border-[#FF547D]"
                      }`}
                    >
                      {detail.unit_price * detail.count}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
