export default function CommentItemCard(props) {
  const comment = props.item ? props.item : null;

  return (
    <div className="rounded-[15px] shadow p-[5px] mb-[10px] border-solid border-[1px] border-[#00000080]">
      <div className="flex items-center">
        <div className="w-[75%] flex items-center">
          <div className="text-[#603085] inline-block p-[3px_15px] ltr:mr-[5px] rtl:ml-[5px]">
            {comment.customer_name ? comment.customer_name : comment.user_name}
          </div>
        </div>
        <div className="w-[25%] flex justify-end">
          {comment.date && (
            <span className="p-[3px_15px]">
              {new Date(comment.date).toLocaleString("en-EN", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </span>
          )}
        </div>
      </div>
      <div className="p-[3px_15px] mt-[5px]">
        {comment.comment}
      </div>
    </div>
  );
}
