import Header from "./layout/header";
import Footer from "./layout/footer";
import { useCallback, useContext, useEffect } from "react";
import AppContext from "./context/context";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function BankPayment() {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  let { id } = useParams();
  const [paymentToken, setPaymentToken] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const paymentRequest = useCallback(async () => {
    console.log('sdafds')
    try {
      await fetch(`${PUBLIC_URL}/api/getbanktoken/token?oid=${id}`, {
        // await fetch(`https://shawnpet.com/api/getbanktoken/token?oid=${id}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          console.log(data);
          if (data.redirect_url) {
            setPaymentToken(data.redirect_url);
            window.location.replace(data.redirect_url);
          } else {
            setError(data.message);
          }
        });
    } catch (error) {}
  }, []);

  useEffect(() => {
    paymentRequest();
  }, [paymentRequest]);

  return (
    <>
      <Header />
      <div className="mt-[60px] mb-[60px] max-w-[95%] m-auto">
        {paymentToken && (
          <>
            <div className="text-center w-full">
              {t("message_transfer_to_bank")}
            </div>
            <div className="w-full text-center">
              <a
                className="bg-black text-white inline-block mt-[15px] p-[1rem]"
                href={paymentToken}
              >
                {t("transfer_to_bank")}
              </a>
            </div>
          </>
        )}
        {error && (
          <>
            <div className="text-center w-full">
              {t("message_bank_duplicate_request")}
            </div>
            <div className="w-full text-center">
              <span
                className="bg-black text-white cursor-pointer inline-block mt-[15px] p-[1rem]"
                onClick={()=>{
                  paymentRequest();
                }}
              >
                {t("try_again")}
              </span>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
