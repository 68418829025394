import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import ConfirmCard from "../confirm-card/confirm-card";
import { useTranslation } from "react-i18next";

export default function RegisterCard(props) {
  const { PUBLIC_URL } = useContext(AppContext);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(0);
  const [activation, setActivation] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    default: "",
    name: "",
    lastName: "",
    username: "",
    password: "",
    mobile: "",
    email: "",
    code: "",
  });
  const { t } = useTranslation();

  async function registerRequest() {
    try {
      if (!name) {
        setError({
          ...error,
          name: t('message_enter_name'),
        });
        return;
      }
      if (!lastName) {
        setError({
          ...error,
          lastName: t('message_enter_last_name'),
        });
        return;
      }
      if (!email) {
        setError({ ...error, email: t('message_enter_email') });
        return;
      }
      if (!username) {
        setError({ ...error, username: t('message_enter_username') });
        return;
      }
      if (!password) {
        setError({ ...error, password: t('message_enter_password') });
        return;
      }

      setLoader(1);

      await fetch(`${PUBLIC_URL}/api/customer/register`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          lastName: lastName,
          birthdate: birthdate,
          username: username,
          password: password,
          mobile: mobile,
          email: email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          console.log(data)
          if (data.status === 1) {
            enqueueSnackbar(t('message_account_created'), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            setActivation(1);
          } else if (data.status === 2) {
            setError({
              ...error,
              default: t('message_enter_required_fields'),
            });
          } else {
            setError({ ...error, default: "Error" });
          }
        });
    } catch (err) {
      enqueueSnackbar(t('message_proccess_error'), {
        variant: "error",
        vertical: "bottom",
        horizontal: "left",
      });
      setLoader(0);
    }
  }

  const registerHandler = () => {
    registerRequest();
  };

  return (
    <div className="">
      {activation === 0 ? (
        <>
          <form>
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div
                  className={`w-[200px] bg-[#00000033] ${
                    error.name ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('name')} *
                </div>
                <Input
                  type="text"
                  value={name}
                  onchange={(value) => {
                    setName(value);
                    setError({ ...error, name: "" });
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.name && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.name}
                </div>
              )}
            </div>
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div
                  className={`w-[200px] bg-[#00000033] ${
                    error.lastName ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('last_name')} *
                </div>
                <Input
                  type="text"
                  value={lastName}
                  onchange={(value) => {
                    setLastName(value);
                    setError({ ...error, lastName: "" });
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.lastName && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.lastName}
                </div>
              )}
            </div>
            <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
              <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
                {t('birth')}
              </div>
              <Input
                type="date"
                value={birthdate}
                onchange={(value) => {
                  setBirthdate(value);
                }}
                classes="w-full p-[15px] bg-[#0000000D]"
              />
            </div>
            <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
              <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
                {t('mobile')}
              </div>
              <Input
                type="text"
                value={mobile}
                onchange={(value) => {
                  setMobile(value);
                }}
                classes="w-full p-[15px] bg-[#0000000D]"
              />
            </div>
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div
                  className={`w-[200px] bg-[#00000033] ${
                    error.email ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('email')} *
                </div>
                <Input
                  type="text"
                  value={email}
                  onchange={(value) => {
                    setEmail(value);
                    setError({ ...error, email: "" });
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.email && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.email}
                </div>
              )}
            </div>
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden">
                <div
                  className={`w-[200px] bg-[#00000033] ${
                    error.username ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('username')} *
                </div>
                <Input
                  type="text"
                  value={username}
                  onchange={(value) => {
                    setUsername(value);
                    setError({ ...error, username: "" });
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.username && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.username}
                </div>
              )}
            </div>
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                <div
                  className={`w-[200px] bg-[#00000033] ${
                    error.password ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('password')} *
                </div>
                <Input
                  type="password"
                  value={password}
                  onchange={(value) => {
                    setPassword(value);
                    setError({ ...error, password: "" });
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.password && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.password}
                </div>
              )}
            </div>
            <FormButton
              onclick={() => {
                registerHandler();
              }}
              classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              {t('register')}
            </FormButton>
          </form>
          {error.default && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.default}
            </div>
          )}
        </>
      ) : (
        <>
          <ConfirmCard email={email} settoggle={props.settoggle} />
          {/* {error && <div className="mt-[15] text-[#FF547D]">{error}</div>} */}
        </>
      )}
    </div>
  );
}
