import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

const AppContext = React.createContext({
  login: () => {},
  cart: {},
  setCart: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  getToken: () => {},
  loadWebsite: () => {},
  currentLanguage: {},
  setCurrentLanguage: () => {},
});

export const AppContextProvider = (props) => {
  // const PUBLIC_URL = "http://localhost:8081/shawn";
  const PUBLIC_URL = ''
  // const WEBSITE_URL = "http://localhost:8081/shawn";
  const WEBSITE_URL = "https://shawnpet.com";
  const DEFAULT_LANGUAGE = "en";
  const LANGUAGELIST = [
    { name: "English", code: "en", flag: `${WEBSITE_URL}/media/usa_flag.png` },
    { name: "Arabic", code: "ar", flag: `${WEBSITE_URL}/media/uae_flag.png` },
  ];
  const { t } = useTranslation();
  const CURRENCY = [
    {
      id: 1,
      text: t("dirham"),
      place: "before",
    },
  ];
  const [currentCurrency, setCurrentCurrency] = useState(CURRENCY[0]);
  
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  useEffect(()=>{
    i18n.changeLanguage(currentLanguage)
  },[currentLanguage])
  const [menus, setMenus] = useState([]);
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({
    category: {
      id: 0,
      name: "",
    },
  });
  const currentLanguageRef = useRef("");
  const [filters, setFilters] = useState({
    filter: 1,
    searchTerm: "",
    postSearchTerm: "",
    brand: [],
    price: 0,
    productType: [],
    tasteFilter: [],
    categoryId: 0,
    postCategoryId: 0,
    yourPet: 0,
    postYourPet: 0,
    directFast: 0,
    maxPrice: 0,
    topFilter: 0,
  });

  const removeFilters = () => {
    setFilters({
      filter: 1,
      searchTerm: "",
      postSearchTerm: "",
      brand: [],
      price: 0,
      productType: [],
      tasteFilter: [],
      categoryId: 0,
      postCategoryId: 0,
      yourPet: false,
      postYourPet: false,
      directFast: false,
      maxPrice: 0,
      topFilter: 0,
    });
  };

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getToken = () => {
    const token = localStorage.getItem("token");
    return token ? token : "";
  };

  const logout = () => {
    localStorage.removeItem("token");
  };

  const getCustomer = () => {
    const customer = localStorage.getItem("token");
    if (customer) {
      return jwt_decode(customer);
    }
    return 0;
  };

  const clearCart = () => {
    localStorage.removeItem("cart");
    setCart([]);
  };

  const loadWebsite = useCallback(async () => {
    // try {
    //   await fetch(
    //     `${PUBLIC_URL}/api/get/load?lng=${currentLanguageRef.current}`
    //   )
    //     .then((respose) => {
    //       if (respose.ok) {
    //         return respose.json();
    //       }
    //       throw new Error("error");
    //     })
    //     .then((data) => {
    //       if (data.status === 0) {
    //       } else {
    //         if (data.status.menus) {
    //           setMenus(data.status.menus);
    //         }
    //       }
    //     });
    // } catch (error) {}

    // if (getToken()) {
    //   try {
    //     await fetch(`${PUBLIC_URL}/api/customer/login`, {
    //       headers: {
    //         Authorization: "Bearer " + getToken(),
    //       },
    //     })
    //       .then((respose) => {
    //         if (respose.ok) {
    //           return respose.json();
    //         }
    //         throw new Error("error");
    //       })
    //       .then((data) => {
    //         if (data.token) {
    //           localStorage.setItem("token", data.token);
    //         }
    //       });
    //   } catch (error) {}
    // }
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/product/wishlist`, {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            if (data.status === 1) {
              setWishlist(data.wishlist);
            }
            // setProducts(data)
            // setProductsLoader(2)
          });
      } else {
        const localWishlist = localStorage.getItem("wish");
        if (localWishlist) {
          await fetch(`${PUBLIC_URL}/api/product/wishlist`, {
            method: "POST",
            body: JSON.stringify({
              localwish: localWishlist,
            }),
          })
            .then((respose) => {
              if (respose.ok) {
                return respose.json();
              }
              throw new Error("error");
            })
            .then((data) => {
              setWishlist(data);
            });
        }
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    loadWebsite();
  }, [loadWebsite]);

  const login = async (username, password, setResult) => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/login`, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          if (data.token) {
            localStorage.setItem("token", data.token);
            setResult(1);
            let localcart = localStorage.getItem("cart");
            localcart = localcart ? JSON.parse(localcart) : [];
            let localwish = localStorage.getItem("wish");
            localwish = localwish ? JSON.parse(localwish) : [];
            let localcompare = localStorage.getItem("compare");
            localcompare = localcompare ? JSON.parse(localcompare) : [];

            const lists = {
              cart: [...localcart],
              wish: [...localwish],
              compare: [...localcompare],
            };

            if (
              localcart.length > 0 ||
              localwish.length > 0 ||
              localcompare.length > 0
            ) {
              await fetch(`${PUBLIC_URL}/api/customer/updatecart`, {
                method: "POST",
                body: JSON.stringify({
                  lists: lists,
                }),
                headers: {
                  Authorization: "Bearer " + data.token,
                },
              })
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json();
                  }
                  throw new Error("error");
                })
                .then((data) => {
                  if (data.status === 1) {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("wish");
                    localStorage.removeItem("compare");
                  }
                  // navigate('/')
                });
            } else {
              // navigate('/')
            }
          } else if (data.email) {
            setResult({ status: 2, email: data.email });
          } else {
            setResult(3);
          }
        });
    } catch (error) {
      // setError(t('khata_dar_amaliyat'))
      // loader(0)
      setResult(0);
    }
  };

  const addToCart = async (attribute_id, count) => {
    try {
      if (getToken()) {
        //if loggedin customer
        await fetch(`${PUBLIC_URL}/api/cart/addtocart`, {
          method: "POST",
          body: JSON.stringify({
            count: count,
            aid: attribute_id,
          }),
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            if (data.status === 0) {
              return false;
            } else {
              setCart(data);
            }
          });
      } else {
        let localCart = localStorage.getItem("cart");

        if (localCart) {
          //if localcart has product
          localCart = JSON.parse(localCart);

          const foundItem = localCart.find((item) => {
            return item.aid === attribute_id;
          });
          if (foundItem) {
            //if there is already the product in the cart
            if (count > 0) {
              //add the product in the localcart
              const newlocalCart = localCart.map((item) => {
                if (attribute_id === item.aid) {
                  return { ...item, count: count };
                }

                return item;
              });
              localStorage.setItem("cart", JSON.stringify(newlocalCart));
            } else {
              //if count <= 0 then remove the product from the localcart
              const newlocalCart = localCart.filter(
                (item) => attribute_id !== item.aid
              );
              localStorage.setItem("cart", JSON.stringify(newlocalCart));
            }
          } else {
            //if there isn't already the product in the cart then add the product in the localcart
            if (count > 0) {
              localStorage.setItem(
                "cart",
                JSON.stringify([
                  ...localCart,
                  { count: count, aid: attribute_id },
                ])
              );
            }
          }
        } else {
          //if localcart hasn't product
          if (count > 0) {
            localStorage.setItem(
              "cart",
              JSON.stringify([{ count: count, aid: attribute_id }])
            );
          }
        }
        //get product details of the cart from server
        await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
          method: "POST",
          body: JSON.stringify({
            localcart: localStorage.getItem("cart"),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setCart(data);
          });
      }
    } catch (error) {
      return false;
    }
  };

  const removeFromCart = async (id) => {
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/cart/remove?aid=${id}`, {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            // setCartRemoveLoader(0);
            if (data.status === 0) {
              // setError(t("hazf_ba_khata"));
            } else {
              setCart(data);
            }
          });
      } else {
        //get from local storage
        let localCart = localStorage.getItem("cart");
        localCart = JSON.parse(localCart);
        const newlocalCart = localCart.filter((item) => {
          return item.aid !== id;
        });
        localStorage.setItem("cart", JSON.stringify(newlocalCart));
        await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
          method: "POST",
          body: JSON.stringify({
            localcart: localStorage.getItem("cart"),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            // setCartRemoveLoader(0);
            setCart(data);
          });
      }
    } catch (error) {
      // setCartRemoveLoader(0);
      // setError(t("khata_dar_amaliyat"));
    }
  };

  return (
    <AppContext.Provider
      value={{
        login,
        logout,
        cart,
        setCart,
        addToCart,
        removeFromCart,
        getToken,
        getCustomer,
        loadWebsite,
        menus,
        setMenus,
        PUBLIC_URL,
        WEBSITE_URL,
        DEFAULT_LANGUAGE,
        LANGUAGELIST,
        CURRENCY,
        currentCurrency,
        setCurrentCurrency,
        currentLanguage,
        setCurrentLanguage,
        selectedMenu,
        setSelectedMenu,
        filters,
        setFilters,
        removeFilters,
        clearCart,
        wishlist,
        setWishlist,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
