import { useContext } from "react";
import AppContext from "../../context/context";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    width: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px !important',
    '& li.MuiButtonBase-root.MuiMenuItem-root': {
      paddingRight: '5px',
      paddingLeft: '5px',
    },
  },
  '& .MuiSvgIcon-root':{
    display: 'none'
  }
}));

export default function LanguageSelect(props) {
  const { LANGUAGELIST, currentLanguage, setCurrentLanguage } =
    useContext(AppContext);
  return (
    <>
      <CustomSelect
        value={currentLanguage}
        onChange={(event)=>{
          setCurrentLanguage(event.target.value);
        }}
      >
        {LANGUAGELIST &&
          LANGUAGELIST.map((language, index) => {
            return (
              <MenuItem value={language.code} key={index}><img src={language.flag} /></MenuItem>
            );
          })}
      </CustomSelect>
    </>
  );
}
