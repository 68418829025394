import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
  Scrollbar,
  Zoom,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/context";
import ProductCard from "../productcard/productcard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ProductSkeleton from "../skeleton/product/product-skeleton";

export default function ProductCarousel(props) {
  const [products, setProducts] = useState(
    props.products ? props.products : []
  );
  const { PUBLIC_URL, currentLanguage, getToken } = useContext(AppContext);
  const currentLanguageRef = useRef("");
  const [loader, setLoader] = useState(0);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getProducts = useCallback(async () => {
    setLoader(1);
    let filter = "lng=" + currentLanguageRef.current;
    if (props.metaId) {
      filter = filter + "&metaid=" + props.metaId;
    }

    if (props.categoryId) {
      filter = filter + "&cid=" + props.categoryId;
    }
    if (props.discount) {
      filter = filter + "&discount=" + props.discount;
    }
    if (props.stock) {
      filter = filter + "&stock=" + props.stock;
    }
    if (props.random) {
      filter = filter + "&random=" + props.random;
    }

    if (props.yourPet) {
      filter = filter + "&pet=1";
    }

    try {
      await fetch(`${PUBLIC_URL}/api/product/productlist?` + filter, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setProducts(data.products);
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
  }, [
    PUBLIC_URL,
    props.categoryId,
    props.discount,
    props.metaId,
    props.random,
    props.stock
  ]);

  useEffect(() => {
    getProducts();
  }, [currentLanguage, getProducts]);

  

  return loader === 0 ? (
    products && products.length > 0 && (
      <div className="relative">
        <Swiper
          navigation={{
            prevEl: navigationNextRef.current,
            nextEl: navigationPrevRef.current,
            clickable: true,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationNextRef.current;
            swiper.params.navigation.nextEl = navigationPrevRef.current;
          }}
          modules={[
            Autoplay,
              Keyboard,
              Pagination,
              Scrollbar,
              Zoom,
              EffectFade,
              Navigation,
          ]}
          grabCursor={true}
          loop={true}
          keyboard={true}
          spaceBetween={15}
          slidesPerView={4}
          initialSlide={4}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          zoom={true}
          effect={"flip"}
          breakpoints={{
            0: {
              spaceBetween: 15,
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              spaceBetween: 15,
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              spaceBetween: 15,
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            991: {
              spaceBetween: 15,
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            1200: {
              spaceBetween: 23,
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            1600: {
              spaceBetween: 30,
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
        >
          {products && products?.map((product, index) => {
            return (
              <SwiperSlide key={index}>
                <ProductCard product={product} hideDiscount={props.hideDiscount} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          ref={navigationPrevRef}
          className="absolute z-[10] top-0 ltr:right-[-50px] rtl:left-[-50px] h-full flex items-center justify-center cursor-pointer"
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-[40px] text-[#00000080]"
          />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute z-[10] top-0 ltr:left-[-50px] rtl:right-[-50px] h-full flex items-center justify-center cursor-pointer"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-[40px] text-[#00000080]"
          />
        </div>
      </div>
    )
  ) : (
    <div className="flex">
      <ProductSkeleton count={4} />
    </div>
  );
}
