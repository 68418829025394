import { Dialog } from "@mui/material";
import Input from "../input/input";
import { ClipLoader } from "react-spinners";
import FormButton from "../form-button/form-button";
import { useContext, useState } from "react";
import AppContext from "../../context/context";
import { useTranslation } from "react-i18next";

export default function NewsletterCard(props) {
  const { PUBLIC_URL } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(0);
  const [error, setError] = useState({
    email: "",
    default: "",
  });
  const { t } = useTranslation();

  async function registerRequest() {
    try {
      if (!email) {
        setError({ ...error, email: t('message_enter_email') });
        return;
      }
      setLoader(1);

      await fetch(`${PUBLIC_URL}/api/post/registernewsletter`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.status === 1) {
            props.setToggle(false);
          } else {
            setError({ ...error, default: t('message_proccess_error') });
          }
        });
    } catch (err) {
      setError({ ...error, default: t('message_proccess_error') });
      setLoader(0);
    }
  }

  return (
    <Dialog
      open={props.toggle}
      onClose={() => {
        props.setToggle(false);
      }}
      scroll="body"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="sh-blur"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "50%",
          },
        },
      }}
    >
      <form className="m-[30px]">
        <div className="mb-[15px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[14px] ltr:text-left rtl:text-right">
              {t('email')} *
            </div>
            <Input
              type="text"
              value={email}
              onchange={(value) => {
                setEmail(value);
                setError({ ...error, email: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.email && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.email}</div>
          )}
        </div>
        <FormButton
          onclick={() => {
            registerRequest();
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t('register')}
        </FormButton>
      </form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.default}</div>
      )}
    </Dialog>
  );
}
