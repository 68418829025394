import { Link } from "react-router-dom";

export default function ServiceCard(props) {
  return (
    <div className="flex bg-[#E9E9E9] rounded-[15px] overflow-hidden">
      <div className="w-[45%] 2xl:w-[53.38%]" style={{
        backgroundImage:`url(${props.image})`,
        backgroundPosition:'center center',
        backgroundSize: 'cover'
      }}>
      </div>

      <div className="p-[6px_12px_12px_14px] w-[55%] 2xl:w-[46.62%] flex flex-col">
        <div className="font-[600] text-[18px] md:text-[24px] 2xl:text-[32px] text-black leading-normal">
          {props.title}
        </div>
        <div className="mt-[9px] text-[14px] 2xl:text-[18px] text-[#4B4B4B]">{props.description}</div>
        <div className="flex justify-end mt-auto">
          <Link
            to={props.link}
            className="inline-block rounded-[100px] bg-[#000000] text-white text-[14px] 2xl:text-[18px] hover:bg-[#666666] p-[4px_12px]"
          >
            More >
          </Link>
        </div>
      </div>
    </div>
  );
}
