import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import Textarea from "../textarea/textarea";
import ShopPagination from "../pagination/pagination";
import CommentItemCard from "../comment-item-card/comment-item-card";

export default function CommentCard(props) {
  const comments = props.comments;
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState({
    default: "",
    comment: "",
  });

  const addComment = async () => {
    try {
      if (!comment) {
        setErrorForm({
          ...errorForm,
          comment: t("nazare_khod_ra_vared_konid"),
        });
        return;
      }
      setLoader(1);
      if (comment) {
        await fetch(`${PUBLIC_URL}/api/post/comment`, {
          method: "POST",
          body: JSON.stringify({
            pid: comments[0].post_id,
            comment: comment,
            parentId: 0,
          }),
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setLoader(0);
            if (data.status === 1) {
              // setParentId({ id: 0, name: '' })
              setComment("");
              // setMessage(t('nazare_shoma_ba_movafaghiyat_sabt_shod'))
            } else {
              // set
              // setErrorForm({
              //   ...errorForm,
              //   default: t("khata_dar_sabt_nazar"),
              // });
            }
          });
      }
    } catch (error) {
      setLoader(0);
      // setErrorForm({ ...errorForm, default: t("khata_dar_sabt_nazar") });
    }
  };

  return (
    <div className="">
      <form className="mb-[15px]">
        <div className="w-full border-solid border-[1px] border-[#00000080] rounded-[15px] shadow p-[5px]">
          <div className="flex items-center text-[#000] p-[15px] text-[14px] ltr:text-left rtl:text-right">
            {t('you')}:
          </div>

          <div className="flex rounded-[20px] overflow-hidden">
            <Textarea
              value={comment}
              onchange={(value) => {
                setComment(value);
                // setErrorForm({ ...errorForm, comment: "" });
              }}
              classes="w-full p-[15px]"
              placeholder={t('type_your_comment')}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <FormButton
            onclick={addComment}
            classes="p-[10px_15px] text-center upppercase bg-[#000] text-[#fff] mt-[10px] rounded-[20px]"
          >
            {loader === 1 && (
              <ClipLoader
                color="#ffffff"
                size="15px"
                className="rtl:ml-[10px] ltr:mr-[10px]"
              />
            )}
            <span>{t('Send')}</span>
          </FormButton>
        </div>
      </form>
      <div className="w-full mt-[15px]">
        <div className="w-full">
          {props.comments &&
            props.comments.map((comment, index) => {
              if ((page - 1) * 3 <= index && index < page * 3) {
                return <CommentItemCard item={comment} key={index} />;
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
        </div>
        {props.comments && props.comments.length > 3 && (
          <div className="w-full">
            <ShopPagination
              pagesCount={
                props.comments.length % 3 === 0
                  ? props.comments.length / 3
                  : Math.floor(props.comments.length / 3) + 1
              }
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
