import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "../../context/context";
import RightArrowIcon from "../../../assets/img/right_arrow_icon.svg";

export default function ProductTopFilter() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const currentLanguageRef = useRef("");
  const [filterItems, setFilterItems] = useState();

  const getFlters = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/get/topfilter?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.filters) {
            setFilterItems(data.filters);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
    getFlters();
  }, [getFlters, currentLanguage]);
  return (
    filterItems && (
      <>
        {filterItems.topfood && filterItems.topfood.length > 0 && (
          <div
            className={`flex items-center rounded-[20px] bg-[#0000001A] text-[#000] p-[10px] cursor-pointer border-solid border-[1px] ${
              filters.topFilter === filterItems.topfood[0].id
                ? "border-[#603085]"
                : "border-[#0000001A]"
            }`}
            onClick={() => {
              if (
                filters.topFilter !== filterItems.topfood[0].id
              ) {
                setFilters({
                  ...filters,
                  topFilter: filterItems.topfood[0].id,
                });
              } else {
                setFilters({
                  ...filters,
                  topFilter: 0,
                });
              }
            }}
          >
            <div className="w-[calc(100%-10px)]">
              {filterItems.topfood[0].name}
            </div>
            <div className="w-[10px] flex justify-end">
              <img src={RightArrowIcon} alt={filterItems.topfood[0].name} />
            </div>
          </div>
        )}
        {filterItems.toptreat && filterItems.toptreat.length > 0 && (
          <div
          className={`flex items-center rounded-[20px] bg-[#0000001A] text-[#000] p-[10px] mt-[5px] cursor-pointer border-solid border-[1px] ${
            filters.topFilter === filterItems.toptreat[0].id
              ? "border-[#603085]"
              : "border-[#0000001A]"
          }`}
            onClick={() => {
              if (
                filters.topFilter !== filterItems.toptreat[0].id
              ) {
                setFilters({
                  ...filters,
                  topFilter: filterItems.toptreat[0].id,
                });
              } else {
                setFilters({
                  ...filters,
                  topFilter: 0,
                });
              }
            }}
          >
            <div className="w-[calc(100%-10px)]">
              {filterItems.toptreat[0].name}
            </div>
            <div className="w-[10px] flex justify-end">
              <img src={RightArrowIcon} alt={filterItems.toptreat[0].name} />
            </div>
          </div>
        )}
        {filterItems.topweek && filterItems.topweek.length > 0 && (
          <div
          className={`flex items-center rounded-[20px] bg-[#0000001A] text-[#000] p-[10px] mt-[5px] cursor-pointer border-solid border-[1px] ${
            filters.topFilter === filterItems.topweek[0].id
              ? "border-[#603085]"
              : "border-[#0000001A]"
          }`}
            onClick={() => {
              if (
                filters.topFilter !== filterItems.topweek[0].id
              ) {
                setFilters({
                  ...filters,
                  topFilter: filterItems.topweek[0].id,
                });
              } else {
                setFilters({
                  ...filters,
                  topFilter: 0,
                });
              }
            }}
          >
            <div className="w-[calc(100%-10px)]">
              {filterItems.topweek[0].name}
            </div>
            <div className="w-[10px] flex justify-end">
              <img src={RightArrowIcon} alt={filterItems.topweek[0].name} />
            </div>
          </div>
        )}
      </>
    )
  );
}
