import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import ShopIcon from "../assets/img/shop_icon.svg";
import Footer from "./layout/footer";
import { Link, useParams } from "react-router-dom";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import BannerProcust from "./components/product-banner/product-banner";
import { styled } from "@mui/material/styles";
import ProductFAQCard from "./components/product-faq-card/product-faq-card";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CommentCard from "./components/comment-card/comment-card";
import PostCard from "./components/post-card/post-card";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    // maxWidth: 40,
    width: "100%",
    // backgroundColor: "#000",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flexDirection: "initial",
    gap: "5px",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    borderRadius: "20px",
    // minHeight: 'auto',
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      background: "#603085",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#333",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        overflowY: "auto",
        padding: "15px",
        background: "#fff",
        borderRadius: "20px",
        marginTop: "15px",
      }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Post() {
  const { WEBSITE_URL, PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");
  const [post, setPost] = useState();
  let { slug } = useParams();
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  let tabCount = 0;

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getPost = useCallback(async () => {
    try {
      let filter = "slug=" + slug;

      if (currentLanguageRef.current) {
        if (!filter) {
          filter = filter + "lng=" + currentLanguageRef.current;
        } else {
          filter = filter + "&lng=" + currentLanguageRef.current;
        }
      }

      await fetch(`${PUBLIC_URL}/api/post/postlist?${filter}`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          if (data && data.count > 0) {
            setPost(data.posts[0]);
            setRelatedPosts(data.posts[0].related_posts);
            setComments(
              data.posts[0].comments ? JSON.parse(data.posts[0].comments) : null
            );
            setFaqs(data.posts[0].faqs ? JSON.parse(data.posts[0].faqs) : null);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL, slug]);

  useEffect(() => {
    getPost();
  }, [currentLanguage, getPost]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        {post && post.meta_description && (
          <meta name="description" content={post.meta_description} />
        )}
        {post && (
          <script type="application/ld+json">
            {JSON.stringify(`{
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${WEBSITE_URL + "/post/" + post.slug}"
              },
              "headline": "${post.title}",
              "description": "${post.meta_description}",
              "image": "${post.thumbnail ? post.thumbnail : ""}",  
              "author": {
                "@type": "",
                "name": ""
              },  
              "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                  "@type": "ImageObject",
                  "url": ""
                }
              },
              "datePublished": ""
            }`)}
          </script>
        )}
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <BannerProcust />
        <div className="m-[25px_5%] inline-block">
          <div className="flex">
            <div className="w-full flex flex-wrap items-center text-[#4D4D4D]">
              <div className="w-full flex items-center">
                <div className="w-full flex">
                  <img
                    src={ShopIcon}
                    alt=""
                    className="ltr:mr-[10px] rtl:ml-[10px] w-[25px]"
                    style={{
                      color: "#4D4D4D",
                    }}
                  />{" "}
                  <Link to="/">{t('shawn')}</Link>
                  {" > "} <Link to="/shop">{t('shop')}</Link>
                  {" > "} {post && post.title}
                </div>
              </div>
            </div>
          </div>
          <div className="my-[15px]">
            <div className="w-full flex">
              {post && (
                <div className="bg-[#fff] shadow rounded-[20px] p-[15px]">
                  <div>
                    {post.thumbnail && (
                      <LazyLoadImage src={post.thumbnail} alt={post.title} />
                    )}
                  </div>
                  <div className="font-[700] text-[35px]">{post.title}</div>
                  {post.content && (
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: post.content,
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            {relatedPosts && relatedPosts.length > 0 && (
              <>
                <div className="relative m-[45px_0] flex items-center justify-center">
                  <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] absolute top-[55%] left-0 w-full"></div>
                  <span className="inline-flex items-center bg-[#000] text-[#fff] rounded-[20px] p-[8px_30px] mt-[10px] z-[2] uppercase">
                    {t('similar_posts')}
                  </span>
                </div>
                <div className="post-list flex flex-wrap gap-[25px]">
                  {relatedPosts.map((item, index) => {
                    return <PostCard post={item} key={index} />;
                  })}
                </div>
              </>
            )}
            <div className="relative m-[45px_0] flex items-center justify-center">
              <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] absolute top-[55%] left-0 w-full"></div>
              <span className="inline-flex items-center bg-[#000] text-[#fff] rounded-[20px] p-[8px_30px] mt-[10px] z-[2] uppercase">
                {t('about_post')}
              </span>
            </div>
            <div>
              <div className="">
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      background: "#fff",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    <StyledTabs
                      value={activeTab}
                      onChange={(event, newValue) => {
                        setActiveTab(newValue);
                      }}
                      // variant="fullWidth"
                    >
                      <StyledTab
                        label={t('opinions')}
                        onClick={() => {
                          // setPage(3);
                        }}
                      />
                      {faqs && (
                        <StyledTab
                          label={t('FAQ')}
                          onClick={() => {
                            // setPage(3);
                          }}
                        />
                      )}
                    </StyledTabs>
                    <Box />
                  </Box>

                  <TabPanel value={activeTab} index={tabCount++}>
                    <CommentCard comments={comments} />
                  </TabPanel>
                  {faqs && (
                    <TabPanel value={activeTab} index={tabCount++}>
                      <ProductFAQCard faqs={faqs} />
                    </TabPanel>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
