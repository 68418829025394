import React, { useState } from "react";
import ProductFAQItemCard from "../product-faq-item-card/product-faq-item-card";
import ShopPagination from "../pagination/pagination";

export default function ProductFAQCard(props) {
  const faqs = props.faqs;
  const [page, setPage] = useState(1);

  return (
    <>
      <div className="w-full">
        {faqs &&
          faqs.map((faq, index) => {
            if ((page - 1) * 3 <= index && index < page * 3) {
              return <ProductFAQItemCard item={faq} key={index} />;
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
      </div>
      {faqs && faqs.length > 3 && (
        <div className="w-full">
          <ShopPagination
            pagesCount={
              faqs.length % 3 === 0
                ? faqs.length / 3
                : Math.floor(faqs.length / 3) + 1
            }
            page={page}
            handleChange={(event, value) => {
              setPage(value);
            }}
          />
        </div>
      )}
    </>
  );
}
