import PencilIcon from "../../../assets/img/pencil_icon.svg";
import TrashIcon from "../../../assets/img/trash_icon.svg";
import PinIcon from "../../../assets/img/pin_icon.svg";
import AppContext from "../../context/context";
import { useContext, useState } from "react";
import Input from "../input/input";
import Textarea from "../textarea/textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function CartAddressItemCard(props) {
  const { PUBLIC_URL, getToken } = useContext(AppContext);
  const item = props.item ? props.item : null;
  const [addressName, setAddressName] = useState("");
  const [address, setAddress] = useState("");
  const { t } = useTranslation();

  async function deleteAddress() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/deleteaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  async function editAddress() {
    try {
      if (!address || !addressName) {
        // setError(t("ramz_ra_vared_konid"));
        return;
      }
      await fetch(`${PUBLIC_URL}/api/customer/editaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
          addressName: addressName,
          address: address,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            setAddressName("");
            setAddress("");
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  async function pinAddress() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/pinaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressId: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            props.setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  return (
    <div className="rounded-[15px] shadow p-[5px] mt-[10px] bg-[#fff]">
      <div className="flex items-center">
        <div className="w-[50%]">
          <span className="text-[#000] inline-block p-[3px_15px] bg-[#0000001A] rounded-[15px]">
            {addressName ? (
              <Input
                type="text"
                value={addressName}
                onchange={(value) => {
                  setAddressName(value);
                }}
                placeholder={t('message_enter_address_name')}
                classes="w-full p-[10px] bg-transparent"
              />
            ) : (
              item.name && item.name
            )}
          </span>
        </div>
        <div className="w-[50%] flex justify-end gap-[5px]">
          {props.displayAdd && props.addAddress && (
            <span
              className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#0000001A] cursor-pointer"
              onClick={()=>props.addAddress(item.address)}
            >
              <FontAwesomeIcon icon={faPlus} fontSize={14} />
            </span>
          )}

          <span
            className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#0000001A] cursor-pointer"
            onClick={() => {
              if (addressName) {
                editAddress();
              } else {
                setAddressName(item.name);
                setAddress(item.address);
              }
            }}
          >
            <img src={PencilIcon} alt="" className="w-[15px]" />
          </span>
          <span
            className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#0000001A] cursor-pointer"
            onClick={deleteAddress}
          >
            <img src={TrashIcon} alt="" className="w-[15px]" />
          </span>
          <span
            className={`w-[35px] h-[35px] flex items-center justify-center rounded-full ${
              item.main_address ? "bg-[#FFD954]" : "bg-[#0000001A]"
            } cursor-pointer`}
            onClick={pinAddress}
          >
            <img src={PinIcon} alt="" className="w-[15px]" />
          </span>
        </div>
      </div>
      <div className="p-[3px_15px] mt-[5px] bg-[#0000001A] rounded-[15px]">
        {address ? (
          <Textarea
            value={address}
            onchange={(value) => {
              setAddress(value);
            }}
            placeholder={t('address_placeholder')}
            classes="w-full p-[10px] bg-transparent"
          />
        ) : (
          item.address
        )}
      </div>
    </div>
  );
}
