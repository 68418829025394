import { useTranslation } from "react-i18next";
import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import { Link } from "react-router-dom";
import InfoIcon from "../../../assets/img/info_icon.svg";
import LikeIcon from "../../../assets/img/like_icon.svg";
import WishlistIcon from "../wishlist/wishlisticon";
import React from "react";
import ProductDefaultImage from "../../../assets/img/product-placeholder.png";

export default function CartProductItemCard(props) {
  const item = props.item;
  const { t } = useTranslation();
  return (
    <div className="rounded-[15px] shadow flex p-[10px] lg:p-[20px] bg-[#fff] mb-[15px]">
      <div className="w-[30%] flex items-center justify-center ltr:pr-[10px] rtl:pl-[10px] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#00000033]">
        <div className="flex items-center border-solid border-[1px] border-[#00000033] p-[10%] rounded-[15px] w-full h-auto md:h-[80px] lg:h-[200px]">
            <img
              src={item.images ? item.images.split(",")[0] : ProductDefaultImage}
              alt={item.name}
              className="mx-auto"
            />
        </div>
      </div>
      <div className="w-[70%] p-[0_10px_10px_10px]">
        <div className="flex items-center">
          <Link
            to={`/product/${item.slug}`}
            className="font-[700] text-[15px] md:text-[18px] lg:text-[25px] w-[75%]"
          >
            <div>{item.name}</div>
            {item.attribute_type === 3 && (
              <div className="text-[10px]">{item.attribute_name}</div>
            )}
          </Link>
          <div className="w-[25%] flex items-center justify-end gap-[10px]">
            <WishlistIcon productId={item.id} />
          </div>
        </div>
        {item && item.categories && (
          <div className="categories font-[700] text-[14px]">
            {JSON.parse(item.categories).map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <span className="mx-[5px]">-</span>}
                  <span className="inline-block text-[#000]">
                    {category.name}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        )}
        <div className="flex items-center relative py-[5px]">
          {item && item.sku && (
            <div className="w-[50%] z-[2]">
              <span className="bg-[#fff] pr-[10px] text-[10px] md:text-[12px] lg:text-[14px]">
                {t('sku')}: {item && item.sku}
              </span>
            </div>
          )}

          {item && item.brand && (
            <div className="w-[50%] flex justify-end  z-[2]">
              <span className="bg-[#fff] ltr:pl-[10px] rtl:pr-[10px] text-[10px] md:text-[12px] lg:text-[14px]">
                {item.brand}
              </span>
            </div>
          )}

          <div className="absolute w-full h-[2px] top-[50%] left-0 bg-[#999]"></div>
        </div>

        <div className="mb-[20px] lg:mb-[50px]">
          <div className="flex items-center">
            <img src={InfoIcon} alt="" className="ltr:mr-[10px] rtl:ml-[10px]" />
            {t('product_test_guarantee')}
          </div>

          {/* <div className="flex items-center text-[14px]">
            <img src={InvestIcon} alt="" className="mr-[10px]" />
            <Link to="" className="text-[#603085] hover:text-[#000]">
              Click here{" "}
            </Link>{" "}
            to view the special offer and buy gold
          </div> */}
          {item.rate && item.review_count > 0 && (
            <div className="flex items-center text-[14px]">
              <img src={LikeIcon} alt="" className="ltr:mr-[10px] rtl:ml-[10px]" />
              {(item.rate * 100) / 5} % ( {item.review_count} {t('message_product_review')}
            </div>
          )}
        </div>
        <div className="flex items-center mt-[10px] border-solid border-[1px] border-[#00000033] p-[10px] rounded-[20px]">
          <div className="flex justify-start w-full">
            <AddToCartButton attribute={item} text={`+ ${t('add')}`} deleteAll />
          </div>
          <div className="w-full flex justify-end text-[#0BC154] text-[14px] md:text-[25px]">
            <Price
              price={
                (item.sale_price ? item.sale_price : item.price) * item.count
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
