import React from "react";
import AppContext from "../../../context/context";
import { useContext } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MenuItem from "./menu-item";

const menu_items = [
  {
    id: 0,
    title: "Shop by brand",
    link: "",
    thumbnail: "/media/service_1.jpg",
    submenu: [
      {
        id: 8,
        title: "Dry Food",
        link: "",
      },
      {
        id: 9,
        title: "Wet Food",
        link: "",
      },
      {
        id: 10,
        title: "Treats & Bones",
        link: "",
      },
      {
        id: 11,
        title: "Supplements",
        link: "",
      },
      {
        id: 12,
        title: "hygienic",
        link: "",
      },
      {
        id: 13,
        title: "Grooming & Care",
        link: "",
      },
      {
        id: 14,
        title: "Toys & Sports",
        link: "",
      },
      {
        id: 15,
        title: "Tick & Flea",
        link: "",
      },
      {
        id: 16,
        title: "Bowls & Feeders",
        link: "",
      },
      {
        id: 17,
        title: "Leads & Collars",
        link: "",
      },
      {
        id: 18,
        title: "Clothing & Shoes",
        link: "",
      },
      {
        id: 19,
        title: "kennels & Flaps",
        link: "",
      },
      {
        id: 20,
        title: "Beds & Baskets",
        link: "",
      },
      {
        id: 21,
        title: "Pet Tech",
        link: "",
      },
      {
        id: 22,
        title: "Outdoor Shop",
        link: "",
      },
      {
        id: 23,
        title: "Outdor accessories",
        link: "",
      },
      {
        id: 24,
        title: "Other accessories",
        link: "",
      },
      {
        id: 25,
        title: "Puppy Products",
        link: "",
      },
      {
        id: 26,
        title: "Dental products",
        link: "",
      },
      {
        id: 27,
        title: "Hot offer",
        link: "",
      },
      {
        id: 28,
        title: "Promotion",
        link: "",
      },
      {
        id: 29,
        title: "Agility & Training",
        link: "",
      },
      {
        id: 30,
        title: "Shop All",
        link: "",
      },
    ],
  },
  {
    id: 1,
    title: "Shop by pet",
    link: "",
  },
  {
    id: 2,
    title: "Contact Us",
    link: "/contact-us",
  },
  {
    id: 1,
    title: "About Us",
    link: "/about-us",
  },
];

export default function Menu() {
  const { WEBSITE_URL, setFilters, filters } = useContext(AppContext);
  const [menuToggle, setMenuToggle] = useState(0);

  return (
    <nav className="header-menus w-full">
      <div className="flex flex-wrap lg:flex-nowrap w-full lg:w-auto">
        {menu_items.map((menu, index) => {
          return menu.submenu ? (
            <span
              key={index}
              className="text-[#fff8] hover:text-[#fff] text-[1rem] p-[10px_0] ltr:lg:p-[10px_2.5rem_10px_0] rtl:lg:p-[10px_0_10px_2.5rem] w-full lg:w-auto cursor-pointer"
              onClick={() => {
                if (menuToggle === 1) {
                  setMenuToggle(0);
                } else {
                  setMenuToggle(1);
                }
              }}
            >
              <span className="flex lg:inline-block w-auto lg:w-full">
                <div className="w-[calc(100%-35px)] lg:w-full text-[16px] font-[300] capitalize text-white leading-normal">
                  {menu.title}
                </div>
                <div className="w-[35px] flex lg:hidden items-center justify-end">
                  {menuToggle === 1 ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                </div>
              </span>
              <div
                className={`sub-menu sh-blur relative lg:absolute ${
                  menuToggle === 1 ? "flex show" : "hidden"
                } top-[15px] left-0 text-[#fff] mt-0 lg:mt-[44px] z-0 lg:z-[2] w-full rounded-[10px] border-[1px] border-[#000] border-solid`}
              >
                <div className="flex items-center flex-wrap lg:flex-nowrap w-full">
                  <div className="flex flex-wrap lg:flex-wrap w-full lg:w-[77.44%] p-[20px_14px]">
                    {menu.submenu &&
                      menu.submenu.map((submenu, index) => {
                        return (
                          <React.Fragment key={index}>
                            <MenuItem
                              onClick={() => {
                                setFilters({
                                  ...filters,
                                  categoryId: submenu.id,
                                });
                                setMenuToggle(0);
                              }}
                              item={submenu}
                            />
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <div className="w-[22.55%] justify-end hidden lg:flex p-[0.38rem]">
                    <img
                      src={`${WEBSITE_URL + menu.thumbnail}`}
                      className="rounded-[10px] duration-300 transition"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </span>
          ) : (
            <span key={index} className="text-[#fff8] hover:text-[#fff] text-[1rem] p-[10px_0] ltr:lg:p-[10px_2.5rem_10px_0] rtl:lg:p-[10px_0_10px_2.5rem] w-full lg:w-auto cursor-pointer">
              <span className="flex lg:inline-block w-auto lg:w-full">
                <Link
                  to={menu.link}
                  className="w-full text-white text-[16px] font-[300] capitalize leading-normal"
                >
                  {menu.title}
                </Link>
              </span>
            </span>
          );
        })}
      </div>
    </nav>
  );
}
