import { Rating } from "@mui/material";

export default function ReviewItemCard(props) {
  const review = props.item ? props.item : null;

  return (
    <div className="rounded-[20px] shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] p-[5px] mb-[10px] border-[1px_solid_rgba(0,0,0,0.25)]">
      <div className="flex items-center">
        <div className="w-[75%] flex items-center">
          <div className="text-[#603085] inline-block p-[3px_15px] ltr:mr-[5px] rtl:ml-[5px]">
            {review.customer_name ? review.customer_name : review.user_name}
          </div>

          {review.rate && (
            <div className="flex p-[3px_15px]">
              <Rating
                name="read-only"
                value={review.rate}
                readOnly
                sx={{ color: "#603085" }}
              />
            </div>
          )}
        </div>
        <div className="w-[25%] flex justify-end">
          {review.date && (
            <span className="p-[3px_15px]">
              {new Date(review.date).toLocaleString("en-EN", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </span>
          )}
        </div>
      </div>
      <div className="p-[3px_15px] mt-[5px]">
        {review.comment}
      </div>
    </div>
  );
}
