import { Skeleton, Stack } from "@mui/material";

export default function PostSkeleton(props) {
  return Array.apply(0, Array(props.count ? props.count : 4)).map((index) => {
    return (
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
        key={index}
      >
        <Skeleton
          variant="square"
          height={200}
          sx={{
            borderRadius: "20px 20px 0 0",
          }}
        />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      </Stack>
    );
  });
}
