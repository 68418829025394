import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../assets/img/close_icon.svg";
import DeliveryIcon from "../../../assets/img/delivery_icon.svg";
import LikeIcon from "../../../assets/img/like_icon.svg";
import InfoIcon from "../../../assets/img/info_icon.svg";
import GaranteeIcon from "../../../assets/img/garantee_icon.svg";
import { Dialog, Rating } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import { useTranslation } from "react-i18next";
import { ProductQuickViewIcon } from "../icons";

export default function ProductQuickView(props) {
  const product = props.product;
  const attributes = JSON.parse(product.attributes).filter(
    (item) => item.attribute_type !== 3
  )
    ? JSON.parse(product.attributes).filter((item) => item.attribute_type !== 3)
    : [];
  const packages = JSON.parse(product.attributes).filter(
    (item) => item.attribute_type === 3
  );
  const metas = product.metas ? JSON.parse(product.metas) : [];
  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0]);
  const [toggle, setToggle] = useState(false);
  const [activeImage, setActiveImage] = useState(
    product.images ? product.images.split(",")[0] : attributes[0].thumbnail
  );
  const reviews = product.reviews ? JSON.parse(product.reviews) : null;

  const { t } = useTranslation();
  const firstVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });
  const secondVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });

  const [selectedVariant, setSelectedVariant] = useState({
    first: null,
    second: null,
  });

  if (product.product_attributes && product.product_attributes.length > 0) {
    firstVariant.current.items = [];
    secondVariant.current.items = [];
    product.product_attributes.forEach((element) => {
      if (firstVariant.current.type === 0) {
        firstVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [element],
        };
      } else if (firstVariant.current.type === element.attribute_type) {
        firstVariant.current = {
          ...firstVariant.current,
          items: [...firstVariant.current.items, element],
        };
      } else {
        secondVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [...secondVariant.current.items, element],
        };
      }
    });
  }

  useEffect(() => {
    if (attributes) {
      const tattributes = attributes.filter(
        (item) => item.attribute_type !== 3
      );

      selectedAttribute.current = tattributes[0];

      if (tattributes[0].attribute_id) {
        const ids = tattributes[0].attribute_id.split(",");

        if (ids[0] && ids[1]) {
          setSelectedVariant({ first: +ids[0], second: +ids[1] });
        } else if (ids[0] && !ids[1]) {
          setSelectedVariant({ first: +ids[0], second: null });
        }
      }
    }
  }, []);

  return (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <ProductQuickViewIcon className="w-[16px] 2xl:w-auto" />
      </span>
      <Dialog
        open={toggle}
        onClose={() => {
          setToggle(false);
        }}
        scroll="body"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="sh-blur"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "90%",
            },
          },
        }}
      >
        <div className="bg-[#fff] rounded-[20px] p-[30px] relative">
          <div className="absolute top-[10px] ltr:right-[10px] rtl:left-[10px]">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] bg-[#00000033] rounded-full cursor-pointer"
              onClick={() => {
                setToggle(false);
              }}
            />
          </div>
          <div className="flex">
            <div className="w-[50%] flex gap-[10px]">
              <div className="w-[20%]">
                {product.images &&
                  product.images.split(",")?.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer flex items-center justify-center w-full border-solid border-[1px] border-[#00000040] rounded-[20px] p-3 mb-[10px]"
                        onClick={() => {
                          setActiveImage(image);
                        }}
                      >
                        <LazyLoadImage
                          src={image}
                          alt={product.name}
                          className="h-[80px] w-auto"
                        />
                      </div>
                    );
                  })}
                {attributes &&
                  attributes?.map((attribute, index) => {
                    if (attribute.thumbnail) {
                      return (
                        <div
                          key={index}
                          className="cursor-pointer flex items-center justify-center w-full border-solid border-[1px] border-[#00000040] rounded-[20px] p-3 mb-[10px]"
                          onClick={() => {
                            setActiveImage(attribute.thumbnail);
                          }}
                        >
                          <LazyLoadImage
                            src={attribute.thumbnail}
                            alt={product.name}
                            className="h-[80px] w-auto"
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
              <div className="w-[80%]">
                <div className="border-solid border-[1px] border-[#00000040] rounded-[20px] p-3">
                  {activeImage && (
                    <LazyLoadImage src={activeImage} alt={product.name} />
                  )}
                </div>
              </div>
            </div>
            <div className="w-[50%] ltr:pl-[20px] rtl:pr-[20px]">
              <div className="flex">
                <div className="font-[700] text-[35px]">{product.name}</div>
                <div className=""></div>
              </div>
              {product && product.categories && (
                <div className="categories font-[700] text-[14px]">
                  {JSON.parse(product.categories).map((category, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index !== 0 && <span className="mx-[5px]">-</span>}
                        <span className="inline-block text-[#000]">
                          {category.name}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              <div className="flex items-center relative">
                <div className="w-[50%] z-[2]">
                  <span className="bg-[#fff] pr-[10px]">
                    SKU: {selectedAttribute && selectedAttribute.sku}
                  </span>
                </div>
                <div className="w-[50%] flex justify-end z-[2]">
                  <span className="bg-[#fff] ltr:pl-[10px] rtl:pr-[10px]">
                    {product.brand}
                  </span>
                </div>
                <div className="absolute w-full h-[2px] top-[50%] ltr:left-0 rtl:right-0 bg-[#999]"></div>
              </div>
              <div className="font-[700] mt-[15px]">{t("about_product")}:</div>
              {product.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                />
              )}
              {selectedAttribute && selectedAttribute.description && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: selectedAttribute.description,
                  }}
                />
              )}

              {metas && metas.length > 0 && (
                <>
                  <div className="bg-[#999] w-full h-[2px] my-[10px]"></div>
                  <div className="w-full">
                    {metas.map((meta, index) => {
                      return (
                        <div className="flex" key={index}>
                          <img
                            src={GaranteeIcon}
                            alt={meta.name}
                            className="ltr:mr-[10px] rtl:ml-[10px]"
                          />
                          {meta.name}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {firstVariant.current.items.length > 0 && (
                <>
                  <div className="mt-[15px]">
                    {firstVariant.current.type_name}
                  </div>
                  <div className=" flex flex-wrap gap-[5px]">
                    {firstVariant.current.items.map(
                      (product_attribute, index) => {
                        return (
                          <div
                            className={`w-[calc(50%-4px)] md:w-[calc(25%-4px)] p-[3px] ${
                              selectedVariant.first ===
                              product_attribute.attribute_id
                                ? "bg-[#603085] text-white"
                                : "bg-[#D9D9D9] text-[#626262]"
                            }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                            key={index}
                            onClick={() => {
                              setSelectedVariant({
                                ...selectedVariant,
                                first: product_attribute.attribute_id,
                              });
                              if (secondVariant.current.items.length === 0) {
                                setSelectedAttribute(
                                  attributes.filter((item) => {
                                    return (
                                      +item.attribute_id ===
                                      +product_attribute.attribute_id
                                    );
                                  })[0]
                                );
                              } else if (selectedVariant.second) {
                                setSelectedAttribute(
                                  attributes.filter((item) => {
                                    return (
                                      item.attribute_id ==
                                      product_attribute.attribute_id +
                                        "," +
                                        selectedVariant.second
                                    );
                                  })[0]
                                );
                              }
                            }}
                          >
                            {product_attribute.attribute_type === 1 && (
                              <span
                                className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                style={{
                                  background: product_attribute.attribute_value,
                                }}
                              ></span>
                            )}
                            {product_attribute.attribute_name}
                          </div>
                        );
                      }
                    )}
                  </div>
                  {selectedVariant.first &&
                    firstVariant.current.items &&
                    firstVariant.current.items.length > 0 &&
                    firstVariant.current.items.filter((item) => {
                      return item.attribute_id === selectedVariant.first;
                    })[0] &&
                    firstVariant.current.items.filter((item) => {
                      return item.attribute_id === selectedVariant.first;
                    })[0].attribute_description && (
                      <>
                        <div className="text-[14px]">
                          {firstVariant.current.type_name +
                            " " +
                            t("description")}
                        </div>

                        <div className="text-[12px]">
                          {
                            firstVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.first
                              );
                            })[0].attribute_description
                          }
                        </div>
                      </>
                    )}
                </>
              )}

              {secondVariant.current.items &&
                secondVariant.current.items.length > 0 && (
                  <>
                    <div className="mt-[15px]">
                      {secondVariant.current.type_name}
                    </div>
                    <div className=" flex flex-wrap gap-[5px]">
                      {secondVariant.current.items.map(
                        (product_attribute, index) => {
                          return (
                            <div
                              className={`w-[calc(50%-4px)] md:w-[calc(25%-4px)] p-[3px] ${
                                selectedVariant.second ===
                                product_attribute.attribute_id
                                  ? "bg-[#603085] text-white"
                                  : "bg-[#D9D9D9] text-[#626262]"
                              }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                              key={index}
                              onClick={() => {
                                setSelectedVariant({
                                  ...selectedVariant,
                                  second: product_attribute.attribute_id,
                                });

                                setSelectedAttribute(
                                  attributes.filter((item) => {
                                    return (
                                      item.attribute_id ==
                                      selectedVariant.first +
                                        "," +
                                        product_attribute.attribute_id
                                    );
                                  })[0]
                                );
                              }}
                            >
                              {product_attribute.attribute_type === 1 && (
                                <span
                                  className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                  style={{
                                    background:
                                      product_attribute.attribute_value,
                                  }}
                                ></span>
                              )}
                              {product_attribute.attribute_name}
                            </div>
                          );
                        }
                      )}
                    </div>
                    {selectedVariant.second &&
                      secondVariant.current.items &&
                      secondVariant.current.items.length > 0 &&
                      secondVariant.current.items.filter((item) => {
                        return item.attribute_id === selectedVariant.second;
                      })[0] &&
                      secondVariant.current.items.filter((item) => {
                        return item.attribute_id === selectedVariant.second;
                      })[0].attribute_description && (
                        <>
                          <div className="text-[14px]">
                            {secondVariant.current.type_name +
                              " " +
                              t("description")}
                          </div>
                          <div className="text-[12px]">
                            {
                              secondVariant.current.items.filter((item) => {
                                return (
                                  item.attribute_id === selectedVariant.second
                                );
                              })[0].attribute_description
                            }
                          </div>
                        </>
                      )}
                  </>
                )}
              <div className="bg-[#999] w-full h-[2px] my-[10px]"></div>
              <div className="">
                <div className="flex items-center">
                  <img
                    src={InfoIcon}
                    alt=""
                    className="ltr:mr-[10px] rtl:ml-[10px]"
                  />
                  {t("product_test_guarantee")}
                </div>
                {reviews && (
                  <div className="flex items-center text-[14px]">
                    <img
                      src={LikeIcon}
                      alt=""
                      className="ltr:mr-[10px] rtl:ml-[10px]"
                    />
                    {(product.rate * 100) / 5} % ( {reviews.length}{" "}
                    {t("message_product_review")}
                  </div>
                )}

                {/* <div className="flex items-center text-[14px]">
                  <img src={InvestIcon} alt="" className="mr-[10px]" />
                  <Link to="" className="text-[#603085] hover:text-[#000]">
                    Click here{" "}
                  </Link>{" "}
                  to view the special offer and buy gold
                </div> */}
              </div>
              {packages && packages.length > 0 && (
                <div>
                  <div className="flex justify-end ">
                    <span
                      className="cursor-pointer text-[#603085] text-[10px]"
                      onClick={() => {
                        setSelectedAttribute(attributes[0]);
                      }}
                    >
                      {t("delete_transaction")}
                    </span>
                  </div>
                  <div className="flex flex-wrap mt-[15px]">
                    {packages.map((item, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                            setSelectedAttribute(item);
                          }}
                          className={`${
                            selectedAttribute &&
                            selectedAttribute.id === item.id
                              ? "bg-[#603085] text-[#fff]"
                              : "bg-[#80808080] text-[#fff]"
                          } cursor-pointer flex items-center text-[12px] p-[3px] rounded-[20px] ltr:mr-[5px] rtl:ml-[5px] mb-[5px] shadow`}
                        >
                          <span className="mx-[10px]">
                            {item.attribute_name}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                  <div className="flex border-solid border-[1px] border-[#D7D7D7] rounded-[20px] p-[3px] mt-[15px]">
                    <div className="flex w-full items-center px-[10px] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#D7D7D7]">
                      <div className="w-full text-[11px]">
                        {t("original_price")}:{" "}
                      </div>
                      <div className="text-[#0BC154] flex justify-end">
                        {selectedAttribute && (
                          <Price
                            price={
                              selectedAttribute.sale_price
                                ? selectedAttribute.sale_price
                                : selectedAttribute.price
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex w-full items-center px-[10px] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#D7D7D7]">
                      <div className="w-full text-[11px]">
                        {t("transaction_price")}:
                      </div>
                      <div className="text-[#0BC154] flex justify-end">
                        {selectedAttribute ? (
                          <Price
                            price={
                              selectedAttribute.attribute_type === 3
                                ? selectedAttribute.price *
                                  selectedAttribute.attribute_value
                                : 0
                            }
                          />
                        ) : (
                          <Price price={0} />
                        )}
                      </div>
                    </div>
                    <div className="flex w-full items-center px-[10px]">
                      <div className="w-full text-[11px]">
                        {t("your_profit")}:{" "}
                      </div>
                      <div className="text-[#0BC154] flex justify-end">
                        {selectedAttribute && attributes ? (
                          <Price
                            price={
                              attributes.filter(
                                (item) =>
                                  item.id === selectedAttribute.parent_id
                              ).length > 0 &&
                              selectedAttribute.attribute_type === 3
                                ? (attributes.filter(
                                    (item) =>
                                      item.id === selectedAttribute.parent_id
                                  )[0].sale_price
                                    ? attributes.filter(
                                        (item) =>
                                          item.id ===
                                          selectedAttribute.parent_id
                                      )[0].sale_price
                                    : attributes.filter(
                                        (item) =>
                                          item.id ===
                                          selectedAttribute.parent_id
                                      )[0].price) *
                                    selectedAttribute.attribute_value -
                                  selectedAttribute.price *
                                    selectedAttribute.attribute_value
                                : 0
                            }
                          />
                        ) : (
                          <Price price={0} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center mt-[15px]">
                <div className="flex items-center w-[65%] text-[10px]">
                  <Rating
                    name="read-only"
                    value={product.rate}
                    readOnly
                    sx={{ color: "#FFD954", marginRight: "10px" }}
                  />
                  {product.rate ? parseFloat(product.rate).toFixed(1) : t("no")}{" "}
                  {t("product_rating")}
                </div>

                <div className="flex items-center justify-end w-[35%] text-[10px]">
                  {t("one_day_delivery")}
                  <img src={DeliveryIcon} alt="" className="ml-[10px]" />
                </div>
              </div>
              <div className="mt-[15px] flex items-center">
                <div className="w-full text-[20px]">
                  {selectedAttribute.stock > 0 ? (
                    <>
                      {t("price")}:{" "}
                      <span className="text-[#0BC154]">
                        {selectedAttribute && (
                          <Price
                            price={
                              selectedAttribute.sale_price
                                ? selectedAttribute.sale_price
                                : selectedAttribute.price
                            }
                          />
                        )}
                      </span>
                    </>
                  ) : (
                    <span className="text-[#7F7F7F]">{t("unavailable")}</span>
                  )}
                </div>
                <div className="w-full flex justify-end">
                  <AddToCartButton
                    attribute={selectedAttribute}
                    text={t("add_to_cart")}
                    classes="bg-[#000]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
