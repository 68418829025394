import Header from "./layout/header";
import { Helmet } from "react-helmet";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Banner from "./components/banner/banner";
import ShopIcon from "../assets/img/shop_icon.svg";
import Footer from "./layout/footer";
import LeftSidebar from "./layout/left-sidebar";
import PostCard from "./components/post-card/post-card";
import ProductSkeleton from "./components/skeleton/product/product-skeleton";
import { Link } from "react-router-dom";
import ShopPagination from "./components/pagination/pagination";
import PostCategoryList from "./components/post-category-list/post-category-list";
import LeftSidebarPost from "./layout/left-sidebar-post";

export default function Blog() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters, getToken } =
    useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");
  const page = useRef(1);
  const [posts, setPosts] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const [loader, setLoader] = useState(0);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getPosts = useCallback(async () => {
    try {
      setLoader(1);

      let filter = "";

      if (page.current > 0) {
        filter = filter + "p=" + page.current;
      }

      if (currentLanguageRef.current) {
        if (!filter) {
          filter = filter + "lng=" + currentLanguageRef.current;
        } else {
          filter = filter + "&lng=" + currentLanguageRef.current;
        }
      }

      if (filters.postSearchTerm) {
        if (!filter) {
          filter = filter + "term=" + filters.postSearchTerm;
        } else {
          filter = filter + "&term=" + filters.postSearchTerm;
        }
      }

      if (filters.postYourPet) {
        if (!filter) {
          filter = filter + "pet=1";
        } else {
          filter = filter + "&pet=1";
        }
      }

      if (filters.postCategoryId > 0) {
        filter = filter + "&cid=" + filters.postCategoryId;
      } else {
        filter = filter + "&cid=" + filters.postCategoryId;
      }

      await fetch(`${PUBLIC_URL}/api/post/postlist?${filter}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          if (data.posts) {
            setPostsCount(data.count);
            setPosts(data.posts);
          }
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
  }, [PUBLIC_URL, page, filters, getToken]);

  useEffect(() => {
    setPosts([]);
    getPosts();
  }, [page, currentLanguage, getPosts]);

  useEffect(() => {
    setPosts([]);
    getPosts();
  }, [filters, setFilters, getPosts]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <PostCategoryList />
        <div className="text-center text-[4rem] font-bold leading-[40px] mb-[15px]">
          {t('shawn_online_blog')}
        </div>
        <div className="mt-[25px] flex">
          <div className="w-[15%]"></div>
          <div className="w-[70%] flex flex-wrap items-center text-[#4D4D4D]">
            <div className="w-full flex items-center">
              <div className="w-full flex">
                <img
                  src={ShopIcon}
                  alt=""
                  className="ltr:mr-[10px] rtl:ml-[10px] w-[25px]"
                  style={{
                    color: "#4D4D4D",
                  }}
                />{" "}
                <Link to="/">{t('shawn')}</Link>
                {" > "} <Link to="/blog">{t('blog')}</Link>
                {" > "} {t('all_articles')}
              </div>
              <div className="w-full flex justify-end">
                {posts && postsCount} {t('items')}
              </div>
            </div>
            <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] w-full my-[5px]"></div>
          </div>
          <div className="w-[15%]"></div>
        </div>
        <div className="flex">
          <div className="w-[20%] mb-[20px]">
            <LeftSidebarPost />
          </div>
          <div className="w-[80%] text-[#4D4D4D] ltr:pr-[10px] rtl:pl-[10px]">
            <div className="flex flex-wrap gap-[10px] mb-[20px] w-full">
              {loader === 0 ? (
                posts ? (
                  posts.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <PostCard
                          post={item}
                          classes="w-[calc(25%-8px)]"
                        />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div
                    className="w-full p-[20px] bg-[#fff] rounded-[15px]"
                    style={{ width: "100%" }}
                  >
                    {t('message_no_post')}
                  </div>
                )
              ) : (
                <ProductSkeleton count={12} />
              )}
            </div>
            {postsCount > 12 && (
              <div className="w-full">
                <ShopPagination
                  pagesCount={
                    postsCount % 12 === 0
                      ? postsCount / 12
                      : Math.floor(postsCount / 12) + 1
                  }
                  page={page.current}
                  handleChange={(event, value) => {
                    page.current = value;
                    getPosts();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
