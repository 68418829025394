import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Banner from "./components/banner/banner";
import ProductCategoryList from "./components/category-list/category-list";
import { Link } from "react-router-dom";
import ProductCarousel from "./components/productcarousel/productcarousel";
import ServiceCard from "./components/service-card/service-card";
import AskCard from "./components/ask-card/ask-card";
import BGForYourPet from "../assets/img/for_your_pet_bg.png";
import BGDiscount from "../assets/img/discount-bg.png";
import Footer from "./layout/footer";
import PostLoader from "./components/post-loader/post-loader";
import NewsletterCard from "./components/newsletter-card/newsletter-card";
import TimeCountDown from "./components/time-countdown/time-countdown";
import {
  FooterBannerIcon,
  MoreNewsIcon,
  PetDiscountIcon,
  SubscribeIcon,
} from "./components/icons";

export default function Home() {
  const { WEBSITE_URL, currentLanguage, getToken } = useContext(AppContext);
  const [newsletterToggle, setNewsletterToggle] = useState(false);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <div className="py-[53.67px]">
          <div className="w-[95%] lg:w-[65.41%] mx-auto">
            <ProductCategoryList />
          </div>
        </div>
        <div className="text-center text-[25px] md:text-[35px] lg:text-[45px] 2xl:text-[54px] font-[700] leading-normal">
          {t("shawn_online_shop")}
        </div>
        <div className="max-w-[95%] lg:max-w-[62.5%] 2xl:max-w-[62.5%] mx-auto text-center text-[16px] 2xl:text-[24px]">
          {t("home_description_1")}
        </div>
        <div className="bg-[rgba(30,30,30,0.7)] rounded-[15px] m-[30px_15px_0_15px] lg:m-[30px_12.71%_0_12.71%] 2xl:m-[50px_12.71%_0_12.71%] text-white border-solid border-[#626060] border-[11px]">
          <div className="bg-[rgba(0,0,0,0.25)] flex flex-wrap md:flex-nowrap items-center p-[8px] border-none rounded-[15px]">
            <div className="w-full md:w-[50%]">
              <h3 className="text-[25px] md:text-[45px] lg:text-[55px] 2xl:text-[90px] font-[900] leading-normal">
                {t("hot_discount")}
              </h3>
              <div className="text-[14px] lg:text-[18px] 2xl:text-[24px]">{t("hot_discount_description")}</div>
              <Link
                to={``}
                className="bg-[rgba(0,0,0,0.5)] rounded-[100px] p-[5px_11px] inline-block mt-[10px] text-[14px] 2xl:text-[18px]"
              >
                {t("goto_discounts")}
              </Link>
            </div>
            <div
              className="w-full md:w-[50%] flex justify-start md:justify-end mt-[10px] md:mt-0"
              style={{
                backgroundImage: `url(${BGDiscount})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundSize: "contain",
              }}
            >
              <TimeCountDown date={"2024-10-24 18:11:15"} bg={"bg-[#E6E6E6]"} />
            </div>
          </div>
        </div>
        <div className="m-[60px_75px_0_75px] lg:m-[15px_12.71%_0_12.71%] 2xl:m-[1.75rem_12.71%_0_12.71%]">
          <ProductCarousel discount={1} key={1} hideDiscount={true} />
        </div>
        <div className="mt-[50px] lg:mt-[83px] text-center text-black text-[25px] md:text-[35px] lg:text-[55px] 2xl:text-[70px] leading-normal font-[900] uppercase">
          {t("shawn_services")}
        </div>
        <div className="mx-[15px] lg:mx-[27.21%] text-center text-[16px] 2xl:text-[16px] text-[#272727]">
          {t("shawn_services_description")}
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-[10px] md:gap-[10px] m-[30px_15px_0_15px] lg:m-[53px_12.71%_0_12.71%]">
          <ServiceCard
            image={`${WEBSITE_URL}/media/service_1.jpg`}
            title="Deals"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/service/1"
          />
          <ServiceCard
            image={`${WEBSITE_URL}/media/service_2.jpg`}
            title="Free shipping"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/service/2"
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-[10px] md:gap-[10px] m-[30px_15px_0_15px] lg:m-[10px_12.71%_0_12.71%] mt-[10px]">
          <ServiceCard
            image={`${WEBSITE_URL}/media/service_3.jpg`}
            title="Charity"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/service/3"
          />
          <ServiceCard
            image={`${WEBSITE_URL}/media/service_4.jpg`}
            title="Training and behaviour"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/service/4"
          />
        </div>
        {getToken() && (
          <>
            <div className="bg-[#603085] rounded-[0.9375rem] m-[30px_15px_0_15px] lg:m-[30px_12.71%_0_12.71%] p-[11px] text-white">
              <div className="rounded-[0.9375rem] bg-[#603085] flex p-[0.63rem] shadow-[0_5px_25px_0_rgba(0,0,0,0.25)]">
                <div className="w-full md:w-[50%]">
                  <h3 className="text-[25px] lg:text-[55px] 2xl:text-[90px] font-[900] leading-normal flex items-center">
                    <PetDiscountIcon />
                    <span className="ml-[11px]">{t("for_your_pet")}</span>
                  </h3>
                  <div className="text-[16px] 2xl:text-[24px]">
                    {t("hot_discount_description")}
                  </div>
                  <Link
                    to={``}
                    className="bg-[rgba(0,0,0,0.50)] rounded-[0.9375rem] p-[5px_11px] inline-block mt-[0.62rem] text-[14px] 2xl:text-[18px]"
                  >
                    {t("goto_discounts")}
                  </Link>
                </div>
                <div
                  className="hidden md:block w-[50%]"
                  style={{
                    backgroundImage: `url(${BGForYourPet})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "right",
                    backgroundSize: "contain",
                  }}
                ></div>
              </div>
            </div>
            <div className="m-[30px_75px_0_75px] md:m-[15px_12.71%_0_12.71%] 2xl:m-[1.31rem_12.71%_0_12.71%]">
              <ProductCarousel yourPet={1} key={2} />
            </div>
          </>
        )}

        {/* <div className="bg-[#dab73b] rounded-[0.9375rem] m-[30px_15px_0_15px] lg:m-[30px_12.71%_0_12.71%] p-[0.69rem_0.75rem]">
          <div className="flex flex-wrap md:flex-nowrap rounded-[0.9375rem] bg-[#FFD954] p-[0.13rem_1.19rem_1.25rem_1.19rem]">
            <div className="w-full md:w-[50%]">
              <h3 className="text-[25px] lg:text-[55px] 2xl:text-[4.375rem] leading-normal font-[900] text-[#000b]">
                {t("new_products")}
              </h3>
              <div className="mt-[0.06rem] text-[1.0625rem] text-[#000]">
                {t("hot_discount_description")}
              </div>
              <Link
                to={``}
                className="bg-[#0008] text-[#FFD954] rounded-[0.9375rem] p-[0.31rem_0.69rem_0.56rem_0.69rem] inline-block mt-[0.62rem] text-[1.0625rem]"
              >
                {t("goto_discounts")}
              </Link>
            </div>
            <div className="w-full md:w-[50%] flex justify-start md:justify-end mt-[10px] md:mt-0">
              <TimeCountDown
                date={"2023-09-24 18:11:15"}
                bg={"bg-[#FFD954]"}
                subbg={"bg-[#FFE589]"}
              />
            </div>
          </div>
        </div>
        <div className="m-[30px_75px_0_75px] md:m-[15px_12.71%_0_12.71%] 2xl:m-[1.31rem_12.71%_0_12.71%]">
          <ProductCarousel key={3} />
        </div> */}
        <div className="text-center text-[25px] lg:text-[55px] 2xl:text-[70px] text-black leading-normal font-[900] uppercase mt-[83px]">
          Ask Shawn
        </div>
        <div className="mx-[15px] md:mx-[27.21%] text-center text-[16px] 2xl:text-[16px] text-[#272727]">
          Introduction of pet service centers and businesses that are approved
          by Shawn
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-[10px] m-[30px_15px_0_15px] lg:m-[69px_12.71%_0_12.71%] 2xl:m-[69px_12.71%_0_12.71%]">
          <AskCard
            image={`${WEBSITE_URL}/media/ask_1.jpg`}
            title="Outdoor Shop"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/ask/1"
          />
          <AskCard
            image={`${WEBSITE_URL}/media/ask_2.jpg`}
            title="Dog Walker"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/ask/2"
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-[10px] mx-[15px] lg:mx-[12.71%] mt-[10px]">
          <AskCard
            image={`${WEBSITE_URL}/media/ask_3.jpg`}
            title="Treatment"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/ask/3"
          />
          <AskCard
            image={`${WEBSITE_URL}/media/ask_4.jpg`}
            title="Learning Center"
            description="Shawn has decided to provide you with some consumer products and goods that can be used by pets in a multiple and cost-effective package in order to save costs and help you to buy more easily."
            link="/ask/4"
          />
        </div>
        <div className="mt-[85px] text-center text-[25px] lg:text-[55px] 2xl:text-[70px] leading-normal font-[900] uppercase">
          {t("magazine")}
        </div>
        <div className="mx-[15px] lg:mx-[27.21%] text-center text-[16px] 2xl:text-[16px]">
          {t("home_magazine_description")}
        </div>
        <div className="flex justify-center mt-[35px]">
          <span
            className="flex items-center bg-[#000] text-[#fff] text-[14px] 2xl:text-[18px] p-[7px_28px] rounded-[100px] cursor-pointer"
            onClick={() => {
              setNewsletterToggle(!newsletterToggle);
            }}
          >
            <SubscribeIcon />
            <span className="ml-[7.5px]">{t("recive_articles_email")}</span>
          </span>
          <NewsletterCard
            toggle={newsletterToggle}
            setToggle={setNewsletterToggle}
          />
        </div>
        <div className="m-[30px_15px_0_15px] lg:m-[43px_12.71%_0_12.71%] 2xl:m-[43px_12.71%_0_12.71%]">
          <PostLoader count={4} />
        </div>

        <div className="relative mt-[50px]" style={{
          backgroundImage: `url(${`${WEBSITE_URL}/media/pre-footer.jpg`})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}>
          {/* <img
            src={`${`${WEBSITE_URL}/media/pre-footer.jpg`}`}
            srcSet={`${`${WEBSITE_URL}/media/pre-footer-sx.jpg`} 2x, ${`${WEBSITE_URL}/media/pre-footer.jpg`} 1x`}
            alt=""
            className="w-full"
          /> */}
          <div className="flex items-center justify-end py-[50px] 2xl:py-0 px-[15px] lg:px-[3.8%]">
            <div className="">
              <div className="flex">
                <FooterBannerIcon />
                <div className="ml-[10px]">
                  <div className="text-[#fff] font-[900] text-[25px] md:text-[54px] leading-0 md:leading-[55px]">
                    {t("in_store")}
                  </div>
                  <div className="text-[#fff] font-[900] text-[25px] md:text-[54px] leading-0 md:leading-[55px]">
                    {t("discounts")}
                  </div>
                </div>
              </div>
              <div className="font-[700] text-[50px] md:text-[90px] text-[#fff] leading-0 md:leading-normal">
                {t("off_15")}
              </div>
              <div className="text-[#fff] w-full md:w-[433px] text-[18px] 2xl:text-[24px]">
                {t("home_in_store_description")}
              </div>
              <Link
                to={``}
                className="inline-flex items-center bg-[#000] text-[#fff] text-[14px] 2xl:text-[18px] rounded-[20px] p-[7px_23px] mt-[10px]"
              >
                <MoreNewsIcon />
                <span className="ml-[7.5px]">{t("more_news")}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="py-[100px]">
          <div className="w-full w-[95%] lg:w-[65.41%] mx-auto">
            <ProductCategoryList />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
