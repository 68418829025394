import { Link } from "react-router-dom";
import CartItemCard from "../cart-item-card/cart-item-card";
import AppContext from "../../context/context";
import { useContext } from "react";
import Price from "../price/price";
import { t } from "i18next";

export default function CartCard() {
  const { cart } = useContext(AppContext);
  return (
    <div className="shadow-[0_4px_10px 0_rgba(0,0,0,0.25)] rounded-[15px]">
      <div className="bg-black py-[11px] text-white text-[14px] 2xl:text-[18px] text-center rounded-[15px_15px_0_0]">
        {t('your_shipping_cart')}
      </div>

      {cart && cart.length > 0 ? (
        <div className="bg-[#fff] pt-[5px] px-[5px] rounded-[0_0_15px_15px]">
          <div className="">
            {cart.map((item, index) => {
              return <CartItemCard item={item} key={index} />;
            })}
          </div>
          <div className="mt-[9px] flex items-center pb-[5px] 2xl:pb-0">
            <div className="w-full text-[18px] 2xl:text-[24px] text-[#0BC154] ltr:pl-[11px] rtl:pr-[11px]">
              <Price
                price={cart?.reduce(
                  (total, product) =>
                    (total =
                      total +
                      product.count *
                        (product.sale_price
                          ? product.sale_price
                          : product.price)),
                  0
                )}
              />
            </div>
            <div className="w-full flex justify-end">
              <Link
                to="/cart"
                className="p-[6px_38px] text-[#fff] text-[18px] 2xl:text-[24px] bg-black rounded-[22px]"
              >
                {t('payment')}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center py-[30px]">{t('message_cart_no_products')}</div>
      )}
    </div>
  );
}
