import { Link } from "react-router-dom";

function getWords(text,wordCount) {
  const textArr=text.split(" ")
  let _text=''
  for(let counter=0;counter<wordCount;counter++){
    _text = _text + textArr[counter] + " "
  }
  return _text
}

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export default function PostCard(props) {
  const post= props.post

  return (
    <Link to={`/post/${post.slug}`} className={`w-[calc(50%-5px)] md:w-[25%] bg-[#fff] rounded-[15px] overflow-hidden p-[4px] block ${props.classes}`}>
      <div className="rounded-[0.9375rem] overflow-hidden">
        <img src={post.thumbnail} alt={post.title} />
      </div>

      <div className="px-[12px] pb-[2px]">
        <div className="text-[18px] lg:text-[24px] 2xl:text-[32px] text-black font-[600]">{post.title}</div>
        <div className="text-[12px] 2xl:text-[18px] text-[#4B4B4B]">{getWords(getText(post.content),11)}...</div>
      </div>
    </Link>
  );
}
