import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function ConfirmCard(props) {
  const { PUBLIC_URL } = useContext(AppContext);
  const [code, setCode] = useState("");
  const [loader, setLoader] = useState(0);
  const [loaderResend, setLoaderResend] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    default: "",
    email: "",
    code: "",
  });
  const { t } = useTranslation();

  async function confirmRequest() {
    try {
      if (!code) {
        setError({ ...error, code: t('message_enter_activation_code') });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/confirm`, {
        method: "POST",
        body: JSON.stringify({
          mobile: props.email,
          code: code,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.token) {
            localStorage.setItem("token", data.token);
            setLoader(1);
            props.settoggle(0);
            enqueueSnackbar(t("message_account_activation"), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          } else {
            setError({ ...error, default: t("message_proccess_error") });
            setLoader(0);
          }
        });
    } catch (error) {
      setError({ ...error, default: t("message_proccess_error") });
      setLoader(0);
    }
  }

  async function resendRequest() {
    // setError('')
    setLoaderResend(1);
    try {
      await fetch(`${PUBLIC_URL}/api/customer/confirmresend`, {
        method: "POST",
        body: JSON.stringify({
          mobile: props.email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoaderResend(0);
          if (data.status === 1) {
            // setMessage(t('code_taid_dobare_ersal_shod'))
            enqueueSnackbar(t("code_resent"), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          } else {
            setError({ ...error, default: t("message_proccess_error") });
          }
        });
    } catch (error) {
      setError({ ...error, default: t("message_proccess_error") });
      setLoaderResend(0);
    }
  }

  return (
    <form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.default}</div>
      )}
      <div className="mb-[15px]">
        <div className="flex rounded-[20px] overflow-hidden text-[11px] md:text-[14px]">
          <div
            className={`w-[280px] bg-[#00000033] ${
              error.code ? "text-[#f00]" : "text-[#000]"
            } p-[15px] uppercase text-[14px] ltr:text-left rtl:text-right`}
          >
            {t("confirm_Code")}
          </div>
          <Input
            type="text"
            value={code}
            onchange={(value) => {
              setCode(value);
              setError({ ...error, code: "" });
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>
        {error.code && (
          <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.code}</div>
        )}
      </div>
      <FormButton
        onclick={() => {
          confirmRequest();
        }}
        classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
      >
        {loader === 1 && (
          <ClipLoader
            color="#ffffff"
            size="15px"
            className="rtl:ml-[10px] ltr:mr-[10px]"
          />
        )}
        {t("activation")}
      </FormButton>
      <FormButton
        onclick={() => {
          resendRequest();
        }}
        classes="mt-[15px] p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
      >
        {loaderResend === 1 && (
          <ClipLoader
            color="#ffffff"
            size="15px"
            className="rtl:ml-[10px] ltr:mr-[10px]"
          />
        )}
        {t('resend_activation_code')}
      </FormButton>
    </form>
  );
}
