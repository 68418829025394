import { useTranslation } from "react-i18next";
import banner_1 from "../../../assets/img/banner-product-bg-1.jpg";

export default function BannerProcust() {
  const { t } = useTranslation();
  return (
    <div className="bg-black flex items-center justify-center p-[0_10%] relative">
      <img src={banner_1} alt="" className="w-[50%]" />
      <div className="absolute p-[10px_15px] lg:p-[10px_3%] w-full bottom-0 left-0">
        <div className="flex flex-wrap md:flex-nowrap items-end">
            <div className="text-white text-[25px] md:text-[50px] lg:text-[96px] text-center uppercase font-[900] leading-[116px] w-full">
              {t('trust_the_tilt')}
            </div>
        </div>
      </div>
    </div>
  );
}
