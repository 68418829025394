import Header from "./layout/header";
import Footer from "./layout/footer";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "./context/context";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Input from "./components/input/input";
import Select from "./components/select/select";
import Textarea from "./components/textarea/textarea";
import CartCheckoutItemCard from "./components/cart-checkout-item-card/cart-checkout-item-card";
import Price from "./components/price/price";
import FormButton from "./components/form-button/form-button";
import { Dialog } from "@mui/material";
import CartAddressCard from "./components/cart-address-card/cart-address-card";
import TrashIcon from "../assets/img/trash_white_icon.svg";

function calculateDiscount(discount, totalPrice) {
  const discountAmount =
    discount.type === 1
      ? (totalPrice * discount.amount) / 100
      : discount.amount;
  if (discount.min_price && discountAmount < discount.min_price) {
    return 0;
  }

  if (discount.max_price && discountAmount > discount.max_price) {
    return discount.max_price;
  }

  return discountAmount;
}

export default function Checkout() {
  const navigate = useNavigate();
  const {
    cart,
    clearCart,
    getCustomer,
    getToken,
    PUBLIC_URL,
    currentLanguage,
  } = useContext(AppContext);
  const customer = getCustomer() ? getCustomer().customer : { name: "" };
  const [name, setName] = useState(customer.name);
  const [lastName, setLastName] = useState(customer.last_name);
  const [company, setCompany] = useState("");
  const [province, setProvince] = useState(1);
  // const [cities, setCities] = useState();
  // const [city, setCity] = useState(1);
  const [address, setAddress] = useState("");
  const [postal, setPostal] = useState("");
  const [tel, setTel] = useState("");
  const [mobile, setMobile] = useState(customer.mobile ? customer.mobile : "");
  const [email, setEmail] = useState(customer.email ? customer.email : "");
  const [note, setNote] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState({
    type: 0,
    amount: 0,
    code: "",
    min_price: 0,
    max_price: 0,
  });
  // const [payment, setPayment] = useState({
  //   type: 1,
  //   cardNum: "",
  //   date: "",
  //   ref: "",
  // });
  // const [shipment, setShipment] = useState(0);
  const [taxes, setTaxes] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState({
    default: "",
    name: "",
    last_name: "",
    company: "",
    province: "",
    city: "",
    address: "",
    postal: "",
    tel: "",
    mobile: "",
    email: "",
    note: "",
    stock: "",
    discount: "",
  });
  const [loader, setLoader] = useState(0);
  const [discountLoader, setDiscountLoader] = useState(0);
  const [addressToggle, setAddressToggle] = useState(false);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const calcTotal = useCallback(() => {
    let _totalPrice = cart?.reduce(
      (total, product) =>
        (total =
          total +
          product.count *
            (product.sale_price ? product.sale_price : product.price)),
      0
    );

    setTotalPrice(_totalPrice);
  }, [cart]);

  useEffect(() => {
    if (cart) {
      calcTotal();
    }
  }, [cart, calcTotal]);

  const getTaxes = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/get/tax?lng=${currentLanguageRef.current}`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.status !== 0) {
            setTaxes(data);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    getTaxes();
  }, [currentLanguage, getTaxes]);

  const addOrder = async () => {
    try {
      if (!name) {
        setError({ ...error, name: t('message_enter_name') });
        return;
      }
      if (province < 1) {
        setError({ ...error, province: t('message_enter_province') });
        return;
      }
      if (!address) {
        setError({ ...error, address: t('message_enter_address') });
        return;
      }

      if (!mobile && !tel) {
        setError({
          ...error,
          default: t('message_enter_tel_or_mobile'),
        });
        return;
      }

      if (!email) {
        setError({ ...error, email: t('message_enter_email') });
        return;
      }

      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/order/add`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          last_name: lastName,
          company: company,
          provinceId: province,
          // cityId: city,
          address: address,
          tel: tel,
          mobile: mobile ? mobile : null,
          email: email ? email : null,
          postal_code: postal,
          cart: cart,
          discount: discount ? JSON.stringify(discount) : "",
          shipping: 3,
          // payment: payment.type === 1 ? "" : JSON.stringify(payment),
          tax: taxes ? JSON.stringify(taxes) : "",
          note: note ? note : "",
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.order) {
            clearCart();
            navigate(`/bank/${data.order}`);
          } else if (data.status === 2) {
            setError({
              ...error,
              default: t('message_enter_required_fields'),
            });
          } else if (data.status === 3) {
            setError({
              ...error,
              email: t('message_enter_email'),
            });
          } else if (data.status === 4) {
            setError({
              ...error,
              default: t('message_enter_tel_or_mobile'),
            });
          } else {
            setError({
              ...error,
              default: t('message_proccess_error'),
            });
          }
        });
    } catch (er) {
      setLoader(0);
      setError({
        ...error,
        default: t('message_proccess_error'),
      });
    }
  };

  const getDiscountCode = async () => {
    setDiscountLoader(1);
    try {
      if (discountCode) {
        await fetch(`${PUBLIC_URL}/api/get/discount?code=${discountCode}`, {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setDiscountLoader(0);
            if (data.status === 0) {
              error.discount = t("message_wrong_discount_code");
              setDiscount({
                type: 0,
                amount: 0,
                code: "",
                min_price: 0,
                max_price: 0,
              });
            } else {
              setDiscount(data);
              error.discount = "";
            }
          });
      } else {
        setDiscountLoader(0);
        error.discount = t("message_wrong_discount_code");
        // setErrorDiscount('کد تخفیف وارد نشده است.')
      }
    } catch (error) {
      setDiscountLoader(0);
      error.default = t("message_proccess_error");
    }
  };

  const provinceName = [
    {
      key: "1",
      value: "Abu Dhabi",
    },
    {
      key: "2",
      value: "Dubai",
    },
    {
      key: "3",
      value: "Sharjah",
    },
    {
      key: "4",
      value: "Ajman",
    },
    {
      key: "5",
      value: "Umm Al Quwain",
    },
    {
      key: "6",
      value: "Ras Al Khaimah",
    },
    {
      key: "7",
      value: "Fujairah",
    },
  ];

  let daysName = [];
  let counter = 1;
  while (counter < 32) {
    daysName = [
      ...daysName,
      {
        key: counter,
        value: counter,
      },
    ];
    counter += 1;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("checkout")} - {t("website_name")}
        </title>
      </Helmet>
      <Header />
      <div className="bg-[#d9d9d9]">
        <div className="inline-block w-full">
          <div className="flex flex-wrap md:flex-nowrap relative m-[25px_15px] lg:m-[25px_10%] gap-[10px] lg:gap-[20px]">
            <div className="w-full md:w-[50%] lg:w-[60%] bg-[#fff] p-[10px] md:p-[20px] rounded-[20px]">
              <form>
                <div className="mb-[15px]">
                  <div className="flex rounded-[20px] overflow-hidden">
                    <div
                      className={`w-[120px] lg:w-[180px] bg-[#00000033] ${
                        error.name ? "text-[#f00]" : "text-[#000]"
                      } p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right`}
                    >
                      {t('name')} *
                    </div>
                    <Input
                      type="text"
                      value={name}
                      onchange={(value) => {
                        setName(value);
                        setError({ ...error, name: "" });
                      }}
                      classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                    />
                  </div>
                  {error.name && (
                    <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                      {error.name}
                    </div>
                  )}
                </div>

                <div className="mb-[15px]">
                  <div className="flex rounded-[20px] overflow-hidden">
                    <div
                      className={`w-[120px] lg:w-[180px] bg-[#00000033] ${
                        error.last_name ? "text-[#f00]" : "text-[#000]"
                      } p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right`}
                    >
                      {t('last_name')} *
                    </div>
                    <Input
                      type="text"
                      value={lastName}
                      onchange={(value) => {
                        setLastName(value);
                        setError({ ...error, last_name: "" });
                      }}
                      classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                    />
                  </div>
                  {error.last_name && (
                    <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                      {error.last_name}
                    </div>
                  )}
                </div>

                <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('company_name')}
                  </div>
                  <Input
                    type="text"
                    value={company}
                    onchange={(value) => {
                      setCompany(value);
                    }}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                  />
                </div>

                <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('province')} *
                  </div>
                  <Select
                    value={province}
                    onchange={(value) => {
                      setProvince(value);
                      setError({ ...error, province: "" });
                    }}
                    options={provinceName}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D] px-[10px] text-[11px] md:text-[14px]"
                  />
                </div>

                <div className="mb-[15px]">
                  <div className="flex rounded-[20px] overflow-hidden">
                    <div
                      className={`w-[120px] lg:w-[180px] bg-[#00000033] ${
                        error.address ? "text-[#f00]" : "text-[#000]"
                      } p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right`}
                    >
                      {t('address')} *
                    </div>
                    <div className="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] flex bg-[#0000000D]">
                      <Textarea
                        value={address}
                        onchange={(value) => {
                          setAddress(value);
                          setAddressToggle(false);
                          setError({ ...error, address: "" });
                        }}
                        classes="w-full p-[15px] bg-transparent"
                      />
                      <span
                        className="h-full flex items-center p-[15px] cursor-pointer"
                        onClick={() => {
                          setAddressToggle(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} fontSize={22} />
                      </span>
                      <Dialog
                        open={addressToggle}
                        onClose={() => {
                          setAddressToggle(false);
                        }}
                        scroll="body"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="sh-blur"
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: "50%",
                            },
                          },
                        }}
                      >
                        <div className="bg-white p-[15px] rounded-[20px]">
                          <CartAddressCard
                            displayAdd={true}
                            addAddress={(value) => {
                              setAddress(value);
                              setAddressToggle(false);
                            }}
                          />
                        </div>
                      </Dialog>
                    </div>
                  </div>
                  {error.address && (
                    <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                      {error.address}
                    </div>
                  )}
                </div>

                <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('postal_code')}
                  </div>
                  <Input
                    type="text"
                    value={postal}
                    onchange={(value) => {
                      setPostal(value);
                    }}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                  />
                </div>

                <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('tel')}
                  </div>
                  <Input
                    type="text"
                    value={tel}
                    onchange={(value) => {
                      setTel(value);
                    }}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                  />
                </div>

                <div className="flex rounded-[20px] overflow-hidden mb-[15px]">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('mobile')}
                  </div>
                  <Input
                    type="text"
                    value={mobile}
                    onchange={(value) => {
                      setMobile(value);
                    }}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                  />
                </div>

                <div className="mb-[15px]">
                  <div className="flex rounded-[20px] overflow-hidden">
                    <div
                      className={`w-[120px] lg:w-[180px] bg-[#00000033] ${
                        error.email ? "text-[#f00]" : "text-[#000]"
                      } p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right`}
                    >
                      {t('email')} *
                    </div>
                    <Input
                      type="text"
                      value={email}
                      onchange={(value) => {
                        setEmail(value);
                        setError({ ...error, email: "" });
                      }}
                      classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                    />
                  </div>
                  {error.email && (
                    <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                      {error.email}
                    </div>
                  )}
                </div>

                <div className="flex rounded-[20px] overflow-hidden">
                  <div className="w-[120px] lg:w-[180px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                    {t('purchase_note')}
                  </div>
                  <Textarea
                    value={note}
                    onchange={(value) => {
                      setNote(value);
                    }}
                    classes="w-[calc(100%-120px)] lg:w-[calc(100%-180px)] p-[15px] bg-[#0000000D]"
                  />
                </div>
              </form>
              {error.default && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.default}
                </div>
              )}
            </div>
            <div className="w-full md:w-[50%] lg:w-[40%]">
              {cart && cart.length > 0 ? (
                <>
                  {cart.map((item, index) => {
                    return <CartCheckoutItemCard item={item} key={index} />;
                  })}

                  <div className="bg-[#fff] p-[10px] rounded-[20px]">
                    <div className="flex rounded-[20px] overflow-hidden">
                      <div className="w-[150px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] lg:text-[14px] ltr:text-left rtl:text-right">
                        {t('discount_code')}
                      </div>
                      <div className="w-[calc(100%-150px)] flex items-center bg-[#0000000D]">
                        <Input
                          type="text"
                          value={discountCode}
                          onchange={(value) => {
                            setDiscountCode(value);
                          }}
                          classes="w-full p-[15px] bg-[#0000000D]"
                        />
                        <span
                          className="bg-[#0000000D] h-full flex items-center p-[15px] cursor-pointer"
                          onClick={getDiscountCode}
                        >
                          {discountLoader === 1 ? (
                            <ClipLoader color="#000" size="15px" />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} fontSize={22} />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] my-[15px]"></div>
                    <div className="flex items-center mb-[15px]">
                      <div className="w-[25%]">{t('sub_total')}</div>
                      <div className="w-[75%] flex justify-end text-[#0BC154]">
                        <Price price={totalPrice} />
                      </div>
                    </div>
                    {discount.amount > 0 && (
                      <div className="flex items-center mb-[15px]">
                        <div className="w-[25%] flex">{t('discount')}</div>
                        <div className="w-[75%] flex justify-end text-[#0BC154]">
                          <span
                            className="text-[18px] w-[25px] h-[25px] rtl:ml-[15px] ltr:mr-[15px] flex items-center justify-center cursor-pointer bg-[#000] shadow rounded-full"
                            onClick={(event) => {
                              setDiscount({
                                type: 0,
                                amount: 0,
                                code: "",
                                min_price: 0,
                                max_price: 0,
                              });
                              setDiscountCode("");
                              setError(...error, { discount: "" });
                            }}
                          >
                            <img src={TrashIcon} alt="delete discount" />
                          </span>
                          {discount.type === 1 ? (
                            <>
                              <Price price={discount.amount} />%
                            </>
                          ) : (
                            <Price price={discount.amount} />
                          )}
                        </div>
                      </div>
                    )}

                    <div className="flex items-center mb-[15px]">
                      <div className="w-[25%] font-[700]">{t('total')}</div>
                      <div className="w-[75%] flex justify-end text-[#0BC154] font-[700] text-[18px]">
                        <Price
                          price={
                            totalPrice -
                            calculateDiscount(discount, totalPrice) +
                            (taxes
                              ? taxes?.reduce(
                                  (total, tax) =>
                                    tax.type === 1
                                      ? (total =
                                          total +
                                          ((totalPrice -
                                            calculateDiscount(
                                              discount,
                                              totalPrice
                                            )) *
                                            tax.amount) /
                                            100)
                                      : tax.amount,
                                  0
                                )
                              : 0)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <FormButton
                    onclick={addOrder}
                    classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px] inline-block mt-[15px]"
                  >
                    {loader === 1 && (
                      <ClipLoader
                        color="#ffffff"
                        size="15px"
                        className="rtl:ml-[10px] ltr:mr-[10px]"
                      />
                    )}
                    {t('purchase')}
                  </FormButton>
                </>
              ) : (
                <>
                  <div>{t("mahsuli_baraye_pardakht_nist")}</div>
                  <Link to="/shop" className="button inline-block mt-[15px]">
                    {t("back_to_store")}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
