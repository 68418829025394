import { Link } from "react-router-dom";
import BellIcon from "../../../assets/img/bell_notification_icon.svg";
import TrashIcon from "../../../assets/img/trash_white_icon.svg";
import AppContext from "../../context/context";
import { useContext } from "react";

export default function NotificationItemCard(props) {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const item = props.item ? props.item : null;

  async function deleteNotification() {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/deletenotification`, {
        method: "POST",
        body: JSON.stringify({
          id: item.id,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.notifications) {
            props.setNotifications(data.notifications);
            if (data.notifications.length < 3) {
              props.setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
            props.setNotifications(null);
            if (data.notifications.length < 3) {
              props.setPage(1);
            }
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }

  return item && (
    <div className="p-[10px] bg-[#FFD954] rounded-[20px] ltr:text-left rtl:text-right text-[11px] md:text-[14px]">
      <div className="flex items-center">
        <div className="w-[90%] flex items-center text-[20px] font-[600]">
          <img src={BellIcon} alt="notification" className="ltr:mr-[5px] rtl:ml-[5px]" />{" "}
          {item.title}
        </div>
        <div className="w-[10%] flex items-center justify-end">
          <span
            className="bg-[#0000004D] p-[5px] rounded-full cursor-pointer"
            onClick={() => {
              deleteNotification();
            }}
          >
            <img src={TrashIcon} alt="delete" />
          </span>
        </div>
      </div>
      <div className="text-[14px] mb-[10px] text-[#00000080]">
        {item.sub_title}
      </div>
      <div className="text-[#000000B2] text-[10px] mb-[10px]">
        {item.content}
      </div>
      <div className="flex items-center">
        <div className="w-[60%]">
          <Link
            to={item.link}
            className="inline-block bg-[#00000080] rounded-[15px] p-[3px_8px] text-[#FFD954] text-[12px]"
          >
            {item.link_text}
          </Link>
        </div>
        <div className="flex items-center justify-end w-[40%] text-[#00000080] text-[10px]">
          {new Date(item.date).toLocaleString("en-EN", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </div>
      </div>
    </div>
  );
}
