import React from "react";
import { Link } from "react-router-dom";
import { ShopStoreIcon } from "../icons";
import { useTranslation } from "react-i18next";

export default function Breadcrumb(props) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex items-center">
      <div className="w-[80%] md:w-full flex items-center text-[10px] 2xl:text-[14px] text-[#4D4D4D]">
        <ShopStoreIcon className="w-[18px]" />
        <Link
          to="/"
          className="ltr:ml-[8.13px] rtl:mr-[8.13px] ltr:mr-[0.38rem] rtl:ml-[0.38rem] text-black uppercase"
        >
          {t("shawn")}
        </Link>
        {" > "}{" "}
        <Link to="/shop" className="mx-[0.38rem] text-black capitalize">
          {t("shop")}
        </Link>
        {" > "} {props.text}
      </div>
      <div className="w-[20%] md:w-full flex justify-end text-[10px] font-[500] text-[#4D4D4D]">
        {props.count && props.count > 0 && props.count + " " + t("items")}
      </div>
    </div>
  );
}
