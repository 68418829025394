import React from "react";
import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductDefaultImage from "../../../assets/img/product-placeholder.png";

export default function CartCheckoutItemCard(props) {
  const { t } = useTranslation();
  const item = props.item;
  return (
    <div className="rounded-[15px] shadow flex p-[10px] bg-[#fff] mb-[10px]">
      <div className="w-[25%] flex items-start justify-center pr-[10px] border-solid border-r-[1px] border-[#00000033]">
        <div className="border-solid border-[1px] border-[#00000033] p-[10%] rounded-[15px] w-full flex justify-center">
          <img
            src={
              item.images
                ? item.images.split(",")[0]
                : item.thumbnail
                ? item.thumbnail
                : ProductDefaultImage
            }
            alt={item.name}
            className="h-[70px]"
          />
        </div>
      </div>
      <div className="w-[75%] p-[0_10px_10px_10px]">
        <div className="flex items-center">
          <Link to={`/${item.slug}`} className=" w-full">
            <div className="font-[700] text-[18px]">{item.name}</div>
            {item.attribute_type === 3 && (
              <div className="text-[10px]">{item.attribute_name}</div>
            )}
          </Link>
        </div>
        {item && item.categories && (
          <div className="categories font-[700] text-[14px]">
            {JSON.parse(item.categories).map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <span className="mx-[5px]">-</span>}
                  <span className="inline-block text-[#000]">
                    {category.name}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        )}

        <div className="flex items-center mt-[10px] border-solid border-[1px] border-[#00000033] p-[5px_10px_5px_5px] rounded-[20px]">
          <div className="w-full ">
            <AddToCartButton attribute={item} text={t("add_plus")} deleteAll />
          </div>
          <div className="w-full flex justify-end text-[#0BC154] text-[18px] font-bold">
            <Price
              price={
                (item.sale_price ? item.sale_price : item.price) * item.count
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
