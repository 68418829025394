import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/context";
import FormButton from "../form-button/form-button";
import Input from "../input/input";
import { ClipLoader } from "react-spinners";

export default function ExistsNotification(props) {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(0);
  const { t } = useTranslation();

  const addNotificationList = async (id, mobile) => {
    setLoader(1);
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/product/addexitslist`, {
          method: "POST",
          body: JSON.stringify({
            aid: id,
          }),
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setLoader(0);
            if (data.status === 0) {
              //set error
            } else {
              if (data.status === 1) {
                enqueueSnackbar(
                  t("message_you_added_exists_notification"),
                  {
                    variant: "success",
                    vertical: "bottom",
                    horizontal: "left",
                  }
                );
              } else if (data.status === 2) {
                enqueueSnackbar(
                  t("message_you_already_exists_notification"),
                  {
                    variant: "error",
                    vertical: "bottom",
                    horizontal: "left",
                  }
                );
              }
            }
          });
      } else {
        await fetch(`${PUBLIC_URL}/api/product/addexitslist`, {
          method: "POST",
          body: JSON.stringify({
            aid: id,
            mobile: mobile,
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setLoader(0);
            setMobile("");
            setError("");
            if (data.status === 0) {
              //set error
            } else {
              if (data.status === 1) {
                enqueueSnackbar(
                  t("message_you_added_exists_notification"),
                  {
                    variant: "success",
                    vertical: "bottom",
                    horizontal: "left",
                  }
                );
              } else if (data.status === 2) {
                enqueueSnackbar(
                  t("message_you_already_exists_notification"),
                  {
                    variant: "error",
                    vertical: "bottom",
                    horizontal: "left",
                  }
                );
              }
            }
          });
      }
    } catch (error) {
      setLoader(0);
    }
  };

  return (
    <>
      <span
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          getToken()
            ? addNotificationList(props.attributeId)
            : setOpenModal(true);
        }}
        className="p-[3px_20px] text-[#fff] bg-[#000] rounded-[15px] cursor-pointer"
      >
        <NotificationsActiveIcon />{" "}
        <span className="rtl:mr-[5px] ltr:ml-[5px] text-[12px]">
          {props.buttonText ? props.buttonText : t('letme_know_if_available')}
        </span>
      </span>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "20px",
            p: 4,
          }}
        >
          <div className="text-[#000]">{t('letme_know_if_available')}</div>
          <Box
            sx={{ display: "flex", alignItems: "flex-end" }}
            className="w-full mt-[15px]"
          >
            <div className="mb-[15px]">
              <div className="flex rounded-[20px] overflow-hidden w-full">
                <div
                  className={`w-[280px] bg-[#00000033] ${
                    error ? "text-[#f00]" : "text-[#000]"
                  } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
                >
                  {t('email')} *
                </div>
                <Input
                  type="text"
                  value={mobile}
                  onchange={(value) => {
                    setMobile(value);
                    setError("");
                  }}
                  classes="w-full p-[15px] bg-[#0000000D]"
                />
              </div>
              {error.mobile && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.mobile}
                </div>
              )}
            </div>
          </Box>
          <div className="flex gap-[3px]">
            <FormButton
              onclick={() => {
                if (!mobile) {
                  setError(t("mobile_or_email_ra_vared_konid"));
                } else {
                  addNotificationList(props.attributeId, mobile);
                  setOpenModal(false);
                }
              }}
              classes="p-[15px] text-center upppercase bg-[#000] text-[#fff] w-full rounded-[20px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              <NotificationsActiveIcon />{" "}
              <span className="rtl:mr-[5px] ltr:ml-[5px]">{t('register')}</span>
            </FormButton>
            <FormButton
              onclick={() => {
                setOpenModal(false);
              }}
              classes="p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
            >
              {t('back')}
            </FormButton>
          </div>
          {error.default && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.default}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
