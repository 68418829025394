import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TimeCountDown(props) {
  const { t } = useTranslation();
  let refInstance = useRef(null);
  let [counter, setCountdown] = useState();
  let getCounter = (e) => {
    let all = Date.parse(e) - Date.parse(new Date());
    let s = Math.floor((all / 1000) % 60);
    let m = Math.floor((all / 1000 / 60) % 60);
    let h = Math.floor((all / 1000 / 60 / 60) % 24);
    let d = Math.floor(all / 1000 / 60 / 60 / 24);
    return {
      all,
      s,
      m,
      h,
      d,
    };
  };
  let initCounter = (e) => {
    let { all, h, m, s, d } = getCounter(e);
    if (all >= 0) {
      setCountdown(
        d +
          ":" +
          (h > 9 ? h : "0" + h) +
          ":" +
          (m > 9 ? m : "0" + m) +
          ":" +
          (s > 9 ? s : "0" + s)
      );
    }
  };
  let reset = (e) => {
    // setCountdown(props.date);
    if (refInstance.current) clearInterval(refInstance.current);
    let id = setInterval(() => {
      initCounter(e);
    }, 1000);
    refInstance.current = id;
  };
  let voidTime = () => {
    let voidZone = new Date(props.date);
    voidZone.setSeconds(voidZone.getSeconds());
    return voidZone;
  };
  useEffect(() => {
    reset(voidTime());
  }, []);

  return (
    <div className="rounded-[15px] overflow-hidden w-full md:w-auto">
      {counter && (
        <span className="flex gap-[15px] p-[5px]">
          <span
            className={`
            ${props.bg ? props.bg : "bg-[#E6E6E6]"}
             rounded-[15px] text-center w-full md:w-auto block md:inline-block drop-shadow overflow-hidden`}
          >
            <div
              className={`shadow text-[rgba(0,0,0,0.70)] w-full md:w-[70px] lg:w-[90px] 2xl:w-[105px] h-[95px] 2xl:h-[111px] flex items-center justify-center p-[12px_8px] text-[25px] md:text-[35px] lg:text-[45px] 2xl:text-[70px] font-semibold ${
                props.subbg ? props.subbg : ""
              }`}
            >
              {counter.split(":")[0]}
            </div>
            <div
              className={`text-[rgba(0,0,0,0.70)] p-[10px] text-[12px] md:text-[14px] 2xl:text-[18px]`}
            >
              {t("days")}
            </div>
          </span>
          <span
            className={`${
              props.bg ? props.bg : "bg-[#E6E6E6]"
            } rounded-[15px] text-center w-full md:w-auto block md:inline-block drop-shadow overflow-hidden`}
          >
            <div
              className={`shadow text-[rgba(0,0,0,0.70)] w-full md:w-[70px] lg:w-[90px] 2xl:w-[105px] h-[95px] 2xl:h-[111px] flex items-center justify-center p-[12px_8px] text-[25px] md:text-[35px] lg:text-[45px] 2xl:text-[70px] font-semibold ${
                props.subbg ? props.subbg : ""
              }`}
            >
              {counter.split(":")[1]}
            </div>
            <div className="text-[rgba(0,0,0,0.70)] p-[10px] text-[12px] md:text-[14px] 2xl:text-[18px]">
              {t("hours")}
            </div>
          </span>
          <span
            className={`${
              props.bg ? props.bg : "bg-[#E6E6E6]"
            } rounded-[15px] text-center w-full md:w-auto block md:inline-block drop-shadow overflow-hidden`}
          >
            <div
              className={`shadow text-[rgba(0,0,0,0.70)] w-full md:w-[70px] lg:w-[90px] 2xl:w-[105px] h-[95px] 2xl:h-[111px] flex items-center justify-center p-[12px_8px] text-[25px] md:text-[35px] lg:text-[45px] 2xl:text-[70px] font-semibold ${
                props.subbg ? props.subbg : ""
              }`}
            >
              {counter.split(":")[2]}
            </div>
            <div className="text-[rgba(0,0,0,0.70)] p-[10px] text-[12px] md:text-[14px] 2xl:text-[18px]">
              {t("minutes")}
            </div>
          </span>
          <span
            className={`${
              props.bg ? props.bg : "bg-[#E6E6E6]"
            } rounded-[15px] text-center w-full md:w-auto block md:inline-block drop-shadow overflow-hidden`}
          >
            <div
              className={`shadow text-[rgba(0,0,0,0.70)] w-full md:w-[70px] lg:w-[90px] 2xl:w-[105px] h-[95px] 2xl:h-[111px] flex items-center justify-center p-[12px_8px] text-[25px] md:text-[35px] lg:text-[45px] 2xl:text-[70px] font-semibold ${
                props.subbg ? props.subbg : ""
              }`}
            >
              {counter.split(":")[3]}
            </div>
            <div className="text-[rgba(0,0,0,0.70)] p-[10px] text-[12px] md:text-[14px] 2xl:text-[18px]">
              {t("seconds")}
            </div>
          </span>
        </span>
      )}
    </div>
  );
}
