import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/context";
import PostCard from "../post-card/post-card";
import MegaphoneIcon from "../../../assets/img/megaphone_icon.svg";
import PostSkeleton from "../skeleton/post/post-skeleton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MoreNewsIcon } from "../icons";

export default function PostLoader(props) {
  const [posts, setPosts] = useState([]);
  const { PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const currentLanguageRef = useRef("");
  const [loader, setLoader] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getPosts = useCallback(async () => {
    setPage(1)
    setLoader(1);

    let filter = "lng=" + currentLanguageRef.current;

    if (props.count) {
      filter = filter + "&count=" + props.count;
    }

    if (page > 0) {
      filter = filter + "&p=" + page;
    }

    filter = filter + "&count=4";

    try {
      await fetch(`${PUBLIC_URL}/api/post/postlist?` + filter)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setPosts(data.posts);
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
  }, [
    PUBLIC_URL,
    props.count,
    page
  ]);

  useEffect(() => {
    getPosts();
  }, [currentLanguage, getPosts, page]);

  return loader === 0 ? (
    posts && (
      <>
        <div className="post-list flex flex-wrap md:flex-nowrap gap-[10px]">
          {posts.map((item, index) => {
            return <PostCard post={item} key={index} />;
          })}
        </div>
        <div className="relative mt-[50px] flex items-center justify-center">
          <div className="bg-[rgba(30,30,30,0.50)] h-[1px] absolute top-[55%] left-0 w-full"></div>
          <Link
          to='/blog'
            className="inline-flex items-center text-[14px] 2xl:text-[18px] bg-[#000] text-[#fff] rounded-[100px] p-[7px_23px] z-[2] cursor-pointer"
          >
            <MoreNewsIcon />
            <span className="ml-[7.5px]">{t('more_articles')}</span>
          </Link>
        </div>
      </>
    )
  ) : (
    <div className="post-list flex gap-[25px]">
      <PostSkeleton />
    </div>
  );
}
