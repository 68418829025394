import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "../../context/context";
import Accordion from "../accordion/accordion";
import SearchBox from "../search-box/search-box";
import { Checkbox } from "@mui/material";

export default function ProductBrandFilter() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const [brands, setBrands] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const currentLanguageRef = useRef("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getBrands = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/get/brands?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.brands) {
            setBrands(data.brands);
            setBrandList(data.brands);
          }
        });
    } catch (error) {}
  }, []);

  useEffect(() => {
    getBrands();
  }, [getBrands, currentLanguage]);

  return (
    <Accordion title="Brand" expanded="1">
      <div className="mt-[15px]">
        <div className="max-h-[268px] overflow-auto">
          <SearchBox
            value={searchValue}
            changeHandler={(value) => {
              setSearchValue(value);
              setBrandList(
                brands.filter((element) =>
                  element.name.toLowerCase().includes(value.toLowerCase())
                )
              );
            }}
          />
          <div className="mt-[15px]">
            {filters.brand && brandList && (
              <>
                {brandList.map((item, index) => {
                  return (
                    <div
                      className="flex items-center leading-none mt-[5px]"
                      key={index}
                    >
                      <div className="w-full">{item.name}</div>
                      <div className="w-full flex justify-end">
                        <Checkbox
                          checked={filters.brand.find((element) => {
                            return element === item.id;
                          }) ? true : false}
                          onChange={(event) => {
                            if (event.target.checked === true) {
                              setFilters({
                                ...filters,
                                brand: [...filters.brand, item.id],
                              });
                            } else {
                              setFilters({
                                ...filters,
                                brand: filters.brand.filter(
                                  (element) => element !== item.id
                                ),
                              });
                            }
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                            color: "#603085",
                            padding: "0",
                            "&.Mui-checked": {
                              color: "#603085",
                            },
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </Accordion>
  );
}
