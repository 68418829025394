import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useRef } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Banner from "./components/banner/banner";
import ProductCategoryList from "./components/category-list/category-list";
import Footer from "./layout/footer";
import { useParams } from "react-router-dom";

export default function Ask() {
  const { currentLanguage } = useContext(AppContext);
  let { slug } = useParams();
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <ProductCategoryList />
        <div className="text-center text-[25px] md:text-[45px] lg:text-[55px] 2xl:text-[70px] font-bold leading-[40px] mb-[15px]">
          {slug == 1
            ? t("outdoor_shop")
            : slug == 2
            ? t("dog_walker")
            : slug == 3
            ? t("treatment")
            : t("learning_center")}
        </div>
        <ProductCategoryList />
      </main>
      <Footer />
    </>
  );
}
