import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function ResetPasswordCard(props) {
  const { PUBLIC_URL } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    email: "",
    default: "",
  });
  const { t } = useTranslation();

  async function registerReset() {
    try {
      if (!email) {
        setError({
          ...error,
          email: t("message_enter_email"),
        });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/reset`, {
        method: "POST",
        body: JSON.stringify({
          username: email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.status === 1) {
            enqueueSnackbar(t("message_request_sent"), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            props.settoggle(0);
          } else if (data.status === 2) {
            setError({
              ...error,
              email: t("message_enter_email"),
            });
          } else {
            setError({ ...error, default: t("message_proccess_error") });
          }
        });
    } catch (error) {
      setLoader(0);
    }
  }

  return (
    <div className="">
      <form>
        <div className="mb-[15px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[380px] bg-[#00000033] ${
                error.email ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("username_email")} *
            </div>
            <Input
              type="text"
              value={email}
              onchange={(value) => {
                setEmail(value);
                setError({ ...error, email: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.email && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.email}</div>
          )}
        </div>
        <FormButton
          onclick={() => {
            registerReset();
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t("reset")}
        </FormButton>
      </form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.default}</div>
      )}
    </div>
  );
}
