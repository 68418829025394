import { useTranslation } from "react-i18next";
import ProductTasteFilter from "../components/product-taste-filter/product-taste-filter";
import ProductTopFilter from "../components/product-top-filter/product-top-filter";

export default function RightSidebar() {
  const { t } = useTranslation();
  return (
    <aside className="">
      <section className="shadow rounded-0 ltr:md:rounded-[20px_0_0_20px] rtl:md:rounded-[0_20px_20px_0] p-[15px] bg-white mb-[15px]">
        <ProductTasteFilter />        
      </section>

      <section className="p-[15px]">
        <ProductTopFilter />        
      </section>
    </aside>
  );
}
