import { Pagination, Stack } from "@mui/material";

export default function ShopPagination(props) {
  return (
    <div className="mx-auto flex justify-center">
      <Stack spacing={2}>
        <Pagination
          size="medium"
          count={props.pagesCount}
          variant="outlined"
          color="dark"
          page={props.page}
          onChange={props.handleChange}
          sx={{
            "& .MuiButtonBase-root": {
              background: "transparent",
              color: "#000",
              fontSize: "15px",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
            },
            "& .MuiButtonBase-root.Mui-selected,& .MuiButtonBase-root:hover": {
              background: "#000",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </div>
  );
}
