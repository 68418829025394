import React, { useCallback, useEffect, useRef } from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import ShopPagination from "../pagination/pagination";
import PurchaseItemCard from "../purchase-item-card/purchase-item-card";
import { useTranslation } from "react-i18next";

export default function PurchaseCard() {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const getOrders = useCallback(async () => {
    try {
      // setProductsLoader(1)
      await fetch(`${PUBLIC_URL}/api/order/orderslist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          // data.orders.forEach((element) => {
          //   element.details = JSON.parse(element.details);
          //   element.discount = JSON.parse(element.discount);
          //   element.taxes = element.tax ? JSON.parse(element.tax) : "";
          //   element.totalPrice = element.details.reduce(
          //     (total, product) =>
          //       (total = total + product.count * product.unit_price),
          //     0
          //   );
          // });
          setOrders(data.orders)
          // setOrders(
          //   data.map((order) => {
          //     return {
          //       ...order,
          //       date: new Date(order.date).toLocaleString("en-EN", {
          //         year: "numeric",
          //         month: "numeric",
          //         day: "numeric",
          //         hour: "2-digit",
          //         minute: "2-digit",
          //       }),
          //       state:
          //         order.state === 0
          //           ? "Not purchased"
          //           : order.state === 1
          //           ? "New"
          //           : order.state === 2
          //           ? "Progressing"
          //           : order.state === 3
          //           ? "Shipping"
          //           : order.state === 4
          //           ? "Sent"
          //           : order.state === 5
          //           ? "Deliveried"
          //           : "",
          //       total:
          //         order.discount &&
          //         order.totalPrice &&
          //         order.totalPrice -
          //           calculateDiscount(order.discount, order.totalPrice) +
          //           (order.taxes
          //             ? order.taxes?.reduce(
          //                 (total, tax) =>
          //                   tax.type === 1
          //                     ? (total =
          //                         total +
          //                         ((order.totalPrice -
          //                           calculateDiscount(
          //                             order.discount,
          //                             order.totalPrice
          //                           )) *
          //                           tax.amount) /
          //                           100)
          //                     : tax.amount,
          //                 0
          //               )
          //             : 0),
          //     };
          //   })
          // );
          // setProductsLoader(2)
        });
    } catch (error) {
      // setProductsLoader(2)
    }
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <div className="">
      {orders && orders.length>0 ? ( 
        <>
          {orders.map((item, index) => {
            if ((page - 1) * 3 <= index && index < page * 3) {
              return <PurchaseItemCard item={item} key={index} />;
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
          {orders.length > 3 && (
            <div className="w-full">
              <ShopPagination
                pagesCount={
                  orders.length % 3 === 0
                    ? orders.length / 3
                    : Math.floor(orders.length / 3) + 1
                }
                page={page}
                handleChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </>
      ):(
        <div>{t("message_no_orders")}</div>
      )}
    </div>
  );
}
