import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.png";

export default function SiteLogo() {
  const { t } = useTranslation();
  return (
    <div className="w-[50%] lg:w-[11.24%]">
      <Link to="/" className="flex items-center justify-start">
        <img src={logo} alt={t("website_name")} className="block w-[78px]" />
      </Link>
    </div>
  );
}
