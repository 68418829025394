import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AskCard(props) {
  const { t } = useTranslation();
  return (
    <div className="bg-[#E9E9E9] rounded-[20px] overflow-hidden">
      <div style={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: 210
      }}>
        
      </div>

      <div className="p-[5px_12px_17px_10px]">
        <div className="font-[600] text-[18px] md:text-[24px] 2xl:text-[32px] text-black">
          {props.title}
        </div>
        <div className="flex">
          <div className="text-[14px] 2xl:text-[18px] text-[#4B4B4B] w-[calc(100%-100px)]">{props.description}</div>
          <div className="flex items-end justify-end w-[100px]">
            <Link
              to={props.link}
              className="inline-block rounded-[100px] bg-[#000000] text-white text-[14px] 2xl:text-[18px] hover:bg-[#666666] p-[4px_12px]"
            >
              More >
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
