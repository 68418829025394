import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { Rating } from "@mui/material";
import { useTranslation } from "react-i18next";
import Textarea from "../textarea/textarea";
import ReviewItemCard from "../review-item-card/review-item-card";
import ShopPagination from "../pagination/pagination";

export default function ReviewCard(props) {
  const product = props.product;
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState({
    default: "",
    rate: "",
    comment: "",
  });

  const addComment = async () => {
    try {
      if (!comment) {
        setErrorForm({
          ...errorForm,
          comment: t("message_enter_comment"),
        });
        return;
      }
      setLoader(1);
      if (rate > 0 && rate < 6 && comment) {
        await fetch(`${PUBLIC_URL}/api/product/comment`, {
          method: "POST",
          body: JSON.stringify({
            pid: product.id,
            rate: rate,
            comment: comment,
            parentId: 0,
          }),
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setLoader(0);
            if (data.status === 1) {
              setRate(0);
              // setParentId({ id: 0, name: '' })
              setComment("");
              // setMessage(t('nazare_shoma_ba_movafaghiyat_sabt_shod'))
            } else {
              // set
              setErrorForm({
                ...errorForm,
                default: t("message_proccess_error"),
              });
            }
          });
      }
    } catch (error) {
      setLoader(0);
      setErrorForm({ ...errorForm, default: t("message_proccess_error") });
    }
  };

  return (
    <div className="">
      {props.reviews && (
        <div className="w-full mt-[5px] mb-[6px]">
          <div className="w-full">
            {props.reviews.map((review, index) => {
              if ((page - 1) * 3 <= index && index < page * 3) {
                return <ReviewItemCard item={review} key={index} />;
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
          </div>
          {props.reviews.length > 3 && (
            <div className="w-full">
              <ShopPagination
                pagesCount={
                  props.reviews.length % 3 === 0
                    ? props.reviews.length / 3
                    : Math.floor(props.reviews.length / 3) + 1
                }
                page={page}
                handleChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </div>
      )}

      <form className="border-solid border-[0.5px] border-[rgba(0,0,0,0.25)] rounded-[20px] shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] p-[17px_20px] bg-white">
        <div className="w-full">
          <div className="flex items-center text-black text-[14px] 2xl:text-[18px] uppercase ltr:text-left rtl:text-right">
            {t("you")}:{" "}
            <Rating
              name="simple-controlled"
              size={window.innerWidth > 991 ? "large" : "medium"}
              value={rate}
              onChange={(event, newValue) => {
                setRate(newValue);
                setErrorForm({ ...errorForm, rate: "" });
              }}
              sx={{
                color: "rgba(255, 217, 84, 1)",
                marginLeft: "15px",
              }}
            />
          </div>

          <div className="mt-[2px] overflow-hidden">
            <Textarea
              value={comment}
              onchange={(value) => {
                setComment(value);
                setErrorForm({ ...errorForm, comment: "" });
              }}
              classes="w-full"
              placeholder={t("message_enter_comment")}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <FormButton
            onclick={addComment}
            classes="p-[6px_16px] text-center uppercase bg-black text-white text-[18px] 2xl:text-[24px] rounded-[20px]"
          >
            {loader === 1 && (
              <ClipLoader
                color="#ffffff"
                size="15px"
                className="rtl:ml-[10px] ltr:mr-[10px]"
              />
            )}
            {loader === 1 && (
              <ClipLoader
                color="#ffffff"
                size="15px"
                className="rtl:ml-[10px] ltr:mr-[10px]"
              />
            )}
            <span>{t("send")}</span>
          </FormButton>
        </div>
      </form>
    </div>
  );
}
