import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import AddressCard from "../address-card/address-card";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function ProfileCard(props) {
  const { PUBLIC_URL, getToken, getCustomer, logout } = useContext(AppContext);
  const customer = getCustomer().customer;
  const [name, setName] = useState(customer.name);
  const [lastName, setLastName] = useState(customer.last_name);
  const [birthdate, setBirthdate] = useState(customer.birthdate);
  const [mobile, setMobile] = useState(customer.mobile);
  const [email, setEmail] = useState(customer.email);
  const [username, setUsername] = useState(customer.username);
  const [loader, setLoader] = useState(0);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    default: "",
    name: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    currentPassword: "",
    confirmPassword: "",
  });
  const { t } = useTranslation();

  async function changeHandler() {
    try {
      if (!currentPassword) {
        setError({
          ...error,
          currentPassword: t("message_enter_current_password"),
        });
        return;
      }

      if (!password) {
        setError({
          ...error,
          password: t("message_enter_new_password"),
        });
        return;
      }

      if (!confirmPassword) {
        setError({
          ...error,
          confirmPassword: t("message_enter_confirm_password"),
        });
        return;
      }

      if (password !== confirmPassword) {
        setError({
          ...error,
          confirmPassword: t("message_passwords_not_match"),
        });
        return;
      }

      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/changepassword`, {
        method: "POST",
        body: JSON.stringify({
          currentPassword: currentPassword,
          password: password,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.token) {
            localStorage.setItem("token", data.token);
            setChangePasswordPage(false);
            enqueueSnackbar(t("message_passwords_changed"), {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          } else if (data.status === 2) {
            setError({
              ...error,
              default: t("message_enter_required_fields"),
            });
          }
        });
    } catch (error) {
      setError({
        ...error,
        default: t("message_proccess_error"),
      });
      setLoader(0);
    }
  }

  async function editHandler() {
    try {
      if (!name) {
        setError({ ...error, name: t("message_enter_name") });
        return;
      }
      if (!lastName) {
        setError({ ...error, lastName: t("message_enter_last_name") });
        return;
      }
      if (!username) {
        setError({ ...error, username: t("message_enter_username") });
        return;
      }
      if (!email) {
        setError({ ...error, email: t("message_enter_email") });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/edit`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          lastName: lastName,
          birthdate: birthdate,
          username: username,
          mobile: mobile,
          email: email,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.token) {
            localStorage.setItem("token", data.token);
          } else if (data.mobile) {
            localStorage.removeItem("token");
          } else {
            // setError({ ...error, default: t('amaliyat_namovafagh') })
          }
        });
    } catch (error) {
      // setError({ ...error, default: t('khata_dar_amaliyat') })
      setLoader(0);
    }
  }

  return changePasswordPage ? (
    <div className="">
      <form>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[280px] bg-[#00000033] ${
                error.currentPassword ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("current_password")} *
            </div>
            <Input
              type="password"
              value={currentPassword}
              onchange={(value) => {
                setCurrentPassword(value);
                setError({ ...error, currentPassword: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.currentPassword && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.currentPassword}
            </div>
          )}
        </div>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[280px] bg-[#00000033] ${
                error.password ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("password")} *
            </div>
            <Input
              type="password"
              value={password}
              onchange={(value) => {
                setPassword(value);
                setError({ ...error, password: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.password && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.password}
            </div>
          )}
        </div>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[280px] bg-[#00000033] ${
                error.confirmPassword ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("confirm_password")} *
            </div>
            <Input
              type="password"
              value={confirmPassword}
              onchange={(value) => {
                setConfirmPassword(value);
                setError({ ...error, confirmPassword: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.confirmPassword && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.confirmPassword}
            </div>
          )}
        </div>
        <FormButton
          onclick={() => {
            changeHandler();
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t("change")}
        </FormButton>
        <FormButton
          onclick={() => {
            setChangePasswordPage(false);
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
        >
          {t("back")}
        </FormButton>
      </form>
    </div>
  ) : (
    <div className="">
      <form>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[200px] bg-[#00000033] ${
                error.name ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("name")} *
            </div>
            <Input
              type="text"
              value={name}
              onchange={(value) => {
                setName(value);
                setError({ ...error, name: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.name && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.name}</div>
          )}
        </div>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[200px] bg-[#00000033] ${
                error.lastName ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("last_name")} *
            </div>
            <Input
              type="text"
              value={lastName}
              onchange={(value) => {
                setLastName(value);
                setError({ ...error, lastName: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.lastName && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.lastName}
            </div>
          )}
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t("birth")}
          </div>
          <Input
            type="date"
            value={birthdate}
            onchange={(value) => {
              setBirthdate(value);
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t("mobile")}
          </div>
          <Input
            type="text"
            value={mobile}
            onchange={(value) => {
              setMobile(value);
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[200px] bg-[#00000033] ${
                error.email ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("email")} *
            </div>
            <Input
              type="text"
              value={email}
              onchange={(value) => {
                setEmail(value);
                setError({ ...error, email: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.email && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.email}</div>
          )}
        </div>
        <div className="mb-[10px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[200px] bg-[#00000033] ${
                error.username ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t("username")} *
            </div>
            <Input
              type="text"
              value={username}
              onchange={(value) => {
                setUsername(value);
                setError({ ...error, username: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.username && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.username}
            </div>
          )}
        </div>
        <FormButton
          onclick={() => {
            editHandler();
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t("edit")}
        </FormButton>
        <FormButton
          onclick={() => {
            setChangePasswordPage(true);
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
        >
          {t("change_password")}
        </FormButton>
        <FormButton
          onclick={() => {
            logout();
            props.settoggle(0);
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#000] text-[#fff] w-full rounded-[20px]"
        >
          {t("logout")}
        </FormButton>
      </form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.default}</div>
      )}
      <AddressCard />
    </div>
  );
}
