import React from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import ShopPagination from "../pagination/pagination";
import PurchaseItemCard from "../purchase-item-card/purchase-item-card";
import { useTranslation } from "react-i18next";

export default function ShopFilterItem(props) {
  const { filters, setFilters } =
    useContext(AppContext);
  return (
    <span
      className={`inline-block ${
        filters.filter === props.filter_id
          ? "bg-[#603085] text-[#fff]"
          : "bg-[#D9D9D9] text-[#000]"
      } hover:bg-[#603085] hover:text-[#fff] cursor-pointer p-[6px_13px] rounded-[10px] text-[12px] lowercase leading-normal`}
      onClick={() => {
        setFilters({
          ...filters,
          filter: props.filter_id,
        });
      }}
    >
      {props.name}
    </span>
  );
}
