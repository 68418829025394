import React, { useCallback, useEffect } from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import ShopPagination from "../pagination/pagination";
import NotificationItemCard from "../notification-item-card/notification-item-card";
import { useTranslation } from "react-i18next";

export default function NotificationCard() {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const getNotifications = useCallback(async () => {
    try {
      // setProductsLoader(1)
      await fetch(`${PUBLIC_URL}/api/get/notificationlist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.notifications) {
            setNotifications(data.notifications);
          }
        });
    } catch (error) {
      // setProductsLoader(2)
    }
  }, []);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <div className="">
      {notifications && notifications.length > 0 ? (
        <>
          {notifications.map((item, index) => {
            if ((page - 1) * 3 <= index && index < page * 3) {
              return (
                <NotificationItemCard
                  item={item}
                  setNotifications={setNotifications}
                  setPage={setPage}
                  key={index}
                />
              );
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
          {notifications.length > 3 && (
            <div className="w-full">
              <ShopPagination
                pagesCount={
                  notifications.length % 3 === 0
                    ? notifications.length / 3
                    : Math.floor(notifications.length / 3) + 1
                }
                page={page}
                handleChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div>{t("message_no_notifications")}</div>
      )}
    </div>
  );
}
