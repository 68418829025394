import { useTranslation } from "react-i18next";
import banner_1 from "../../../assets/img/banner-bg-1.jpg";
import { Link } from "react-router-dom";

export default function Banner() {
  const { t } = useTranslation();
  return (
    <div className="bg-black">
      <div className="relative flex flex-wrap lg:flex-nowrap items-center justify-center max-w-[95%] lg:max-w-[70%] 2xl:max-w-[52.44%] mx-auto">
        <img src={banner_1} alt="" className="w-full md:w-[50%] 2xl:w-[1007px] h-auto 2xl:h-[982px]" />
        <div className="relative lg:absolute left-0 bottom-0 lg:bottom-[50px] 2xl:bottom-[179px] p-[10px_15px] md:py-[60px] lg:py-0 md:px-[15px] lg:px-[27px] w-full">
          <div className="flex flex-wrap lg:flex-nowrap items-center lg:items-end justify-center lg:justify-start">
            <div className="order-2 lg:order-1 w-full lg:w-[calc(100%-209px)] 2xl:w-[calc(100%-309px)] flex items-end justify-center lg:justify-start">
              <div className="text-white">
                <h1 className="text-[25px] md:text-[33px] lg:text-[40px] 2xl:text-[54px] font-[700] uppercase m-0 text-center lg:text-start">
                  {t("website_name")}
                </h1>
                <div className="text-[14px] 2xl:text-[18px] text-center lg:text-start">{t("website_description")}</div>
                <div className="mt-[15px] mb-[13px] flex items-center gap-[7px] justify-center lg:justify-start">
                  <Link
                    to="/shop"
                    className="inline-block text-[14px] 2xl:text-[18px] p-[8px_36px] rounded-[10px] lg:rounded-[100px] bg-white text-[#272727] hover:bg-[#666666] hover:text-white"
                  >
                    {t("products")}
                  </Link>
                  <Link
                    to="/blog"
                    className="inline-block text-[14px] 2xl:text-[18px] p-[8px_36px] rounded-[10px] lg:rounded-[100px] text-white bg-[#666666] hover:bg-white hover:text-black"
                  >
                    {t("articles")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="order-1 lg:order-2 w-full lg:w-[209px] 2xl:w-[309px] flex justify-center">
              <div className="text-white text-[35px] md:text-[50px] lg:text-[60px] 2xl:text-[90px] text-center ltr:lg:text-start rtl:lg:text-end uppercase font-[900] w-full leading-[30px] md:leading-[60px] lg:leading-normal">
                {t("trust_the_tilt")}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute bottom-0 left-0 w-full flex items-center p-[10px_3%]">
          <div className="w-full flex gap-[15px] justify-center md:justify-start">
            <a
              href="https://www.facebook.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={facebook_icon} alt="facebook" className="w-[25px]" />
            </a>
            <a
              href="https://x.com/shawnpet96252?s=11"
              rel="noopener noreferrer"
            >
              <img src={twitter_icon} alt="twitter" className="w-[25px]" />
            </a>
            <a
              href="https://www.instagram.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={instagram_icon} alt="instagram" className="w-[25px]" />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}
