import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../context/context";
import AddressItemCard from "../address-item-card/address-item-card";
import FormButton from "../form-button/form-button";
import Input from "../input/input";
import Textarea from "../textarea/textarea";
import { ClipLoader } from "react-spinners";
import ShopPagination from "../pagination/pagination";
import { useTranslation } from "react-i18next";

export default function AddressCard() {
  const { PUBLIC_URL, getToken } = useContext(AppContext);
  const [addresses, setAddresses] = useState([]);
  const [addressName, setAddressName] = useState("");
  const [address, setAddress] = useState("");
  const [addressLoader, setAddressLoader] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState({
    addressName: "",
    address: "",
    default: "",
  });
  const { t } = useTranslation();

  const getAddress = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/addresslist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }, [PUBLIC_URL, getToken]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  async function addAddress() {
    try {
      if (!addressName) {
        setError({ ...error, addressName: t('message_enter_address_name') });
        return;
      }
      if (!address) {
        setError({ ...error, address: t('message_enter_address') });
        return;
      }

      setAddressLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/addaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressName: addressName,
          address: address,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setAddressLoader(0);
          if (data.addresses) {
            setAddressName("");
            setAddress("");
            setAddresses(data.addresses);
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
            setError({ ...error, default: t('message_proccess_error')});
          }
        });
    } catch (error) {
      setError({ ...error, default: t('message_proccess_error') });
      setAddressLoader(0);
    }
  }

  return (
    addresses && (
      <>
        <div className="mt-[10px] flex rounded-[20px] overflow-hidden">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('address')}
          </div>
          <div className="w-full bg-[#0000000D] p-[5px]">
            <div className="flex items-center">
              <span
                className={`inline-block w-[140px] ${
                  error.addressName ? "text-[#f00]" : "text-[#000]"
                } text-[11px] md:text-[14px]`}
              >
                {t('address_name')} *:{" "}
              </span>
              <Input
                type="text"
                value={addressName}
                onchange={(value) => {
                  setAddressName(value);
                  setError({...error,addressName:""})
                }}
                placeholder="Write address name"
                classes="w-[calc(100%-140px)] p-[10px] bg-transparent text-[11px] md:text-[14px]"
              />
            </div>
            <div className="w-full h-[1px] bg-[#BEBEBE]"></div>
            <Textarea
              value={address}
              onchange={(value) => {
                setAddress(value);
                setError({...error,address:""})
              }}
              placeholder={`${t('address_placeholder')} *`}
              classes={`w-full p-[10px] bg-transparent text-[11px] md:text-[14px]`}
            />
          </div>
        </div>
        {error.addressName && (
          <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
            {error.addressName}
          </div>
        )}
        {error.address && (
          <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
            {error.address}
          </div>
        )}
        {error.default && (
          <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px] w-full">
            {error.default}
          </div>
        )}
        <FormButton
          onclick={() => {
            addAddress();
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {addressLoader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t('add_address')}
        </FormButton>
        {addresses && (
          <div className="ltr:text-left rtl:text-right">
            {addresses.map((item, index) => {
              if ((page - 1) * 2 <= index && index < page * 2) {
                return (
                  <AddressItemCard
                    item={item}
                    key={index}
                    setAddresses={setAddresses}
                    setPage={setPage}
                  />
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
            {addresses.length > 2 && (
              <div className="w-full">
                <ShopPagination
                  pagesCount={
                    addresses.length % 2 === 0
                      ? addresses.length / 2
                      : Math.floor(addresses.length / 2) + 1
                  }
                  page={page}
                  handleChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  );
}
