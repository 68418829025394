import { Skeleton, Stack } from "@mui/material";

export default function CategorySkeleton(props) {
  return Array.apply(0, Array(props.count ? props.count : 4)).map((index) => {
    return (
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: '100%'
        }}
        key={index}
      >
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={60} sx={{ fontSize: "1rem" }} />
      </Stack>
    );
  });
}
