import React, { useCallback, useEffect, useRef } from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import Textarea from "../textarea/textarea";
import PetItemCard from "../pet-item-card/pet-item-card";
import ShopPagination from "../pagination/pagination";
import { useTranslation } from "react-i18next";

export default function PetProfileCard() {
  const { getToken, PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const [name, setName] = useState("");
  const [type, setType] = useState(1);
  const [breed, setBreed] = useState(0);
  const [birthdate, setBirthdate] = useState("");
  const [interests, setInterests] = useState("");
  const [gender, setGender] = useState(0);
  const [loader, setLoader] = useState(0);
  const [pets, setPets] = useState();
  const [petTypes, setPetTypes] = useState();
  const [petBreeds, setPetBreeds] = useState();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const currentLanguageRef = useRef("");
  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getPets = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/pets`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.pets) {
            setPets(data.pets);
          } else {
            setPets(null);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL, getToken]);

  useEffect(() => {
    getPets();
  }, [getPets]);

  const getPetTypes = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/get/pettypes?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.types) {
            setPetTypes(data.types);
          }
        });
    } catch (error) {}
  }, []);

  useEffect(() => {
    getPetTypes();
  }, [currentLanguage]);

  const getPetBreeds = useCallback(async () => {
    try {
      if (type > 0) {
        await fetch(`${PUBLIC_URL}/api/get/petbreeds?pt=${type}`)
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            if (data.breeds) {
              setPetBreeds(data.breeds);
            }
          });
      } else {
        setPetBreeds([]);
      }
    } catch (error) {}
  }, [type]);

  useEffect(() => {
    getPetBreeds();
  }, [type]);

  async function addPet() {
    try {
      if (!name) {
        // setError({ ...error, default: t('form_ra_por_konid') })
        setLoader(0);
        return;
      }
      await fetch(`${PUBLIC_URL}/api/customer/addpet`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          type: type,
          breed: breed,
          birthdate: birthdate,
          gender: gender,
          interests: interests,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.pets) {
            // enqueueSnackbar(t('moshakhasate_heyvanat_sabt_shod'), {
            //   variant: 'success',
            //   vertical: 'bottom',
            //   horizontal: 'left',
            // })
            // getPets()
            // setPetModalOpen(false)
            // setError('')
            // setPetName('')
            // setPetID(0)
            // setPetAge(0)
            // setPetType(1)
            // setPetBreed(1)
            setPets(data.pets)
          } else if (data.status === 2) {
            // setError({ ...error, default: t('nam_eshtebah_ast') })
          } else if (data.status === 3) {
            // setError({ ...error, default: t('heyvane_khanegi_vojud_darad') })
          } else {
            // setError({ ...error, default: t('khata_dar_sabt') })
          }
        });
    } catch (error) {
      // setError({ ...error, default: t('khata_dar_amaliyat') })
      setLoader(0);
    }
  }

  return (
    <div className="">
      <form>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('pet_name')}
          </div>
          <Input
            type="text"
            value={name}
            onchange={(value) => {
              setName(value);
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('pet_species')}
          </div>
          {petTypes && (
            <select
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
              className="w-full p-[15px] bg-[#0000000D] px-[10px] text-[11px] md:text-[14px]"
            >
              {petTypes.map((type, index) => {
                return (
                  <option key={index} value={type.id}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('breed_type')}
          </div>
          {petBreeds && (
            <select
              value={breed}
              onChange={(event) => {
                setBreed(event.target.value);
              }}
              className="w-full p-[15px] bg-[#0000000D] text-[11px] md:text-[14px]"
            >
              {petBreeds.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('birth')}
          </div>
          <Input
            type="date"
            value={birthdate}
            onchange={(value) => {
              setBirthdate(value);
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('gender')}
          </div>
          <select
            value={gender}
            onChange={(event) => {
              setGender(event.target.value);
            }}
            className="w-full p-[15px] bg-[#0000000D] text-[11px] md:text-[14px]"
          >
            <option value="0">{t('male')}</option>
            <option value="1">{t('female')}</option>
          </select>
        </div>
        <div className="flex rounded-[20px] overflow-hidden mb-[10px]">
          <div className="w-[200px] bg-[#00000033] text-[#000] p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right">
            {t('pet_interests')}
          </div>
          <Textarea
            value={interests}
            onchange={(value) => {
              setInterests(value);
            }}
            classes="w-full p-[15px] bg-[#0000000D]"
          />
        </div>

        <FormButton
          onclick={() => {
            addPet();
          }}
          classes="p-[15px] text-center upppercase bg-[#603085] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          + {t('add')}
        </FormButton>
      </form>
      {/* {error && <div className="mt-[15] text-[#FF547D]">{error}</div>} */}
      {pets && (
        <>
          {pets.map((item, index) => {
            if ((page - 1) * 2 <= index && index < page * 2) {
              return (
                <PetItemCard
                  item={item}
                  key={index}
                  setPets={setPets}
                  setPage={setPage}
                />
              );
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
          {pets.length > 2 && (
            <div className="w-full">
              <ShopPagination
                pagesCount={
                  pets.length % 2 === 0
                    ? pets.length / 2
                    : Math.floor(pets.length / 2) + 1
                }
                page={page}
                handleChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
