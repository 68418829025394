import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/context";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CategorySkeleton from "../skeleton/category/category-skeleton";

export default function ProductCategoryList() {
  const { WEBSITE_URL, PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);
  const getCategories = useCallback(async () => {
    setLoader(true);
    try {
      await fetch(
        `${PUBLIC_URL}/api/product/categorieslist?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setCategories(data);
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  }, [PUBLIC_URL]);

  useEffect(() => {
    getCategories();
  }, [currentLanguage, getCategories]);

  return (
    <div className="block">
      <div className="flex flex-wrap md:flex-nowrap items-center justify-center">
        {loader === false ? (
          categories && categories.length > 0 ? (
            categories.map((category, index) => {
              return (
                <Link
                  to={`/shop?c=${category.id}`}
                  className={`inline-block w-[50%] md:w-full rounded-[20px] border-solid border-[1px] ${
                    filters.categoryId === category.id
                      ? "border-[#603085]"
                      : "border-transparent"
                  }`}
                  key={index}
                  onClick={() => {
                    if (
                      filters.categoryId === 0 ||
                      category.id !== filters.categoryId
                    ) {
                      setFilters({
                        ...filters,
                        categoryId: category.id,
                      });
                    } else {
                      setFilters({
                        ...filters,
                        categoryId: 0,
                      });
                    }
                  }}
                >
                  {category.thumbnail && (
                    <div className="flex justify-center">
                      <img
                        src={`${WEBSITE_URL}/media/${category.thumbnail}`}
                        alt={category.name}
                        className="w-[40px] 2xl:w-[60px] h-[40px] 2xl:h-[60px] flex"
                      />
                    </div>
                  )}
                  <div className="flex justify-center text-center text-[14px] 2xl:text-[18px] mt-[22.82px] uppercase">{category.name}</div>
                </Link>
              );
            })
          ) : (
            <>{t("no_category")}</>
          )
        ) : (
          <div className="flex">
            <CategorySkeleton count={5} />
          </div>
        )}
      </div>
    </div>
  );
}
