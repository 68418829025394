import { Route, Routes } from 'react-router-dom'
import Home from './view/home'
import { AppContextProvider } from './view/context/context'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Shop from './view/shop'
import Product from './view/product'
import Cart from './view/cart'
import Checkout from './view/checkout'
import Blog from './view/blog'
import Post from './view/post'
import AboutUs from './view/about-us'
import ContactUs from './view/contact-us'
import ResetPassword from './view/reset'
import Ask from './view/ask'
import Service from './view/service'
import BankPayment from './view/bankpayment'
import BankConfirm from './view/bankconfirm'

export default function Store() {
  const { i18n } = useTranslation()
  document.body.style.direction = i18n.dir()
  document.body.classList.remove('rtl')
  document.body.classList.remove('ltr')
  document.body.classList.add(i18n.dir())
  document.documentElement.style.setProperty('font-family',i18next.language === 'ar'?'"Shabnam", sans-serif':'Inter');
  const theme = createTheme({
    direction: i18next.language === 'ar' ? 'rtl' : 'ltr',
    typography: {
      fontFamily: i18next.language === 'ar' ? ['sans-serif'].join(',') : ['Inter'].join(','),
    },
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    palette: {
      primary: {
        light: '#e9ecf4',
        main: '#234090',
        dark: '#2c3333',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e9ecf4',
        main: '#ec4899',
        dark: '#2c3333',
        contrastText: '#fff',
      },
      purple: {
        light: '#603085',
        main: '#603085',
        dark: '#603085',
        contrastText: '#fff',
      },
      light: {
        light: '#ccc',
        main: '#666666',
        dark: '#2c3333',
        contrastText: '#fff',
      },
      dark: {
        light: '#000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff',
      }
    },
  })
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  })

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
  })

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: i18next.language,
          dir: i18next.language === 'ar' ? 'rtl' : 'ltr',
        }}
      />

      <CacheProvider value={i18next.language === 'ar' ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={theme}>
          <AppContextProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/shop/:slug" element={<Shop />} />
              <Route path="/product/:slug" element={<Product />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<Blog />} />
              <Route path="/post/:slug" element={<Post />} />
              <Route path="/ask/:slug" element={<Ask />} />
              <Route path="/service/:slug" element={<Service />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/reset/:slug" element={<ResetPassword />} />
              <Route path="/bank/:id" element={<BankPayment />} />
              <Route path="/payment-confirm" element={<BankConfirm />} />
              <Route path="/panel/*" />
            </Routes>
          </AppContextProvider>
        </ThemeProvider>
      </CacheProvider>
    </div>
  )
}
