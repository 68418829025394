export default function ProductFAQItemCard(props) {
  const faq = props.item ? props.item : null;

  return (
    <div className="rounded-[15px] shadow p-[5px] bg-[#0000000D] mb-[10px]">
      <div className="flex items-center">
        <div className="bg-[#0000000D] inline-block rounded-[15px] p-[3px_15px] ltr:mr-[5px] rtl:ml-[5px]">
          {faq.question}
        </div>
      </div>
      <div className="bg-[#0000000D] rounded-[15px] p-[3px_15px] mt-[5px]">
        {faq.answer}
      </div>
    </div>
  );
}
