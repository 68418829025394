import { useTranslation } from "react-i18next";
import banner_1 from "../../../assets/img/carot.jpg";
import facebook_icon from "../../../assets/img/facebook.png";
import instagram_icon from "../../../assets/img/instagram.png";
import twitter_icon from "../../../assets/img/twitter.png";
import linkedin_icon from "../../../assets/img/linkedin.png";
import { Link } from "react-router-dom";

export default function BannerAbout() {
  const { t } = useTranslation();
  return (
    <div className="bg-black flex items-center justify-center p-[0_10%] relative">
      <img src={banner_1} alt="" className="w-full md:w-[50%]" />
      <div className="absolute p-[10px_15px] md:p-[10px_15px] lg:p-[10px_3%] w-full">
        <div className="flex flex-wrap md:flex-nowrap items-center">
          <div className="order-2 md:order-1 w-full md:w-[50%] flex justify-center">
            <div className="text-white">
              <div className="text-[25px] md:text-[33px] lg:text-[40px] uppercase">
                {t("website_name")}
              </div>
              <div className="text-[16px]">{t("website_description")}</div>
              <div className="mt-[30px] md:mt-[60px]">
                <Link
                  to="/shop"
                  className="inline-block p-[8px_36px] rounded-[5px] bg-white text-black hover:bg-[#666666] hover:text-white"
                >
                  {t("products")}
                </Link>
                <Link
                  to="/blog"
                  className="inline-block p-[8px_36px] rounded-[5px] text-white bg-[#666666] hover:bg-white hover:text-black ltr:ml-3 rtl:mr-3"
                >
                  {t("articles")}
                </Link>
              </div>
            </div>
          </div>
          <div className="order-1 md:order-2 w-full md:w-[50%] flex justify-center">
            <div className="text-white text-[35px] md:text-[50px] lg:text-[96px] text-center ltr:md:text-left rtl:md:text-right uppercase font-[900] w-full md:w-[60%] lg:w-[150px] leading-[30px] md:leading-[60px] lg:leading-[116px]">
              {t("trust_the_tilt")}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full flex items-center p-[10px_3%]">
        <div className="w-full flex gap-[15px] justify-center md:justify-start">
          <a
            href="https://www.facebook.com/shawn.uae"
            rel="noopener noreferrer"
          >
            <img src={facebook_icon} alt="facebook" className="w-[25px]" />
          </a>
          <a href="https://x.com/shawnpet96252?s=11" rel="noopener noreferrer">
            <img src={twitter_icon} alt="twitter" className="w-[25px]" />
          </a>
          <a
            href="https://www.instagram.com/shawn.uae"
            rel="noopener noreferrer"
          >
            <img src={instagram_icon} alt="instagram" className="w-[25px]" />
          </a>
        </div>
      </div>
    </div>
  );
}
