import FilterIcon from "../../assets/img/filter_icon.svg";
import TrashIcon from "../../assets/img/trash_icon.svg";
import Switcher from "../components/switcher/switcher";
import { useContext } from "react";
import AppContext from "../context/context";
import SearchBox from "../components/search-box/search-box";
import { useTranslation } from "react-i18next";

export default function LeftSidebarPost() {
  const { filters, setFilters, getToken } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <aside className="mr-[15px]">
      <section className="shadow ltr:rounded-[0_20px_20px_0] rtl:rounded-[20px_0_0_20px] p-[15px] bg-white">
        <div className="flex items-center">
          <div className="w-full flex">
            <img src={FilterIcon} alt="" className="ltr:mr-[5px] rtl:ml-[5px]" /> {t('filters')}
          </div>
          {/* <div className="w-full flex justify-end text-[11px] text-[#808080]">
            <img src={TrashIcon} alt="" className="mr-[5px]" /> Remove filters
          </div> */}
        </div>
        <div className="bg-[#9c9c9c] h-[1px] m-[10px_0]"></div>
        <div className="mt-[15px]">
          <SearchBox
            value={filters.postSearchTerm}
            changeHandler={(value) => {
              setFilters({ ...filters, postSearchTerm: value });
            }}
          />
        </div>
        {getToken() && (
          <div className="flex items-center mt-[15px]">
            <div className="w-[80%] text-[13px]">{t('for_your_pet')}</div>
            <div className="w-[20%] flex justify-end">
              <Switcher
                checked={filters.postYourPet}
                changeHandler={(value) => {
                  setFilters({
                    ...filters,
                    postYourPet: value,
                  });
                }}
              />
            </div>
          </div>
        )}
      </section>
    </aside>
  );
}
