import { useContext, useRef, useState } from "react";
import AppContext from "../context/context";
import { useEffect } from "react";
import ProductSearch from "../components/product-search/product_search";
import Menu from "../components/menu/menu/menu";
import MenuAccount from "../components/menu-account/menu_account";
import MenuCart from "../components/menu-cart/menu_cart";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "../../assets/img/close_icon.svg";
import LanguageSelect from "../components/language-select/language-select";
import MenuToggler from "../components/menu/menu-toggler/menu_toggler";
import SiteLogo from "../components/site-logo/site-logo";

export default function Header() {
  const { getToken, PUBLIC_URL, setCart, currentLanguage } =
    useContext(AppContext);
  const [menuToggle, setMenuToggle] = useState(false);
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  const getCartProducts = useCallback(async () => {
    if (getToken()) {
      try {
        await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setCart(data);
          });
      } catch (error) {}
    } else {
      try {
        const localCart = localStorage.getItem("cart");
        if (localStorage) {
          await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
            method: "POST",
            body: JSON.stringify({
              localcart: localCart,
            }),
          })
            .then((respose) => {
              if (respose.ok) {
                return respose.json();
              }
              throw new Error("error");
            })
            .then((data) => {
              setCart(data);
            });
        }
      } catch (error) {}
    }
  }, [PUBLIC_URL, getToken, setCart]);

  useEffect(() => {
    getCartProducts();
  }, [setCart, currentLanguage]);

  return window.innerWidth > 1023 ? (
    <header className="bg-[#000] py-[10px]">
      <div className="flex items-center max-w-[95%] lg:max-w-[85%] 2xl:max-w-[50.46%] mx-auto">
        <SiteLogo />

        <div className="w-0 lg:w-[88.76%] flex items-center justify-end gap-[10px] relative">
          <div className="flex w-[82.8%]">
            <Menu />
          </div>
          <div className="w-auto lg:w-[17.20%] flex items-center justify-end gap-[18.5px]">
            <ProductSearch />
            <MenuAccount />
            <MenuCart />
            <LanguageSelect />
          </div>
        </div>
      </div>
    </header>
  ) : (
    <header className="bg-[#000] py-[10px]">
      <div className="flex items-center max-w-[95%] mx-auto">
        <SiteLogo />

        <div
          className={`${
            menuToggle ? "block" : "hidden"
          } w-full h-full bg-[#000] p-[10px] fixed top-0 left-0 z-[99] overflow-y-auto`}
        >
          <div className="w-full flex justify-end">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] bg-[#fff] rounded-full cursor-pointer"
              onClick={() => {
                setMenuToggle(false);
              }}
            />
          </div>
          <Menu />
        </div>
        <MenuToggler onClick={() => setMenuToggle(true)} />
      </div>
    </header>
  );
}
