import { Link } from "react-router-dom";

export default function MenuItem(props) {
  return (
    <div className="w-full lg:w-[25%]">
      <Link
        to={`/shop`}
        className={`text-[#fff] leading-normal hover:bg-[#000D] text-[1rem] p-[5px_10px_5px_0] inline-block w-full cursor-pointer`}
        onClick={props.onClick}
      >
        {props.item.title}
      </Link>
    </div>
  );
}
