import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../context/context";
import ProductSearchItem from "../product-search-item/product-search-item";
import { ClickAwayListener } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/img/close_icon.svg";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../icons";

export default function ProductSearch() {
  const { PUBLIC_URL, filters, setFilters } = useContext(AppContext);
  // const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [products, setProducts] = useState([]);
  const [boxActive, setBoxActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getProducts = useCallback(async () => {
    // setLoader(true);
    try {
      // setProductsLoader(1)

      let filter = "";

      if (filters.searchTerm) {
        if (!filter) {
          filter = filter + "term=" + filters.searchTerm;
        } else {
          filter = filter + "&term=" + filters.searchTerm;
        }
      }

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          // setProductsCount(data.count);
          setProducts(data.products);

          // setLoader(false);
        });
    } catch (error) {
      // setProductsLoader(2)
    }
  }, [filters, PUBLIC_URL]);

  useEffect(() => {
    if (filters.searchTerm.length > 0) {
      const timer = setTimeout(() => {
        setProducts([]);
        getProducts();
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setProducts([]);
      // setProductsCount(0);
    }
  }, [filters, setFilters, getProducts]);

  return (
    <>
      <span
        onClick={() => {
          setToggle(true);
        }}
        className="inline-block cursor-pointer"
      >
        <SearchIcon />
      </span>
      <div
        className={`${
          toggle ? "block" : "hidden"
        } flex fixed top-0 left-0 z-[999999] bg-[rgba(0,0,0,0.70)] backdrop-blur-[25px] w-full h-full items-start justify-center`}
      >
        <div className="w-full flex justify-end absolute top-[20px] right-[20px] p-[5px]">
          <span className="bg-[#000] text-[#fff] rounded-full">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] bg-[#fff] rounded-full cursor-pointer"
              onClick={() => {
                setToggle(false);
                setFilters({ ...filters, searchTerm: "" });
              }}
            />
          </span>
        </div>
        <div className="w-[48.34%] mt-[43px] relative">
          <form className="flex items-center px-[3px] border-b-[1px] border-solid border-[#fff]">
            <input
              value={filters.searchTerm}
              onChange={(event) => {
                setFilters({ ...filters, searchTerm: event.target.value });
              }}
              onClick={() => {
                setBoxActive(true);
              }}
              placeholder={t("search")}
              className="w-[calc(100%-21.809px)] bg-transparent text-[14px] lg:text-[24px] 2xl:text-[32px] text-white"
            />
            <span
              onClick={() => {
                navigate("/shop");
                setToggle(false);
              }}
              className="w-[21.809px] cursor-pointer"
            >
              <SearchIcon className="w-[18px] 2xl:w-[21.809px] h-[20px] 2xl:h-[30px]" />
            </span>
          </form>
          <div className="text-white text-[14px] lg:text-[18px] 2xl:text-[24px] mb-[9px]">
            {t("pet_supplies_store")}
          </div>
          {products && products.length > 0 && boxActive && (
            <ClickAwayListener
              onClickAway={() => {
                // setFilters({ ...filters, searchTerm: "" });
                setBoxActive(false);
              }}
            >
              <div className="max-h-[75vh] overflow-y-auto">
                {products.map((product, index) => {
                  return <ProductSearchItem item={product} key={index} />;
                })}
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
      {/* <div
        className={`${
          toggle ? "block" : "hidden"
        } lg:flex fixed lg:relative top-0 left-0 z-[999999] lg:z-0 bg-[#000] lg:bg-transparent w-full h-full px-[15px] lg:p-0 items-center justify-center`}
      >
        <div className="flex lg:hidden items-center bg-[#000] p-[5px] text-[#fff] ltr:rounded-[15px_15px_0_0] rtl:rounded-[0_0_15px_15px]">
          <div className="w-full flex justify-end">
            <img
              src={CloseIcon}
              alt=""
              className="p-[5px] bg-[#fff] rounded-full cursor-pointer"
              onClick={() => {
                setToggle(false);
              }}
            />
          </div>
        </div>
        <form className="flex items-center px-[3px] border-b-[1px] border-solid border-[#fff8] mt-[30px] lg:mt-0 w-full">
          <input
            value={filters.searchTerm}
            onChange={(event) => {
              setFilters({ ...filters, searchTerm: event.target.value });
            }}
            onClick={() => {
              setBoxActive(true);
            }}
            placeholder={t('search')}
            className="w-[calc(100%-18px)] p-[5px] bg-transparent text-white"
          />
          <span
            onClick={() => {
              navigate("/shop");
              setToggle(false);
            }}
            className="w-[20px]"
          >
            <img src={search_icon} alt="" className="w-[20px] cursor-pointer" />
          </span>
        </form>
        {products && products.length > 0 && boxActive && (
          <ClickAwayListener
            onClickAway={() => {
              // setFilters({ ...filters, searchTerm: "" });
              setBoxActive(false);
            }}
          >
            <div className="hidden lg:block absolute bg-[#fff] shadow rounded-[20px] p-[5px] z-[2] min-w-[300px] ltr:right-0 rtl:left-0 max-h-[273px] overflow-y-auto">
              {products.map((product, index) => {
                return <ProductSearchItem item={product} key={index} />;
              })}
            </div>
          </ClickAwayListener>
        )}
      </div> */}
    </>
  );
}
