import ShippingIcon from "../../assets/img/shipping_icon.svg";
import QualityIcon from "../../assets/img/quality_icon.svg";
import PaymentIcon from "../../assets/img/payment_icon.svg";
import ResponseIcon from "../../assets/img/response_icon.svg";
import InstagramIcon from "../../assets/img/instagram_icon.svg";
import TwitterIcon from "../../assets/img/twitter_icon.svg";
import { useTranslation } from "react-i18next";
import { FacebookWhiteIcon } from "../components/icons";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="bg-[#000] text-[#fff] p-[22px_15px_65px_15px] lg:p-[22px_11.19%_65px_11.19%]">
      <div className="flex flex-wrap lg:flex-nowrap items-center">
        <div className="w-full md:w-[50%] text-[14px] 2xl:text-[16px] text-white flex items-center">
          <img
            src={ShippingIcon}
            alt=""
            className="w-[50px] h-[50px] ltr:mr-[20px] rtl:ml-[20px]"
          />{" "}
          {t("shipping_all_parts_country")}
        </div>
        <div className="w-full md:w-[50%] text-[14px] 2xl:text-[16px] text-white flex items-center justify-start lg:justify-center">
          <img
            src={QualityIcon}
            alt=""
            className="w-[50px] h-[50px] ltr:mr-[20px] rtl:ml-[20px]"
          />{" "}
          {t("product_quality_assurance")}
        </div>
        <div className="w-full md:w-[50%] text-[14px] 2xl:text-[16px] text-white flex items-center justify-start lg:justify-center">
          <img
            src={PaymentIcon}
            alt=""
            className="w-[50px] h-[50px] ltr:mr-[20px] rtl:ml-[20px]"
          />{" "}
          {t("money_back_guarantee")}
        </div>
        <div className="w-full md:w-[50%] text-[14px] 2xl:text-[16px] text-white flex items-center justify-start lg:justify-end">
          <img
            src={ResponseIcon}
            alt=""
            className="w-[50px] h-[50px] ltr:mr-[20px] rtl:ml-[20px]"
          />{" "}
          {t("excellent_response")}
        </div>
      </div>
      <div className="bg-[#404040] h-[2px] m-[23px_0_42px_0]"></div>
      <div className="flex flex-wrap md:flex-nowrap">
        <div className="w-full text-[16px] 2xl:text-[20px] text-white">
          {t("company_name")} : SHAWN TRADING CO L.L.C <br />
          {t("tel")} : <a href="tel:00971506372879">+971506372879</a>
          <br />
          {t("web")} : www.shawnpet.com
          <br />
          {t("email")} : <a href="mailto:info@shawn.ae">info@shawnpet.com</a>
          <br />
          {t("copyright")}
        </div>
        <div className="text-start md:text-end w-full text-[16px] 2xl:text-[20px] text-white">
          {t("license_no")} : 1133371 <br />
          {t("register_no")} : 1872714
          <div className="flex items-center justify-start md:justify-end gap-[15px] mt-[22px]">
            <a
              href="https://www.facebook.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <FacebookWhiteIcon />
            </a>
            <a
              href="https://x.com/shawnpet96252?s=11"
              rel="noopener noreferrer"
            >
              <img src={TwitterIcon} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
