import { BarsIcon } from "../../icons";

export default function MenuToggler(props) {
  return (
    window.innerWidth < 1024 && (
      <div className="w-[50%] lg:w-[33.333333%] flex lg:inline-block items-center justify-end">
        <span onClick={props.onClick} className="cursor-pointer">
          <BarsIcon />
        </span>
      </div>
    )
  );
}
