import { useContext } from "react";
import AppContext from "../../context/context";
import { NumericFormat } from "react-number-format";

export default function Price(props) {
  const { currentCurrency } = useContext(AppContext);
  return (
    props.price && (
      <>
        {currentCurrency.place === "before" && currentCurrency.text}
        <NumericFormat
          value={props.price.toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
        />
        {currentCurrency.place === "after" && " " + currentCurrency.text}
      </>
    )
  );
}
