import React, { useEffect } from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../input/input";
import FormButton from "../form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import ConfirmCard from "../confirm-card/confirm-card";
import { useTranslation } from "react-i18next";

export default function LoginCard(props) {
  const { login } = useContext(AppContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(0);
  const [result, setResult] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState({
    username: "",
    password: "",
    default: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (result) {
      setLoader(0);
      if (result === 1) {
        enqueueSnackbar(t('message_you_loggedin'), {
          variant: "success",
          vertical: "bottom",
          horizontal: "left",
        });
        //login success => close dialog
        props.settoggle(0);
      } else if (result.status === 2) {
        //account not activated
      } else if (result === 0 || result === 3) {
        enqueueSnackbar(t('message_proccess_error'), {
          variant: "error",
          vertical: "bottom",
          horizontal: "left",
        });
        //error
      }
    }
  }, [result, setResult, props]);

  return result && result.status === 2 ? (
    <ConfirmCard email={result.email} settoggle={props.settoggle} />
  ) : (
    <div className="">
      <form>
        <div className="mb-[15px]">
          <div className="flex rounded-[20px] overflow-hidden ">
            <div
              className={`w-[280px] bg-[#00000033] ${
                error.username ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t('username_email')} *
            </div>
            <Input
              type="text"
              value={username}
              onchange={(value) => {
                setUsername(value);
                setError({ ...error, username: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.username && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.username}
            </div>
          )}
        </div>
        <div className="mb-[15px]">
          <div className="flex rounded-[20px] overflow-hidden">
            <div
              className={`w-[280px] bg-[#00000033] ${
                error.password ? "text-[#f00]" : "text-[#000]"
              } p-[15px] uppercase text-[11px] md:text-[14px] ltr:text-left rtl:text-right`}
            >
              {t('password')} *
            </div>
            <Input
              type="password"
              value={password}
              onchange={(value) => {
                setPassword(value);
                setError({ ...error, password: "" });
              }}
              classes="w-full p-[15px] bg-[#0000000D]"
            />
          </div>
          {error.password && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.password}
            </div>
          )}
        </div>
        <FormButton
          onclick={() => {
            if (!username) {
              setError({ ...error, username: t('message_enter_username') });
              return;
            }
            if (!password) {
              setError({ ...error, password: t('message_enter_password') });
              return;
            }
            setLoader(1);
            login(username, password, setResult);
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          {t('login')}
        </FormButton>
      </form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">{error.default}</div>
      )}
    </div>
  );
}
