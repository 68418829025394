// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//   Autoplay,
//   EffectFade,
//   Keyboard,
//   Navigation,
//   Scrollbar,
//   Zoom,
// } from "swiper";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import "swiper/swiper-bundle.min.css";
// import { useRef } from "react";
import ProductCard from "../productcard/productcard";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronLeft,
//   faChevronRight,
// } from "@fortawesome/free-solid-svg-icons";

export default function RelatedProductsCarousel(props) {
  const products = props.related_products ? props.related_products : [];
  // const navigationPrevRef = useRef(null);
  // const navigationNextRef = useRef(null);

  return (
    <div className="flex flex-wrap lg:flex-nowrap gap-[10px]">
      {/* <div className="flex flex-wrap gap-[10px]">
        {products &&
          products.map((item, index) => {
            return (
              <div className="w-full md:w-[calc(25%-10px)]" key={index}>
                <ProductCard product={item} key={Math.random()} />
              </div>
            );
          })}
      </div> */}
      {/* <Swiper
        centeredSlides={false}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        modules={[
          Autoplay, Keyboard,
          Scrollbar,
          Zoom,
          EffectFade,
          Navigation,
        ]}
        grabCursor={true}
        loop={true}
        keyboard={true}
        spaceBetween={30}
        slidesPerView={4}
        initialSlide={4}
        // autoplay={{
        //   delay: 5000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // }}
        zoom={true}
        effect={"flip"}
        breakpoints={{
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          991: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        }}
      > */}
        {products &&
          products.map((product, index) => {
            return (
              // <SwiperSlide key={index}>
                <ProductCard product={product} key={index} />
              // </SwiperSlide>
            );
          })}
      {/* </Swiper>
      <div
        ref={navigationPrevRef}
        className="absolute z-10 top-0 ltr:right-[-50px] rtl:left-[-50px] h-full flex items-center justify-center cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-[40px] text-[#00000080]"
        />
      </div>
      <div
        ref={navigationNextRef}
        className="absolute z-10 top-0 ltr:left-[-50px] rtl:right-[-50px] h-full flex items-center justify-center cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-[40px] text-[#00000080]"
        />
      </div> */}
    </div>
  );
}
