export default function Icons() {
  return <></>;
}

export function SearchIcon(props) {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M10.9099 13.1582L11.8735 15.0854M10.9099 13.1582C13.8006 11.2311 15.0854 7.3767 13.1582 4.16474C11.2311 1.27397 7.3767 -0.0108178 4.16474 1.91636C1.27397 3.84354 -0.0108177 7.6979 1.91636 10.9099C3.84354 13.8006 7.6979 15.0854 10.9099 13.1582ZM4.16474 9.30388C3.20115 7.3767 3.84354 5.12833 5.44952 4.16474M11.8735 15.4066C12.5158 15.0854 13.1582 15.4066 13.4794 15.7278L15.4066 19.261C15.7278 19.9034 15.4066 20.5458 15.0854 20.867C14.443 21.1882 13.8006 20.867 13.4794 20.5458L11.5523 17.0126C11.2311 16.3702 11.5523 15.7278 11.8735 15.4066Z"
        stroke="white"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchBlackIcon(props) {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4324 10.1187L9.15509 11.5641M8.4324 10.1187C10.6005 8.6733 11.5641 5.78253 10.1187 3.37355C8.67329 1.20548 5.78253 0.241887 3.37355 1.68727C1.20548 3.13266 0.241887 6.02342 1.68727 8.4324C3.13266 10.6005 6.02342 11.5641 8.4324 10.1187ZM3.37355 7.22791C2.65086 5.78253 3.13266 4.09625 4.33714 3.37355M9.15509 11.805C9.63688 11.5641 10.1187 11.805 10.3596 12.0459L11.805 14.6957C12.0459 15.1775 11.805 15.6593 11.5641 15.9002C11.0823 16.1411 10.6005 15.9002 10.3596 15.6593L8.91419 13.0094C8.6733 12.5277 8.91419 12.0459 9.15509 11.805Z"
        stroke="#4D4D4D"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AccountIcon() {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.0001V19.7501C1 14.9176 4.91751 11.0001 9.75 11.0001C14.5825 11.0001 18.5 14.9176 18.5 19.7501V21.0001"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M9.75024 11C12.5116 11 14.7502 8.76138 14.7502 6C14.7502 3.23857 12.5116 1 9.75024 1C6.98882 1 4.75024 3.23857 4.75024 6C4.75024 8.76138 6.98882 11 9.75024 11Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CartIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 21C19.3284 21 20 20.3284 20 19.5C20 18.6716 19.3284 18 18.5 18C17.6716 18 17 18.6716 17 19.5C17 20.3284 17.6716 21 18.5 21Z"
        fill="black"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 21C9.3284 21 10 20.3284 10 19.5C10 18.6716 9.3284 18 8.5 18C7.67157 18 7 18.6716 7 19.5C7 20.3284 7.67157 21 8.5 21Z"
        fill="black"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 3H21L19 14H6L4 3ZM4 3C3.83333 2.33333 3 1 1 1"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14H6H4.23077C2.44646 14 1.5 14.7812 1.5 16C1.5 17.2188 2.44646 18 4.23077 18H18.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BarsIcon() {
  return (
    <svg
      width="60"
      height="44"
      viewBox="0 0 60 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60 3C60 4.38071 58.8807 5.5 57.5 5.5H2.5C1.11929 5.5 0 4.38071 0 3C0 1.61929 1.11929 0.5 2.5 0.5H57.5C58.8807 0.5 60 1.61929 60 3ZM60 21C60 22.3807 58.8807 23.5 57.5 23.5H8.5C7.11929 23.5 6 22.3807 6 21C6 19.6193 7.11929 18.5 8.5 18.5H57.5C58.8807 18.5 60 19.6193 60 21ZM12 41.4C12 42.7807 13.1193 43.9 14.5 43.9H57.5C58.8807 43.9 60 42.7807 60 41.4C60 40.0193 58.8807 38.9 57.5 38.9H14.5C13.1193 38.9 12 40.0193 12 41.4Z"
        fill="white"
      />
    </svg>
  );
}

export function ProductVariantIcon(props) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M11.5262 13.6316C15.0143 13.6316 17.842 10.8039 17.842 7.31579C17.842 3.82767 15.0143 1 11.5262 1C8.03812 1 5.21045 3.82767 5.21045 7.31579C5.21045 10.8039 8.03812 13.6316 11.5262 13.6316Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7367 21.0001C19.2248 21.0001 22.0525 18.1724 22.0525 14.6843C22.0525 11.1962 19.2248 8.36848 15.7367 8.36848C12.2486 8.36848 9.4209 11.1962 9.4209 14.6843C9.4209 18.1724 12.2486 21.0001 15.7367 21.0001Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31579 21.0001C10.8039 21.0001 13.6316 18.1724 13.6316 14.6843C13.6316 11.1962 10.8039 8.36848 7.31579 8.36848C3.82767 8.36848 1 11.1962 1 14.6843C1 18.1724 3.82767 21.0001 7.31579 21.0001Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ProductQuickViewIcon(props) {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M10.9099 13.1582L11.8735 15.0854M10.9099 13.1582C13.8006 11.2311 15.0854 7.3767 13.1582 4.16474C11.2311 1.27397 7.3767 -0.0108178 4.16474 1.91636C1.27397 3.84354 -0.0108176 7.6979 1.91636 10.9099C3.84354 13.8006 7.6979 15.0854 10.9099 13.1582ZM4.16474 9.30388C3.20115 7.3767 3.84354 5.12833 5.44952 4.16474M11.8735 15.4066C12.5158 15.0854 13.1582 15.4066 13.4794 15.7278L15.4066 19.261C15.7278 19.9034 15.4066 20.5458 15.0854 20.867C14.443 21.1882 13.8006 20.867 13.4794 20.5458L11.5523 17.0126C11.2311 16.3702 11.5523 15.7278 11.8735 15.4066Z"
        stroke="#7F7F7F"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ProductShareIcon(props) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M16 21C17.6569 21 19 19.6569 19 18C19 16.3431 17.6569 15 16 15C14.3431 15 13 16.3431 13 18C13 19.6569 14.3431 21 16 21Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4C13 5.65685 14.3431 7 16 7Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14C5.65685 14 7 12.6569 7 11C7 9.3431 5.65685 8 4 8C2.34315 8 1 9.3431 1 11C1 12.6569 2.34315 14 4 14Z"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.5 5.5L6.5 9.5" stroke="#7F7F7F" />
      <path d="M6.5 12.5L13.5 16.5" stroke="#7F7F7F" />
    </svg>
  );
}

export function ProductWishlistIcon(props) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M23.2222 7.51358C23.2222 9.23189 22.5625 10.8823 21.3842 12.1032C18.6721 14.9145 16.0416 17.8459 13.2281 20.5552C12.5832 21.1672 11.5602 21.1449 10.9431 20.5052L2.83751 12.1032C0.387497 9.56356 0.387497 5.46359 2.83751 2.92397C5.3116 0.359388 9.34216 0.359388 11.8162 2.92397L12.1109 3.22936L12.4053 2.92415C13.5916 1.69389 15.2071 1 16.8948 1C18.5825 1 20.1979 1.69382 21.3842 2.92397C22.5626 4.14495 23.2222 5.7953 23.2222 7.51358Z"
        stroke="#7F7F7F"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PetDiscountIcon() {
  return (
    <svg
      width="61"
      height="68"
      viewBox="0 0 61 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.0767 64.6216V55.7907C41.1921 54.3236 40.994 52.8485 40.4952 51.4639C39.9964 50.0793 39.2087 48.8165 38.1844 47.7599C47.846 46.6829 58 43.0214 58 26.2212C57.9991 21.9252 56.3467 17.794 53.3846 14.6826C54.787 10.9241 54.6879 6.76971 53.1076 3.08244C53.1076 3.08244 49.4768 2.00551 41.0767 7.63634C34.0243 5.72499 26.5904 5.72499 19.538 7.63634C11.1379 2.00551 7.5071 3.08244 7.5071 3.08244C5.92668 6.76971 5.82751 10.9241 7.23017 14.6826C4.2459 17.8171 2.59172 21.9856 2.61474 26.3135C2.61474 42.9906 12.7687 46.6522 22.4303 47.8522C21.418 48.8983 20.6373 50.1458 20.1389 51.5135C19.6406 52.8812 19.4358 54.3387 19.538 55.7907V64.6216"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SubscribeIcon() {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57153 5.28564L14.393 8.49993L19.2144 5.28564"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.14282 10.1069H5.28568"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.89307H5.28571"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28564 3.67857V3.14286C5.28564 1.95939 6.24503 1 7.4285 1H21.3571C22.5406 1 23.4999 1.95939 23.4999 3.14286V13.8571C23.4999 15.0406 22.5406 16 21.3571 16H7.4285C6.24503 16 5.28564 15.0406 5.28564 13.8571V13.3214"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function MoreNewsIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88674 10.3448V3.88171M9.88674 10.3448L14.8167 13.1619C15.1399 13.3466 15.5419 13.1133 15.5419 12.7411V1.48545C15.5419 1.11326 15.1399 0.879925 14.8167 1.06458L9.88674 3.88171M9.88674 10.3448H4.23154C2.44681 10.3448 1 8.89796 1 7.11326C1 5.32852 2.44681 3.88171 4.23154 3.88171H9.88674"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M4.84314 14.6266L4.23145 10.3447H7.46299L8.01009 14.1742C8.14775 15.1378 7.39997 15.9999 6.4266 15.9999C5.63059 15.9999 4.95572 15.4146 4.84314 14.6266Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function FooterBannerIcon() {
  return (
    <svg
      width="118"
      height="109"
      viewBox="0 0 118 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6189 43.4087V92.7904C9.6189 98.8512 14.532 103.764 20.5926 103.764H97.4086C103.469 103.764 108.382 98.8512 108.382 92.7904V43.4087"
        stroke="white"
        strokeWidth="10"
      />
      <path
        d="M74.5448 103.764V70.8428C74.5448 64.7821 69.6319 59.8691 63.5711 59.8691H52.5974C46.5369 59.8691 41.6238 64.7821 41.6238 70.8428V103.764"
        stroke="white"
        strokeWidth="10"
        strokeMiterlimit="16"
      />
      <path
        d="M112.872 39.9198L103.577 7.3882C103.174 5.9749 101.882 5.00049 100.412 5.00049H78.2046L80.8125 36.2962C80.8981 37.3204 81.4545 38.2456 82.3373 38.7718C84.4733 40.0449 88.6581 42.4043 91.9217 43.4085C97.4953 45.1231 105.643 44.5047 110.283 43.9341C112.213 43.6971 113.407 41.7893 112.872 39.9198Z"
        stroke="white"
        strokeWidth="10"
      />
      <path
        d="M69.9733 43.4085C73.0871 42.4504 77.0398 40.2584 79.251 38.9535C80.304 38.3324 80.8834 37.1591 80.7819 35.9411L78.2036 5.00049H39.7956L37.2172 35.9411C37.1157 37.1591 37.6954 38.3324 38.748 38.9535C40.9592 40.2584 44.9121 42.4504 48.0259 43.4085C56.2178 45.9291 61.7815 45.9291 69.9733 43.4085Z"
        stroke="white"
        strokeWidth="10"
      />
      <path
        d="M14.4226 7.3882L5.1278 39.9198C4.59365 41.7893 5.78699 43.6971 7.71672 43.9341C12.3567 44.5047 20.5052 45.1231 26.0785 43.4085C29.3419 42.4043 33.5269 40.0449 35.6628 38.7718C36.5456 38.2456 37.1023 37.3204 37.1877 36.2962L39.7956 5.00049H17.588C16.1181 5.00049 14.8264 5.9749 14.4226 7.3882Z"
        stroke="white"
        strokeWidth="10"
      />
    </svg>
  );
}

export function FacebookWhiteIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48253 0 0 4.4897 0 10.016C0 15.0327 3.69433 19.1845 8.50586 19.9084L9.02788 19.9876V12.095H6.66548V10.4926H9.02788V8.36475C9.02788 7.05444 9.34181 6.19145 9.86151 5.65001C10.3812 5.10857 11.1491 4.8355 12.2177 4.8355C13.0721 4.8355 13.4046 4.88735 13.7207 4.9262V6.23777H12.6083C11.9771 6.23777 11.4593 6.59049 11.1905 7.06029C10.9217 7.53009 10.8381 8.09103 10.8381 8.66264V10.4917H13.6213L13.3736 12.0941H10.8381V20L11.3539 19.9298C16.2338 19.2669 20 15.0818 20 10.016C20 4.4897 15.5175 0 10 0ZM10 0.910546C15.0262 0.910546 19.0909 4.9818 19.0909 10.016C19.0909 14.4457 15.9254 18.0888 11.7472 18.9089V13.0046H14.1531L14.6813 9.58118H11.7472V8.66264C11.7472 8.1903 11.8334 7.76709 11.9789 7.51289C12.1243 7.2587 12.2645 7.14832 12.6083 7.14832H14.6298V4.1019L14.2356 4.04855C13.963 4.0116 13.3405 3.92495 12.2177 3.92495C10.989 3.92495 9.93435 4.26019 9.20632 5.01867C8.47829 5.77715 8.11879 6.90655 8.11879 8.36475V9.58207H5.75639V13.0055H8.11879V18.8805C4.00772 18.0082 0.909091 14.3978 0.909091 10.016C0.909091 4.9818 4.97384 0.910546 10 0.910546Z"
        fill="white"
      />
    </svg>
  );
}

export function ShopStoreIcon(props) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M1.9353 8.77777V18.7778C1.9353 20.0051 2.93022 21 4.15753 21H19.7131C20.9404 21 21.9353 20.0051 21.9353 18.7778V8.77777"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M15.0832 21V14.3333C15.0832 13.106 14.0883 12.1111 12.8609 12.1111H10.6387C9.41143 12.1111 8.4165 13.106 8.4165 14.3333V21"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeMiterlimit="16"
      />
      <path
        d="M22.8446 8.07131L20.9623 1.48352C20.8806 1.19732 20.619 1 20.3213 1H15.8242L16.3523 7.33752C16.3697 7.54492 16.4823 7.73227 16.6611 7.83883C17.0937 8.09665 17.9411 8.57443 18.602 8.77778C19.7307 9.125 21.3807 8.99978 22.3203 8.88422C22.7111 8.83622 22.9528 8.44989 22.8446 8.07131Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M14.1574 8.77778C14.788 8.58376 15.5884 8.13988 16.0362 7.87563C16.2494 7.74984 16.3667 7.51226 16.3462 7.2656L15.8241 1H8.0463L7.52417 7.2656C7.50361 7.51226 7.621 7.74984 7.83414 7.87563C8.28193 8.13988 9.08241 8.58376 9.71297 8.77778C11.3719 9.28822 12.4985 9.28822 14.1574 8.77778Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
      <path
        d="M2.90811 1.48352L1.02588 8.07131C0.917713 8.44989 1.15937 8.83622 1.55015 8.88422C2.48977 8.99978 4.13986 9.125 5.26848 8.77778C5.92934 8.57443 6.77683 8.09665 7.20935 7.83883C7.38811 7.73227 7.50085 7.54492 7.51814 7.33752L8.04626 1H3.54912C3.25147 1 2.98988 1.19732 2.90811 1.48352Z"
        stroke="#4D4D4D"
        strokeWidth="2"
      />
    </svg>
  );
}

export function GaranteeIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.85446 1.11247C8.0271 0.962508 8.2838 0.962508 8.45644 1.11247L9.94669 2.40715C10.0406 2.48874 10.1636 2.5287 10.2875 2.5179L12.2542 2.34646C12.482 2.32659 12.6897 2.47748 12.7413 2.70028L13.1859 4.62367C13.214 4.74487 13.2899 4.8495 13.3965 4.91361L15.0884 5.93088C15.2844 6.04872 15.3637 6.29286 15.2744 6.50338L14.5037 8.32079C14.4551 8.43537 14.4551 8.56464 14.5037 8.67922L15.2744 10.4966C15.3637 10.7071 15.2844 10.9513 15.0884 11.0691L13.3965 12.0864C13.2899 12.1505 13.214 12.2551 13.1859 12.3764L12.7413 14.2997C12.6897 14.5225 12.482 14.6734 12.2542 14.6535L10.2875 14.4821C10.1636 14.4713 10.0406 14.5113 9.94669 14.5928L8.45644 15.8876C8.2838 16.0375 8.0271 16.0375 7.85446 15.8876L6.36418 14.5928C6.27027 14.5113 6.14727 14.4713 6.02334 14.4821L4.05667 14.6535C3.82886 14.6734 3.62119 14.5225 3.56968 14.2997L3.12499 12.3764C3.09698 12.2551 3.02095 12.1505 2.91434 12.0864L1.22251 11.0691C1.02653 10.9513 0.947202 10.7071 1.03649 10.4966L1.80728 8.67922C1.85584 8.56464 1.85584 8.43537 1.80728 8.32079L1.03649 6.50338C0.947202 6.29286 1.02653 6.04872 1.22251 5.93088L2.91434 4.91361C3.02095 4.8495 3.09698 4.74487 3.12499 4.62367L3.56968 2.70028C3.62119 2.47748 3.82886 2.32659 4.05667 2.34646L6.02334 2.5179C6.14727 2.5287 6.27027 2.48874 6.36418 2.40715L7.85446 1.11247Z"
        stroke="#0BC154"
      />
      <path
        d="M5.86108 9.26443L7.39088 10.7942L11.2154 6.96973"
        stroke="#0BC154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TestGaranteeIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_125_3343)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.48333 13.9667C3.90269 13.9667 1 11.064 1 7.48333C1 3.90269 3.90269 1 7.48333 1C11.064 1 13.9667 3.90269 13.9667 7.48333C13.9667 11.064 11.064 13.9667 7.48333 13.9667ZM0 7.48333C0 3.3504 3.3504 0 7.48333 0C11.6163 0 14.9667 3.3504 14.9667 7.48333C14.9667 11.6163 11.6163 14.9667 7.48333 14.9667C3.3504 14.9667 0 11.6163 0 7.48333ZM8.53995 9.93182L8.64648 2.09091H7.19762L7.30415 9.93182H8.53995ZM7.24556 12.8029C7.43377 12.9911 7.65927 13.0852 7.92205 13.0852C8.09961 13.0852 8.25941 13.0426 8.40146 12.9574C8.54705 12.8686 8.66246 12.7514 8.74769 12.6058C8.83647 12.4602 8.88086 12.3004 8.88086 12.1264C8.88086 11.8636 8.78675 11.6381 8.59854 11.4499C8.41033 11.2617 8.18484 11.1676 7.92205 11.1676C7.65927 11.1676 7.43377 11.2617 7.24556 11.4499C7.05735 11.6381 6.96325 11.8636 6.96325 12.1264C6.96325 12.3892 7.05735 12.6147 7.24556 12.8029Z"
          fill="#1E1E1E"
          fill-opacity="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_125_3343"
          x="-4"
          y="-4"
          width="22.9667"
          height="22.9668"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_125_3343"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_125_3343"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function LikeGreenIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7574 15.3301H1.49755C1.22276 15.3301 1 15.1073 1 14.8325V6.70582C1 6.431 1.22276 6.20827 1.49755 6.20827H3.79262C4.3752 6.20827 4.91505 5.9026 5.21478 5.40306L7.46239 1.65698C7.94742 0.848703 9.08972 0.771831 9.67858 1.50796C9.95613 1.85492 10.0456 2.31599 9.9179 2.74164L9.07007 5.56775C8.97429 5.88701 9.21336 6.20827 9.54664 6.20827H13.3406C14.4341 6.20827 15.2284 7.24815 14.9406 8.30313L13.3575 14.108C13.1607 14.8295 12.5053 15.3301 11.7574 15.3301Z"
        stroke="#17DADA"
        strokeLinecap="round"
      />
      <path
        d="M3.90234 6.20827V15.3301"
        stroke="#17DADA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function InvestIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8684 5.342C10.252 5.18222 9.39555 4.94727 8.70894 4.94727C5.32341 4.94727 2.57892 7.05327 2.57892 9.65119C2.57892 11.1441 3.48519 12.4745 4.89858 13.3363L4.51564 14.5993C4.42341 14.9035 4.65106 15.2104 4.96894 15.2104H6.36166C6.46419 15.2104 6.56397 15.1772 6.64598 15.1156L7.74697 14.2893H9.67084L10.7718 15.1156C10.8539 15.1772 10.9537 15.2104 11.0562 15.2104H12.4489C12.7668 15.2104 12.9944 14.9035 12.9022 14.5993L12.5193 13.3363C13.3613 12.8229 14.0234 12.1431 14.421 11.3617"
        stroke="#603085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8683 5.34193L14.4209 4.15771L14.3546 7.02216L15.9999 7.71035V10.4735L14.4794 11.263"
        stroke="#603085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6578 8.89494C11.4398 8.89494 11.2631 8.71818 11.2631 8.50021C11.2631 8.28223 11.4398 8.10547 11.6578 8.10547C11.8758 8.10547 12.0525 8.28223 12.0525 8.50021C12.0525 8.71818 11.8758 8.89494 11.6578 8.89494Z"
        fill="#603085"
        stroke="#603085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.52637C1 6.52637 1 8.4211 2.57895 8.89479"
        stroke="#603085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52689 4.75234C9.62873 4.45893 9.68415 4.14318 9.68415 3.81425C9.68415 2.25998 8.44705 1 6.92099 1C5.39494 1 4.15784 2.25998 4.15784 3.81425C4.15784 4.55767 4.44086 5.23375 4.90313 5.73684"
        stroke="#603085"
        strokeLinejoin="round"
      />
    </svg>
  );
}
