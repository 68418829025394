import { useCallback, useEffect } from "react";
import { useContext } from "react";
import { useRef } from "react";
import AppContext from "../../context/context";
import "./price-slider.scss";

import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Price from "../price/price";
import { useTranslation } from "react-i18next";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: "#603085",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "3px solid #603085",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "#000",
    },
    "& .MuiSlider-valueLabelCircle": {
      background: "#666",
      color: "#fff",
      padding: "3px 5px",
      borderRadius: "20px",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

export default function PriceSlider(props) {
  const { PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const max = useRef(0);
  const currentLanguageRef = useRef("");
  const { t } = useTranslation();

  const getMinMax = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/product/bound?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          max.current = data.max;
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
    getMinMax();
  }, [getMinMax, currentLanguage]);

  return (
    <>
      <div className="flex items-center">
        <div className="w-full">{t('from')}</div>
        <div className="w-full flex justify-end">{t('untile_the')}</div>
      </div>
      {max.current && (
        <IOSSlider
          aria-label="ios slider"
          defaultValue={filters.maxPrice > 0 ? filters.maxPrice : max.current}
          valueLabelDisplay="on"
          min={0}
          max={max.current}
          step={props.gapPrice ? props.gapPrice : 5}
          onChange={(event, newValue) => {
            if (typeof newValue === "number") {
              // setValue(newValue);
              setFilters({
                ...filters,
                maxPrice: newValue
              })
            }
          }}
        />
      )}
      <div className="flex items-center">
        <div className="w-full">
          <Price price={0} />
        </div>
        <div className="w-full flex justify-end">
          <Price price={max.current} />
        </div>
      </div>
    </>
  );
}
