import Header from "./layout/header";
import { Helmet } from "react-helmet";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Footer from "./layout/footer";
import { Link, useParams } from "react-router-dom";
import PackageCloseIcon from "../assets/img/close_package_icon.svg";
// import InvestIcon from "../assets/img/invest_icon.svg";
import DeliveryIcon from "../assets/img/delivery_icon.svg";
import { Box, Rating, Tab, Tabs, Typography } from "@mui/material";
import AddToCartButton from "./components/add-to-cart-button/add-to-cart-button";
import Price from "./components/price/price";
import CartCard from "./components/cart-card/cart-card";
import { styled } from "@mui/material/styles";
import ReviewCard from "./components/review-card/review-card";
import ProductFAQCard from "./components/product-faq-card/product-faq-card";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RelatedProductsCarousel from "./components/related-products-carousel/related-products-carousel";
import ProductDefaultImage from "../assets/img/product-placeholder.png";
import Banner from "./components/banner/banner";
import {
  GaranteeIcon,
  InvestIcon,
  LikeGreenIcon,
  ShopStoreIcon,
  TestGaranteeIcon,
} from "./components/icons";
import Breadcrumb from "./components/breadcrumb/breadcrumb";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  // "@media (max-width:767px)": {
  "& .MuiTabs-flexContainer": {
    flexDirection: window.innerWidth < 768 ? "column" : "row",
  },
  // },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flexDirection: "initial",
    // gap: "5px",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "18px",
    marginRight: "24px",
    // borderRadius: "20px",
    // minHeight: 'auto',
    color: "#000",
    "&.Mui-selected": {
      color: "#603085",
      // background: "#603085",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#333",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        overflowY: "auto",
        padding: "6px 0",
        // background: "#fff",
        // borderRadius: "20px",
        marginTop: "6px",
      }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Product() {
  const { PUBLIC_URL, currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");
  const [product, setProduct] = useState();
  // const [loader, setLoader] = useState(0);
  let { slug } = useParams();
  const [attributes, setAttributes] = useState();
  const [metas, setMetas] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [packages, setPackages] = useState();
  const [related_products, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeImage, setActiveImage] = useState();
  const [packageToggle, setPackageToggle] = useState(true);
  const loaded = useRef(false);
  let tabCount = 0;
  const firstVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });
  const secondVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });

  const [selectedVariant, setSelectedVariant] = useState({
    first: null,
    second: null,
  });

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getProducts = useCallback(async () => {
    try {
      // setLoader(1);

      let filter = "slug=" + slug;

      if (currentLanguageRef.current) {
        if (!filter) {
          filter = filter + "lng=" + currentLanguageRef.current;
        } else {
          filter = filter + "&lng=" + currentLanguageRef.current;
        }
      }

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          console.log(JSON.parse(data.products[0].attributes2));
          if (data && data.count > 0) {
            const tproduct = data.products[0];
            setProduct(tproduct);
            let _attributes = JSON.parse(tproduct.attributes).filter(
              (item) => item.attribute_type !== 3
            )
              ? JSON.parse(tproduct.attributes).filter(
                  (item) => item.attribute_type !== 3
                )
              : [];
            setAttributes(_attributes);

            if (
              tproduct.product_attributes &&
              tproduct.product_attributes.length > 0
            ) {
              firstVariant.current.items = [];
              secondVariant.current.items = [];
              tproduct.product_attributes.forEach((element) => {
                if (firstVariant.current.type === 0) {
                  firstVariant.current = {
                    type: element.attribute_type,
                    type_name: element.attribute_type_name,
                    items: [element],
                  };
                } else if (
                  firstVariant.current.type === element.attribute_type
                ) {
                  firstVariant.current = {
                    ...firstVariant.current,
                    items: [...firstVariant.current.items, element],
                  };
                } else {
                  secondVariant.current = {
                    type: element.attribute_type,
                    type_name: element.attribute_type_name,
                    items: [...secondVariant.current.items, element],
                  };
                }
              });
            }

            let _packages = JSON.parse(tproduct.attributes).filter(
              (item) => item.attribute_type === 3
            );
            setPackages(_packages);

            setMetas(
              tproduct.metas
                ? JSON.parse(tproduct.metas).filter((item) => {
                    return item.display_on_product === 1;
                  })
                : null
            );

            setSelectedAttribute(
              _attributes.length > 0 ? _attributes[0] : _packages[0]
            );

            const ids = _attributes[0].attribute_id.split(",");

            if (ids[0] && ids[1]) {
              setSelectedVariant({ first: +ids[0], second: +ids[1] });
            } else if (ids[0] && !ids[1]) {
              setSelectedVariant({ first: +ids[0], second: null });
            }

            setRelatedProducts(
              tproduct.related_products ? tproduct.related_products : null
            );

            setReviews(tproduct.reviews ? JSON.parse(tproduct.reviews) : null);

            setFaqs(tproduct.faqs ? JSON.parse(tproduct.faqs) : null);

            setActiveImage(
              tproduct.images
                ? tproduct.images.split(",")[0]
                : _attributes.length > 0
                ? _attributes[0].thumbnail
                : ProductDefaultImage
            );
          }
          // setLoader(0);
          loaded.current = true;
        });
    } catch (error) {
      // setLoader(0);
      loaded.current = true;
    }
  }, [PUBLIC_URL, slug]);

  useEffect(() => {
    getProducts();
  }, [currentLanguage, getProducts]);

  return (
    <>
      <Helmet>
        <title>{product && product.name + " - " + t("website_name")}</title>
        {product && product.meta_description && (
          <meta name="description" content={product.meta_description} />
        )}
        {product && attributes && reviews && (
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": "${product.name}",
              "image": "${
                product.images
                  ? product.images.split(",")[0]
                  : product.attributes
                  ? product.attributes.thumbnail
                  : ""
              }",
              "description": "${product.meta_description}",
              "brand": {
                "@type": "Brand",
                "name": "${t("website_name")}"
              },
              "sku": "",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ${
                  product.reviews
                    ? product.reviews.reduce(
                        (total, review) => (total = total + review.rate),
                        0
                      ) / product.reviews.length
                    : 0
                },
                "ratingCount": ${
                  product.reviews ? product.reviews.length : 0
                },
                "reviewCount": ${product.reviews ? product.reviews.length : 0}
              }
              ${
                product.reviews
                  ? `,"review": [${product.reviews.map((review, index) => {
                      return `${index > 0 && ","}{
                  "@type": "Review",
                  "name": "${review.customer_name}",
                  "reviewBody": "${review.comment}",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "${review.rate}"
                  },
                  "datePublished": "${review.date}",
                  "author": "",
                  "publisher": ""
                }`;
                    })}]`
                  : ""
              }              
            }`}
          </script>
        )}
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <Banner />
        <div className="mt-[51px] text-center text-[25px] md:text-[35px] 2xl:text-[54px] leading-normal font-[700]">
          {t("shawn_online_shop")}
        </div>
        <div className="w-full lg:w-[74.68%] mt-[28px] mx-auto pb-[197px]">
          <div className="flex">
            <div className="w-full flex flex-wrap items-center text-[#4D4D4D]">
              <div className="w-full flex items-center">
                <Breadcrumb text={product && product.name} />
              </div>
            </div>
          </div>
          <div className="my-[15px]">
            <div className="w-full flex gap-[5px]">
              {product && (
                <div className="w-full lg:w-[70%] 2xl:w-[78.4%] flex flex-wrap lg:flex-nowrap bg-[#fff] shadow-[0_4px_10px_0_rgba(0,0,0,0.10)] rounded-[20px] p-[15.52px_12px] border-[0.5px] border-solid border-[rgba(30,30,30,0.30)]">
                  <div className="w-full md:w-[27vw] 2xl:w-[637px] flex flex-wrap md:flex-nowrap gap-[11.16px]">
                    <div className="w-full md:w-[4vw] 2xl:w-[114.8419px] flex md:block justify-center">
                      {product.images &&
                        product.images.split(",").length > 0 &&
                        product.images.split(",").map((image, index) => {
                          return (
                            <div
                              key={index}
                              className="cursor-pointer flex items-center justify-center w-[25%] md:w-[4vw] 2xl:w-[114.8419px] h-auto md:h-[4vw] 2xl:h-[114.8419px] border-solid border-[1px] border-[rgba(0,0,0,0.25)] rounded-[20px] mb-[17.67px] overflow-hidden"
                              onClick={() => {
                                setActiveImage(image);
                              }}
                            >
                              <LazyLoadImage src={image} alt={product.name} />
                            </div>
                          );
                        })}
                      {attributes &&
                        attributes.length > 0 &&
                        attributes.map((attribute, index) => {
                          if (attribute.thumbnail) {
                            return (
                              <div
                                key={index}
                                className="cursor-pointer flex items-center justify-center w-[25%] md:w-[4vw] 2xl:w-[114.8419px] h-auto md:h-[4vw] 2xl:h-[114.8419px] border-solid border-[1px] border-[rgba(0,0,0,0.25)] rounded-[20px] mb-[17.67px] overflow-hidden"
                                onClick={() => {
                                  setActiveImage(attribute.thumbnail);
                                }}
                              >
                                <LazyLoadImage
                                  src={attribute.thumbnail}
                                  alt={product.name}
                                />
                              </div>
                            );
                          }
                          return <React.Fragment key={index}></React.Fragment>;
                        })}
                    </div>
                    <div className="w-full md:w-[calc(100%-4vw)] 2xl:w-[511px]">
                      <div className="border-solid border-[1px] border-[#00000040] rounded-[20px] overflow-hidden md:w-full 2xl:w-[26.61vw] md:h-auto 2xl:h-[26.61vw]">
                        {activeImage ? (
                          <LazyLoadImage src={activeImage} alt={product.name} />
                        ) : (
                          <LazyLoadImage
                            src={ProductDefaultImage}
                            alt={product.name}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-[calc(100%-27vw)] 2xl:w-[calc(100%-637px)] ltr:pl-0 ltr:md:pl-[19px] rtl:pr-0 rtl:md:pr-[19px]">
                    <div className="flex">
                      <h1 className="font-[600] text-[24px] 2xl:text-[32px] m-0 p-0 leading-normal text-black">
                        {product.name}
                      </h1>
                    </div>
                    {product && product.categories && (
                      <div className="categories text-[14px] 2xl:text-[18px] leading-normal text-[#2A2A2A]">
                        {JSON.parse(product.categories).map(
                          (category, index) => {
                            return (
                              <React.Fragment key={index}>
                                {index !== 0 && (
                                  <span className="mx-[5px]">-</span>
                                )}
                                <span className="inline-block text-[#000]">
                                  {category.name}
                                </span>
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    )}

                    <div className="flex items-center relative mt-[9px] text-[14px] 2xl:text-[16px]">
                      {selectedAttribute && selectedAttribute.sku && (
                        <div className="w-[50%] z-[2] text-[#4A4A4A]">
                          <span className="bg-[#fff] ltr:pr-[10px] rtl:pl-[10px]">
                            {t("sku")}: {selectedAttribute.sku}
                          </span>
                        </div>
                      )}

                      {product && product.brand && (
                        <div className="w-[50%] flex justify-end z-[2] text-[#4A4A4A]">
                          <span className="bg-[#fff] ltr:pl-[10px] rtl:pr-[10px]">
                            {product.brand}
                          </span>
                        </div>
                      )}

                      <div className="absolute w-full h-[1px] top-[50%] left-0 bg-[rgba(30,30,30,0.50)]"></div>
                    </div>
                    <div className="text-[14px] 2xl:text-[18px] mt-[3px] text-[#2A2A2A]">
                      {t("about_product")}:
                    </div>
                    {product.description && (
                      <div
                        className="text-[#4B4B4B] text-[12px] 2xl:text-[14px] font-[300] text-justify"
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    )}
                    {selectedAttribute && selectedAttribute.description && (
                      <div
                        className="text-[#4B4B4B] text-[12px] 2xl:text-[14px] font-[300] text-justify"
                        dangerouslySetInnerHTML={{
                          __html: selectedAttribute.description,
                        }}
                      />
                    )}

                    {selectedAttribute &&
                      (selectedAttribute.width ||
                        selectedAttribute.height ||
                        selectedAttribute.length ||
                        selectedAttribute.weight) && (
                        <div className="flex mt-[15px] gap-[20px] text-[0.8rem] text-[14px] 2xl:text-[16px]">
                          {selectedAttribute.length ? (
                            <span>
                              <strong>{t("length")}: </strong>
                              {selectedAttribute.width} {t("centimeter")}
                            </span>
                          ) : (
                            ""
                          )}
                          {selectedAttribute.width ? (
                            <span>
                              <strong>{t("width")}: </strong>
                              {selectedAttribute.width} {t("centimeter")}
                            </span>
                          ) : (
                            ""
                          )}
                          {selectedAttribute.height ? (
                            <span>
                              <strong>{t("height")}: </strong>
                              {selectedAttribute.height} {t("centimeter")}
                            </span>
                          ) : (
                            ""
                          )}
                          {selectedAttribute.weight ? (
                            <span>
                              <strong>{t("weight")}: </strong>
                              {selectedAttribute.weight} {t("centimeter")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    {product.product_attributes ? (
                      <>
                        {firstVariant.current.items.length > 0 && (
                          <>
                            <div className="mt-[6px] text-[18px] 2xl:text-[24px]">
                              {firstVariant.current.type_name}
                            </div>
                            <div className=" flex flex-wrap gap-[5px] mt-[5px]">
                              {firstVariant.current.items.map(
                                (product_attribute, index) => {
                                  return (
                                    <div
                                      className={`w-[calc(50%-4px)] md:w-[calc(25%-4px)] p-[3px] ${
                                        selectedVariant.first ===
                                        product_attribute.attribute_id
                                          ? "bg-[#603085] text-white"
                                          : "bg-[#D9D9D9] text-[#626262]"
                                      }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                                      key={index}
                                      onClick={() => {
                                        setSelectedVariant({
                                          ...selectedVariant,
                                          first: product_attribute.attribute_id,
                                        });
                                        if (
                                          secondVariant.current.items.length ===
                                          0
                                        ) {
                                          setSelectedAttribute(
                                            attributes.filter((item) => {
                                              return (
                                                +item.attribute_id ===
                                                +product_attribute.attribute_id
                                              );
                                            })[0]
                                          );
                                        } else if (selectedVariant.second) {
                                          setSelectedAttribute(
                                            attributes.filter((item) => {
                                              return (
                                                item.attribute_id ==
                                                  product_attribute.attribute_id +
                                                    "," +
                                                    selectedVariant.second ||
                                                item.attribute_id ==
                                                  selectedVariant.second +
                                                    "," +
                                                    product_attribute.attribute_id
                                              );
                                            })[0]
                                          );
                                        }
                                      }}
                                    >
                                      {product_attribute.attribute_type ===
                                        1 && (
                                        <span
                                          className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                          style={{
                                            background:
                                              product_attribute.attribute_value,
                                          }}
                                        ></span>
                                      )}
                                      {product_attribute.attribute_name}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            {selectedVariant.first &&
                              firstVariant.current.items &&
                              firstVariant.current.items.length > 0 &&
                              firstVariant.current.items.filter((item) => {
                                return (
                                  item.attribute_id === selectedVariant.first
                                );
                              })[0] &&
                              firstVariant.current.items.filter((item) => {
                                return (
                                  item.attribute_id === selectedVariant.first
                                );
                              })[0].attribute_description && (
                                <>
                                  <div className="text-[12px] 2xl:text-[14px]">
                                    {firstVariant.current.type_name +
                                      " " +
                                      t("description")}
                                  </div>

                                  <div className="text-[10px] 2xl:text-[14px]">
                                    {
                                      firstVariant.current.items.filter(
                                        (item) => {
                                          return (
                                            item.attribute_id ===
                                            selectedVariant.first
                                          );
                                        }
                                      )[0].attribute_description
                                    }
                                  </div>
                                </>
                              )}
                          </>
                        )}

                        {secondVariant.current.items &&
                          secondVariant.current.items.length > 0 && (
                            <>
                              <div className="mt-[6px] text-[18px] 2xl:text-[24px]">
                                {secondVariant.current.type_name}
                              </div>
                              <div className=" flex flex-wrap gap-[5px] mt-[5px]">
                                {secondVariant.current.items.map(
                                  (product_attribute, index) => {
                                    return (
                                      <div
                                        className={`w-[calc(50%-4px)] md:w-[calc(25%-4px)] p-[3px] ${
                                          selectedVariant.second ===
                                          product_attribute.attribute_id
                                            ? "bg-[#603085] text-white"
                                            : "bg-[#D9D9D9] text-[#626262]"
                                        }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                                        key={index}
                                        onClick={() => {
                                          setSelectedVariant({
                                            ...selectedVariant,
                                            second:
                                              product_attribute.attribute_id,
                                          });

                                          setSelectedAttribute(
                                            attributes.filter((item) => {
                                              return (
                                                item.attribute_id ==
                                                  selectedVariant.first +
                                                    "," +
                                                    product_attribute.attribute_id ||
                                                item.attribute_id ==
                                                  product_attribute.attribute_id +
                                                    "," +
                                                    selectedVariant.first
                                              );
                                            })[0]
                                          );
                                        }}
                                      >
                                        {product_attribute.attribute_type ===
                                          1 && (
                                          <span
                                            className={`w-[20px] h-[20px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                            style={{
                                              background:
                                                product_attribute.attribute_value,
                                            }}
                                          ></span>
                                        )}
                                        {product_attribute.attribute_name}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              {selectedVariant.second &&
                                secondVariant.current.items &&
                                secondVariant.current.items.length > 0 &&
                                secondVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.second
                                  );
                                })[0] &&
                                secondVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.second
                                  );
                                })[0].attribute_description && (
                                  <>
                                    <div className="text-[12px] 2xl:text-[14px]">
                                      {secondVariant.current.type_name +
                                        " " +
                                        t("description")}
                                    </div>
                                    <div className="text-[12px] 2xl:text-[14px]">
                                      {
                                        secondVariant.current.items.filter(
                                          (item) => {
                                            return (
                                              item.attribute_id ===
                                              selectedVariant.second
                                            );
                                          }
                                        )[0].attribute_description
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </>
                    ) : (
                      <div className=" flex flex-wrap gap-[5px] mt-[15px]">
                        {attributes.map((attribute, index) => {
                          return (
                            <div
                              className={`w-[calc(50%-4px)] md:w-[calc(25%-4px)] p-[3px] ${
                                selectedAttribute.id === attribute.id
                                  ? "bg-[#603085] text-white"
                                  : "bg-[#D9D9D9] text-[#626262]"
                              }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                              key={index}
                              onClick={() => {
                                setSelectedAttribute(attribute);
                              }}
                            >
                              {attribute.attribute_type === 1 && (
                                <span
                                  className={`w-[20px] h-[20px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                  style={{
                                    background: attribute.attribute_value,
                                  }}
                                ></span>
                              )}
                              {attribute.attribute_name}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {metas && metas.length > 0 && (
                      <>
                        <div className="text-[16px] 2xl:text-[17px] font-[700] mb-[7px] mt-[5px]">
                          Product feature:
                        </div>
                        <div className="w-full">
                          {metas.map((meta, index) => {
                            return (
                              <div className="flex" key={index}>
                                <GaranteeIcon />
                                <span className="text-[10px] 2xl:text-[12px] mr-[6.69px]">
                                  {meta.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div className="flex items-center mt-[11px]">
                      <div className="w-[80%] flex items-center">
                        <TestGaranteeIcon />
                        <span class="ml-[6.03px]">
                          {t("product_test_guarantee")}
                        </span>
                      </div>
                      {packages.length > 0 && (
                        <div className="w-[20%] flex items-center justify-end text-[10px]">
                          <span
                            className="flex items-center cursor-pointer"
                            onClick={() => {
                              setPackageToggle(!packageToggle);
                            }}
                          >
                            {packageToggle ? (
                              <>
                                <img
                                  src={PackageCloseIcon}
                                  alt="close"
                                  className="ltr:mr-[5px] rtl:ml-[5px]"
                                />{" "}
                                {t("close")}
                              </>
                            ) : (
                              <>{t("open")}</>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    {reviews && (
                      <div className="flex items-center text-[12px]">
                        <LikeGreenIcon />
                        {(product.rate * 100) / 5} % ( {reviews.length}{" "}
                        {t("message_product_review")}
                      </div>
                    )}

                    {packages && packages.length > 0 && (
                      <div>
                        <div className="flex items-center text-[12px]">
                          <InvestIcon />
                          <Link
                            to=""
                            className="text-[#603085] hover:text-[#000] ml-[5px]"
                          >
                            Click here{" "}
                          </Link>{" "}
                          to view the special offer and buy gold
                        </div>
                        <div className="flex justify-end ">
                          <span
                            className="cursor-pointer text-[#603085] text-[10px]"
                            onClick={() => {
                              setSelectedAttribute(attributes[0]);
                            }}
                          >
                            {t("delete_transaction")}
                          </span>
                        </div>
                        <div className="flex flex-wrap mt-[15px]">
                          {packages.map((item, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  setSelectedAttribute(item);
                                }}
                                className={`${
                                  selectedAttribute &&
                                  selectedAttribute.id === item.id
                                    ? "bg-[#603085] text-[#fff]"
                                    : "bg-[#80808080] text-[#fff]"
                                } cursor-pointer flex items-center text-[12px] p-[3px] rounded-[20px] ltr:mr-[5px] rtl:ml-[5px] mb-[5px] shadow`}
                              >
                                <span className="mx-[10px]">
                                  {item.attribute_name}
                                </span>
                              </span>
                            );
                          })}
                        </div>
                        <div className="flex border-solid border-[1px] border-[#D7D7D7] rounded-[20px] p-[3px] mt-[15px]">
                          <div className="flex w-full items-center px-[10px] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#D7D7D7]">
                            <div className="w-full text-[11px]">
                              {t("original_price")}:{" "}
                            </div>
                            <div className="text-[#0BC154] flex justify-end">
                              {selectedAttribute && (
                                <Price
                                  price={
                                    selectedAttribute.sale_price
                                      ? selectedAttribute.sale_price
                                      : selectedAttribute.price
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex w-full items-center px-[10px] border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[#D7D7D7]">
                            <div className="w-full text-[11px]">
                              {t("transaction_price")}:
                            </div>
                            <div className="text-[#0BC154] flex justify-end">
                              {selectedAttribute ? (
                                <Price
                                  price={
                                    selectedAttribute.attribute_type === 3
                                      ? selectedAttribute.price *
                                        selectedAttribute.attribute_value
                                      : 0
                                  }
                                />
                              ) : (
                                <Price price={0} />
                              )}
                            </div>
                          </div>
                          <div className="flex w-full items-center px-[10px]">
                            <div className="w-full text-[11px]">
                              {t("your_profit")}:{" "}
                            </div>
                            <div className="text-[#0BC154] flex justify-end">
                              {selectedAttribute && attributes ? (
                                <Price
                                  price={
                                    attributes.filter(
                                      (item) =>
                                        item.id === selectedAttribute.parent_id
                                    ).length > 0 &&
                                    selectedAttribute.attribute_type === 3
                                      ? (attributes.filter(
                                          (item) =>
                                            item.id ===
                                            selectedAttribute.parent_id
                                        )[0].sale_price
                                          ? attributes.filter(
                                              (item) =>
                                                item.id ===
                                                selectedAttribute.parent_id
                                            )[0].sale_price
                                          : attributes.filter(
                                              (item) =>
                                                item.id ===
                                                selectedAttribute.parent_id
                                            )[0].price) *
                                          selectedAttribute.attribute_value -
                                        selectedAttribute.price *
                                          selectedAttribute.attribute_value
                                      : 0
                                  }
                                />
                              ) : (
                                <Price price={0} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex items-center mt-[25px]">
                      <div className="flex items-center w-[65%] text-[12px]">
                        <Rating
                          name="read-only"
                          value={product.rate}
                          readOnly
                          sx={{
                            color: "#FFD954",
                            marginRight: "10px",
                          }}
                        />
                        {product.rate
                          ? parseFloat(product.rate).toFixed(1)
                          : "No"}{" "}
                        {t("product_rating")}
                      </div>

                      <div className="flex items-center justify-end w-[35%] text-[12px]">
                        {t("one_day_delivery")}{" "}
                        <img
                          src={DeliveryIcon}
                          alt=""
                          className="ltr:ml-[10px] rtl:mr-[10px]"
                        />
                      </div>
                    </div>
                    <div className="mt-[3px] flex items-center">
                      <div className="w-full text-[18px] 2xl:text-[24px]">
                        {selectedAttribute && selectedAttribute.stock > 0 ? (
                          <>
                            <span className="text-[rgba(30,30,30,0.70)]">
                              {t("price")}:{" "}
                            </span>
                            <span className="text-[#0BC154]">
                              {selectedAttribute && (
                                <Price
                                  price={
                                    selectedAttribute.sale_price
                                      ? selectedAttribute.sale_price
                                      : selectedAttribute.price
                                  }
                                />
                              )}
                            </span>
                          </>
                        ) : (
                          <span className="text-[#7F7F7F]">
                            {t("unavailable")}
                          </span>
                        )}
                      </div>
                      <div className="w-full flex justify-end">
                        {selectedAttribute && (
                          <AddToCartButton
                            attribute={selectedAttribute}
                            text={t("add_to_cart")}
                            classes="bg-[#000]"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {window.innerWidth > 991 && (
                <div className="w-[30%] 2xl:w-[21.6%]">
                  <CartCard />
                </div>
              )}
            </div>

            <div className="max-w-full mt-[15px]">
              <div className="">
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      background: "#fff",
                      padding: "8px 12px",
                      borderRadius: "20px",
                      boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.10)",
                      width: { xs: "calc(100vw - 30px)", md: "100%" },
                    }}
                  >
                    <StyledTabs
                      value={activeTab}
                      onChange={(event, newValue) => {
                        setActiveTab(newValue);
                      }}
                      // aria-label="styled tabs example"
                      // variant="fullWidth"
                    >
                      {product && product.introduction && (
                        <StyledTab
                          label="Introduction"
                          onClick={() => {
                            // setPage(0);
                          }}
                        />
                      )}

                      {product && product.expert_check && (
                        <StyledTab
                          label={t("expert_check")}
                          onClick={() => {
                            // setPage(1);
                          }}
                        />
                      )}

                      {product && product.specification && (
                        <StyledTab
                          label={t("specifications")}
                          onClick={() => {
                            // setPage(2);
                          }}
                        />
                      )}

                      <StyledTab
                        label={t("opinions")}
                        onClick={() => {
                          // setPage(3);
                        }}
                      />
                      {faqs && (
                        <StyledTab
                          label={t("FAQ")}
                          onClick={() => {
                            // setPage(3);
                          }}
                        />
                      )}
                    </StyledTabs>
                    <Box />
                  </Box>

                  {product && product.introduction && (
                    <TabPanel value={activeTab} index={tabCount++}>
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: product.introduction,
                        }}
                      />
                    </TabPanel>
                  )}

                  {product && product.expert_check && (
                    <TabPanel value={activeTab} index={tabCount++}>
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: product.expert_check,
                        }}
                      />
                    </TabPanel>
                  )}

                  {product && product.specification && (
                    <TabPanel value={activeTab} index={tabCount++}>
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: product.specification,
                        }}
                      />
                    </TabPanel>
                  )}

                  <TabPanel value={activeTab} index={tabCount++}>
                    <ReviewCard reviews={reviews} />
                  </TabPanel>
                  {faqs && (
                    <TabPanel value={activeTab} index={tabCount++}>
                      <ProductFAQCard faqs={faqs} />
                    </TabPanel>
                  )}
                </Box>
              </div>
            </div>

            {related_products && related_products.length > 0 && (
              <>
                <div className="text-center text-black text-[24px] 2xl:text-[32px] font-[600] mt-[78px]">
                  {t("choose_goods_with_shawn")}
                </div>
                <div className="text-center text-black text-[14px] 2xl:text-[18px] mt-[6px]">
                  {t("choose_goods_with_shawn_message")}
                </div>
                <div className="relative m-[19px_0_34px_0] flex items-center justify-center">
                  <div className="bg-[#9c9c9c] h-[2px] rounded-[5px] absolute top-[55%] ltr:left-0 rtl:right-0 w-full"></div>
                  <span className="inline-flex items-center bg-black text-white text-[14px] 2xl:text-[18px] rounded-[20px] p-[7px_16px] z-[2] capitalize shadow-[0_4px_20px_0_rgba(0,0,0,0.10)]">
                    {t("similar_products")}
                  </span>
                </div>
                <RelatedProductsCarousel related_products={related_products} />
              </>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
