import { Checkbox } from "@mui/material";
import Accordion from "../accordion/accordion";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "../../context/context";
import { useTranslation } from "react-i18next";

export default function ProductTypeFilter() {
  const { PUBLIC_URL, currentLanguage, filters, setFilters } =
    useContext(AppContext);
  const currentLanguageRef = useRef("");
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();

  const getTypes = useCallback(async () => {
    try {
      await fetch(
        `${PUBLIC_URL}/api/get/producttypes?lng=${currentLanguageRef.current}`
      )
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.types) {
            setTypes(data.types);
          }
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
    getTypes();
  }, [getTypes, currentLanguage]);
  return (
    types &&
    types.length > 0 && (
      <>
        <Accordion title="Product Type" classes="mt-[20px]">
          <div className="mt-[15px]">
            {filters.productType && types.map((type, index) => {
              if (!type.parent_id) {
                return (
                  <div
                    className="flex items-center leading-none mb-[5px]"
                    key={index}
                  >
                    <div className="w-full">{type.name}</div>
                    <div className="w-full flex justify-end">
                      <Checkbox
                        checked={
                          filters.productType.find((element) => {
                            return element === type.id;
                          })
                            ? true
                            : false
                        }
                        onChange={(event) => {
                          if (event.target.checked === true) {
                            setFilters({
                              ...filters,
                              productType: [...filters.productType, type.id],
                            });
                          } else {
                            setFilters({
                              ...filters,
                              productType: filters.productType.filter(
                                (element) => element !== type.id
                              ),
                            });
                          }
                        }}
                        inputProps={{ "aria-label": "B" }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                          color: "#603085",
                          padding: "0",
                          "&.Mui-checked": {
                            color: "#603085",
                          },
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
          </div>
        </Accordion>
        <Accordion title={t('product_type')} classes="mt-[20px]">
          <div className="mt-[15px]">
            {filters.productType && types.map((type, index) => {
              if (type.parent_id) {
                return (
                  <div
                    className="flex items-center leading-none mb-[5px]"
                    key={index}
                  >
                    <div className="w-full">{type.name}</div>
                    <div className="w-full flex justify-end">
                      <Checkbox
                        checked={
                          filters.productType.find((element) => {
                            return element === type.id;
                          })
                            ? true
                            : false
                        }
                        onChange={(event) => {
                          if (event.target.checked === true) {
                            setFilters({
                              ...filters,
                              productType: [...filters.productType, type.id],
                            });
                          } else {
                            setFilters({
                              ...filters,
                              productType: filters.productType.filter(
                                (element) => element !== type.id
                              ),
                            });
                          }
                        }}
                        inputProps={{ "aria-label": "B" }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                          color: "#603085",
                          padding: "0",
                          "&.Mui-checked": {
                            color: "#603085",
                          },
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
          </div>
        </Accordion>
      </>
    )
  );
}
