import Header from "./layout/header";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useRef } from "react";
import AppContext from "./context/context";
import { useTranslation } from "react-i18next";
import Footer from "./layout/footer";
import BannerAbout from "./components/banner-about/banner";

export default function AboutUs() {
  const { WEBSITE_URL, currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const currentLanguageRef = useRef("");

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <Header />
      <main className="bg-[#d9d9d9]">
        <BannerAbout />
        <div className="text-center mx-[10%]">
          <div className="mt-[60px] px-0 md:px-[68px]">
            <div className="text-[70px] font-[900] uppercase">
              ABOUT COMPANY
            </div>
            <div>
              Shawn trading co L.L.C established in 2023 in Dubai, UAE as a hub
              for retail and chain stores and regional wholesale. We started the
              business in Dubai as it has the greatest potential within GCC
              region.
            </div>
            <div>
              The holding company is active in production and distribution of
              pet supplies to large number of wholesalers, chain stores and
              retails etc. The platforms are online and offline.
            </div>
            <div>
              The scope of the business is planned to expand in many aspects
              such as product ranges, countries, industries, etc.
            </div>
          </div>
          <div className="flex items-center gap-[10px] mt-[38px]">
            <div className="">
              <img
                src={`${`${WEBSITE_URL}/media/about-us-1.jpg`}`}
                alt=""
                className="rounded-[20px]"
              />
            </div>
            <div className="">
              <img
                src={`${`${WEBSITE_URL}/media/about-us-2.jpg`}`}
                alt=""
                className="rounded-[20px]"
              />
            </div>
            <div className="">
              <img
                src={`${`${WEBSITE_URL}/media/about-us-3.jpg`}`}
                alt=""
                className="rounded-[20px]"
              />
            </div>
            <div className="">
              <img
                src={`${`${WEBSITE_URL}/media/about-us-4.jpg`}`}
                alt=""
                className="rounded-[20px]"
              />
            </div>
          </div>
          <div className="mt-[17px] px-0 md:px-[68px]">
            <div className="text-[35px] font-[900] uppercase">
              Shawn Pet SHop
            </div>
            <div>Layout:</div>
            <div>
              According to the level of customers' needs, 65% of the store is
              dedicated to food products and 35% of the store is dedicated to
              hygiene products , vitamins and pet supplies.
            </div>
            <div>
              The design of the shelves and the layout will be such that the
              most consumed goods will be located at the end of the store in
              order to provide a suitable route for customers to visit all parts
              of the store. A very high variety of products needed by our
              customers is one of our characteristics, and we will be responsive
              to every taste and demand.
            </div>
            <div>Color scheme:</div>
            <div>
              Black is regarded as the darkest color and can create a calm and
              peaceful atmosphere. The black color creates an unlimited
              atmosphere and also completely emphasizes the contrast and gives a
              special effect to the products arranged if the ambient light
              balances.
            </div>
            <div>
              As the brand takes a look to the future, the purple organizational
              color is used alongside white and black colors because it is
              young, luxurious and wise and can make it feel good in its
              customers.
            </div>
            <div>Products:</div>
            <div>
              In our product portfolio, all pet needs are found, as we have used
              our years of experience scientifically and up-to-date to better
              understand the needs of our customers in this industry. The
              complete pets product basket generally includes beds, toys, dry
              food, wet food, supplements, hygienic products, suppliments
              grooming tools, training tools, treats, leashes and collars,
              bowls, pet carrier, Clothing, pet tags, cat litters, scrachers,
              pet furniture and a lot of other accessories which are used to
              enhance the quality of life of pets.
            </div>
          </div>
          <div className="mt-[34px] px-0 md:px-[68px]">
            <div className="text-[35px] font-[900] uppercase">
              OUR COMPETENCIES
            </div>
            <div>
              Our competencies are the foundation of our success in the pet
              industry. With over 12 years of international experience, we have
              established ourselves as a trusted and reliable partner in this
              market. Our extensive knowledge and expertise have allowed us to
              navigate the complexities of the industry, enabling us to provide
              exceptional services to our clients.
            </div>

            <div>
              One of our key strengths lies in our IT team, which comprises of
              over 90 skilled professionals. This dedicated team is well-versed
              in the latest technological advancements relevant to the pet
              industry. Through their expertise and continuous learning, we are
              able to offer cutting-edge solutions to our clients, ensuring
              their businesses stay competitive in the digital era.
            </div>

            <div>
              Moreover, our vast experience as a third-party service provider
              has allowed us to work with 60 renowned international brands. This
              exposure has provided us with valuable insights into various
              market strategies, trends, and consumer needs. Our ability to
              collaborate and adapt to different brand requirements has further
              strengthened our competencies and positioned us as a go-to partner
              for global players.
            </div>

            <div>
              Additionally, our well-established network of suppliers is a
              testament to our commitment to quality and reliability. Over the
              years, we have built strong relationships with suppliers across
              the globe, ensuring a steady supply of top-notch products for our
              clients. This network allows us to meet diverse demands while
              adhering to the highest standards of quality control.
            </div>

            <div>
              In conclusion, our competencies are the culmination of our rich
              experience, skilled IT team, strong partnerships with
              international brands, and extensive network of suppliers. These
              factors enable us to provide exceptional services to our clients,
              setting us apart in the pet industry. We are proud of our
              achievements and remain dedicated to continuously enhancing our
              competencies to meet the evolving needs of the market.
            </div>
          </div>
          <img
            src={`${`${WEBSITE_URL}/media/about-us-5.jpg`}`}
            alt=""
            className="rounded-[20px] my-[30px]"
          />

          <div className="mt-[100px] pb-[178px]">
            <div className="text-[35px] font-[900] uppercase">
              trading brands
            </div>
            <div className="mt-[30px]">
              <img
                src={`${`${WEBSITE_URL}/media/about-us-logo.png`}`}
                alt=""
                className="rounded-[20px_20px_0_0]"
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
