import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Price from "../price/price";
import React from "react";
import ImagePlaceholder from '../../../assets/img/product-placeholder.png'

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export default function ProductSearchItem(props) {
  const product = props.item;
  const attributes = JSON.parse(product.attributes);
  const metas = product.metas ? JSON.parse(product.metas) : [];

  return (
    <>
      <Link to={`/product/${product.slug}`} className="inline-block w-full">
        <div className="flex bg-white shadow-[0_4px_10px_0_rgba(0,0,0,0.10)] rounded-[22px] overflow-hidden mb-[9px]">
          <div className="w-[26.76%] shadow-[4px_0_20px_0_rgba(0,0,0,0.20)]">
            {product.images && product.images.split(",")[0] ? (
              <LazyLoadImage
                src={product.images.split(",")[0]}
                alt={product.name}
                className="max-w-full 2xl:max-w-[250px] max-h-auto 2xl:max-h-[250px] m-auto"
              />
            ) : (
              attributes &&
              attributes.map((attribute, index) => {
                return attribute.att_order === 1 && attribute.thumbnail ? (
                  <LazyLoadImage
                    key={index}
                    src={attribute.thumbnail}
                    alt={product.name}
                    className="max-w-full 2xl:max-w-[250px] max-h-auto 2xl:max-h-[250px] m-auto"
                  />
                ) : (
                  <LazyLoadImage
                    key={index}
                    src={ImagePlaceholder}
                    alt={product.name}
                    className="max-w-full 2xl:max-w-[250px] max-h-auto 2xl:max-h-[250px] m-auto"
                  />
                );
              })
            )}
          </div>
          <div className="w-[73.24%] p-[22px_10px_8px_23px] flex flex-col relative">
            <div className="absolute top-0 right-0 flex justify-end">
              {metas &&
                metas.map((meta, index) => {
                  if (meta.display !== 0) {
                    return (
                      <span
                        key={index}
                        className={`bg-[#FFD954] text-[#61562E] ltr:rounded-[0_0_0_15px] rtl:rounded-[0_15px_0_0] text-[18px] w-[60px] h-[45px] ltr:pl-[15px] ltr:pr-[30px] rtl:pr-[15px] rtl:pl-[30px] ltr:mr-[-15px] rtl:ml-[-15px] flex items-center justify-center shadow`}
                      >
                        {meta.name}
                      </span>
                    );
                  } else {
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                })}
              {!props.hideDiscount &&
              attributes[0] &&
              attributes[0].sale_price ? (
                <span className="bg-[#FF547D] text-[#F9F9F9] rounded-[0_15px] text-[18px] w-[45px] h-[45px] flex items-center justify-center shadow">
                  {(
                    100 -
                    (+attributes[0].sale_price * 100) /
                      +attributes[0].price
                  ).toFixed(0)}
                  %
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="text-[18px] md:text-[22px] 2xl:text-[32px] font-[600]">{product.name}</div>
            <div className="text-[14px] md:text-[18px] 2xl:text-[24px] text-[rgba(30,30,30,0.80)]">
              {attributes[0] && getText(attributes[0].excerpt)}
            </div>
            <div className="w-full text-[18px] md:text-[22px] 2xl:text-[32px] font-[600] mt-auto">
              {!attributes[0].sale_price ? (
                <span className="text-[#0BC154]">
                  <Price price={attributes[0].price} />
                </span>
              ) : (
                <>
                  <span className="text-[#0BC154] text-[32px] font-[600]">
                    <Price price={attributes[0].sale_price} />
                  </span>
                  <span className="text-[24px] text-[#FF547D] ltr:ml-[26px] rtl:mr-[26px]">
                    <del>
                      <Price price={attributes[0].price} />
                    </del>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
