export default function Select(props) {
  return (
    <select
      value={props.value ? props.value : 0}
      onChange={(event) => {
        props.onchange(event.target.value);
      }}
      className={props.classes}
    >
      {props.options.map((option, index) => {
        return <option key={index} value={option.key}>{option.value}</option>;
      })}
    </select>
  );
}
